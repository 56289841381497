// ScrollContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';

interface ChatScrollContextData {
  scrollTop?: number;
  scrollHeight?: number;
  clientHeight?: number;
  isScrolling?: boolean;
}

interface FullChatScrollContent extends ChatScrollContextData {
  setScrollData: (data: ChatScrollContextData) => void;
}

const ChatScrollContext = createContext<FullChatScrollContent>({
  scrollTop: 0,
  scrollHeight: 0,
  clientHeight: 0,
  isScrolling: false,
  setScrollData: () => {},
});

export const useChatScroll = () => useContext(ChatScrollContext);

export const ChatScrollProvider = ({ children }: { children: React.ReactNode }) => {
  const [scrollState, setScrollState] = useState<ChatScrollContextData>({
    scrollTop: 0,
    scrollHeight: 0,
    clientHeight: 0,
    isScrolling: false,
  });

  const setScrollData = useCallback((props: ChatScrollContextData) => {
    setScrollState((prevState) => ({
      ...prevState,
      ...props,
    }));
  }, []);

  const value = {
    ...scrollState,
    setScrollData,
  };

  return <ChatScrollContext.Provider value={value}>{children}</ChatScrollContext.Provider>;
};
