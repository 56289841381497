import { http } from '.';
import { ApiBody_Get_Chats_Props, Query_Chat_Search_Props, ApiResponse_Get_Graphs_Props } from '../utils';

export const _getGraphs = async ({ pageNumber = 0 }: ApiBody_Get_Chats_Props): Promise<ApiResponse_Get_Graphs_Props> => {
  const uri = `graphs?pageNumber=${pageNumber}`;
  return await http<ApiResponse_Get_Graphs_Props>({ method: 'get', uri });
};

export const _searchGraphs = async ({ searchQuery = '', pageNumber = 0 }: Query_Chat_Search_Props): Promise<ApiResponse_Get_Graphs_Props> => {
  const uri = `graphs/search?searchQuery=${searchQuery}&pageNumber=${pageNumber}`;

  return await http<ApiResponse_Get_Graphs_Props>({ method: 'get', uri });
};
