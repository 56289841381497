import { useMutation, useQueryClient } from 'react-query';
import { _signin, _signup, _getUserIDFromDatabase, _policyAcceptance, _deleteUser } from '../api';
import { ApiResponse_Success_Props, ApiResponse_Post_Policy_Acceptance_Props, ApiResponse_User_Props, Local_User_Props, logError } from '../utils';

// ------------------ User API Hook ------------------
export const useAuth = () => {
  const queryClient = useQueryClient();

  const signUpAPIMutation = useMutation({
    mutationFn: _signup,
    onSuccess: (user: ApiResponse_User_Props | null) => {
      console.log('signUpAPIMutation success', user);
    },
    onError: (e) => {
      logError(e, 'signUpAPIMutation');
    },
  });

  const signInAPIMutation = useMutation({
    mutationFn: _signin,
    onSuccess: (user: ApiResponse_User_Props | null) => {
      console.log('signInAPIMutation success', user);
    },
    onError: (e) => {
      logError(e, 'signInAPIMutation');
    },
  });

  const acceptTOSAPIMutation = useMutation({
    mutationFn: _policyAcceptance,
    onSuccess: async (data: ApiResponse_Post_Policy_Acceptance_Props) => {
      let user = getUser();
      const userFromDatabase = await _getUserIDFromDatabase();
      let betaUseAccepted = false;
      let tosAccepted = false;

      if (data.betaUseAccepted) betaUseAccepted = true;
      if (data.tosAccepted) tosAccepted = true;

      if (user) user = { ...user, betaUseAccepted, tosAccepted, firstName: userFromDatabase?.firstName || '', lastName: userFromDatabase?.lastName || '' };

      console.log('acceptTOSAPIMutation success', data);
      setUser(user);
    },
    // eslint-disable-next-line
    onError: (e) => {
      logError(e, 'acceptTOSAPIMutation');
    },
  });

  const deleteUserMutation = useMutation({
    mutationFn: _deleteUser,
    // eslint-disable-next-line
    onSuccess: async (data: ApiResponse_Success_Props) => {
      return data.success;
    },
    // eslint-disable-next-line
    onError: (e) => {
      logError(e, 'deleteUserMutation');
    },
  });

  const setUser = (user: Local_User_Props | null) => {
    queryClient.setQueryData('user', user || null);
  };

  const getUser = (): Local_User_Props | null => {
    return queryClient.getQueryData('user') || null;
  };

  // Reset the user queries
  const resetUser = () => {
    queryClient.setQueryData('user', null);
  };

  return { signUpAPIMutation, signInAPIMutation, acceptTOSAPIMutation, deleteUserMutation, setUser, getUser, resetUser };
};
[];
