import { ResponseBlockIcon } from '../../response-block-icon';

interface TitleBlockProps {
  title: string;
}

export const TitleBlock = ({ title }: TitleBlockProps) => (
  <div className="response-block-wrapper" data-cy="client-response-block-text">
    <ResponseBlockIcon usertype="client" visible />
    <div className="response-block-common-style client-response">{title}</div>
  </div>
);
