import {
  ApiResponse_Get_Users_Me_Props,
  Local_SignIn_Props,
  Local_SignUp_Props,
  ApiBody_GetAccessToken_Props,
  ApiResponse_Post_Policy_Acceptance_Props,
  ApiResponse_User_Props,
  logError,
  ApiResponse_Post_Auth_Signin_Props,
  ApiResponse_Success_Props,
  isWebview,
} from '../utils';
import { getIdToken, signInWithEmailAndPassword, signInWithRedirect, sendEmailVerification, signInWithPopup, UserCredential } from 'firebase/auth';
import { auth, googleProvider, appleProvider } from '../services/firebase';
import { http } from '.';
import { getStorageItem, storageItems } from '../utils/localStorage';

// Returns the access token for the currently logged in user
export const _getAccessToken = async ({ forceRefresh }: ApiBody_GetAccessToken_Props) => {
  try {
    const user = auth?.currentUser;
    if (!user) {
      return '';
    }

    return await getIdToken(user, forceRefresh);
  } catch (e) {
    logError(e, '_getAccessToken', 'Error getting ID token');
    return '';
  }
};

export const _sendVerificationEmail = async () => {
  try {
    const user = auth?.currentUser;
    if (!user) {
      throw new Error('An unkown error occurred.', { cause: 'No user found' });
    }

    await sendEmailVerification(user);
  } catch (e) {
    logError(e, '_sendVerificationEmail');
    throw e;
  }
};

// Get the user's ID from our backend
export const _getUserIDFromDatabase = async (): Promise<ApiResponse_Get_Users_Me_Props | null> => {
  try {
    return await http<ApiResponse_Get_Users_Me_Props>({ method: 'get', uri: 'users/me' });
  } catch (e) {
    return null;
  }
};

export const _signup = async (data: Local_SignUp_Props): Promise<ApiResponse_User_Props | null> => {
  try {
    const { email, password, firstName, lastName } = data;

    // Base case
    if (!email || !password || !firstName || !lastName) throw new Error('Missing required fields');

    await http({ method: 'post', uri: `auth/signup/local`, data: { firstName, lastName, email, password } });
    const { user: firebaseUser } = await signInWithEmailAndPassword(auth, email, password);
    const idToken = await getIdToken(firebaseUser);
    const _localSignInRes = await http<ApiResponse_Post_Auth_Signin_Props>({ method: 'post', uri: `auth/signin`, data: { idToken } });

    // eslint-disable-next-line
    if (_localSignInRes?.user) {
      // eslint-disable-next-line
      return _localSignInRes?.user;
    } else {
      throw new Error('Unknown error, signup failed');
    }
  } catch (e) {
    logError(e, '_signup');
    throw e;
  }
};

// TODO: Move this out of the API layer
export const _signin = async (data: Local_SignIn_Props): Promise<ApiResponse_User_Props | null> => {
  try {
    const { email, password, type } = data;
    const userAgent = navigator.userAgent ?? '';

    // Base case
    if (type === 'local' && (!email || !password)) throw new Error('Missing required fields');

    let firebaseUser = null;
    const provider = type === 'google' ? googleProvider : appleProvider;

    if (type === 'local') {
      firebaseUser = await signInWithEmailAndPassword(auth, email, password);
    } else {
      // Check if running inside a webview or mobile app
      if (isWebview(userAgent) || getStorageItem(storageItems.isNativeApp)) {
        await signInWithRedirect(auth, provider);
        return null;
      } else {
        firebaseUser = await signInWithPopup(auth, provider);
      }
    }

    const _localSignInRes = await _signinApi(firebaseUser);

    return _localSignInRes.user;
  } catch (e) {
    logError(e, '_signin');
    throw e;
  }
};

// Creates a user account on our backend
export const _signinApi = async (firebaseUser: UserCredential) => {
  const user = firebaseUser.user;
  const idToken = await getIdToken(user);
  return await http<ApiResponse_Post_Auth_Signin_Props>({ method: 'post', uri: `auth/signin`, data: { idToken } });
};

// Sends a request to our backend to accept the TOS
export const _policyAcceptance = async (): Promise<ApiResponse_Post_Policy_Acceptance_Props> => {
  const uri = `users/me/policyAcceptance`;

  return await http<ApiResponse_Post_Policy_Acceptance_Props>({
    method: 'post',
    uri,
    data: {
      betaUseAccepted: true,
      tosAccepted: true,
    },
  });
};

// Delete user account
export const _deleteUser = async (): Promise<ApiResponse_Success_Props> => {
  const uri = `users/me`;

  return await http<ApiResponse_Success_Props>({
    method: 'delete',
    uri,
  });
};
