import { memo, useEffect, useRef, useState } from 'react';
import {
  Component_ResponseBlockGraph_Props,
  GraphSeries,
  Local_CSV_Export_Props,
  Local_Graph_Table_Column_Props,
  Local_Graph_Table_Props,
  Local_KMB,
  formatNumberBasedOnMaxNumber,
  kmbFormatter,
  limitDecimalPlaces,
  mergeObjects,
  generateCSVData,
  timeRangeToSting,
  getNativeInsets,
} from '../../utils';
import { useQuery } from 'react-query';
import { useTheme } from '../../hooks';
import ReactECharts from 'echarts-for-react';
import { registerTheme } from 'echarts';
import dark_theme from '../../assets/graphThemes/dark';
import light_theme from '../../assets/graphThemes/light';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnBodyOptions } from 'primereact/column';
import { TABLE_DATA_DEFAULT_ROW_COUNT, defaultTheme } from '../../utils/constants';
import { ResponseBlockFeedback } from '../response-block-feedback';
import { SelectButton } from 'primereact/selectbutton';
import { Sidebar } from 'primereact/sidebar';
import { ResponseBlockIcon } from '../response-block-icon';
import { Button } from 'primereact/button';
import { CSVLink } from 'react-csv';
import './index.scss';

export const ResponseBlockGraph = memo(({ data = {}, config, xvars = [], _id, originalMessageId, userFeedback, firstResponse, lastResponse, className }: Component_ResponseBlockGraph_Props) => {
  const { getTheme } = useTheme();

  // Graph State
  const [timeRange, setTimeRange] = useState(5);
  const [timeRanges, setTimeRanges] = useState([5, 10]);
  const [graphDisplayType, setGraphDisplayType] = useState<GraphSeries['type']>(config.plot || 'bar');
  const [graphOptions, setGraphOptions] = useState({});
  const [tableData, setTableData] = useState<Local_Graph_Table_Props[]>([]);
  const [tableDataColumns, setTableDataColumns] = useState<Local_Graph_Table_Column_Props[]>([]);
  const [selectedFrequency, setSelectedFrequency] = useState<string>(config.frequency || xvars[0] || '');
  const [visibleRight, setVisibleRight] = useState<boolean>(false);
  const [showPercentChange, setShowPercentChange] = useState<boolean>(false);
  const [csvData, setCSVData] = useState<Local_CSV_Export_Props>();

  // const [isDashboard, setIsDashboard] = useState<boolean>(false);

  const echartsRef = useRef(null);
  const nativeInsets = getNativeInsets();

  // Table State
  const [tableKMB, setTableKMB] = useState<Local_KMB>('b');
  const [selectedTableFrequency, setSelectedTableFrequency] = useState<string>(config.frequency || xvars[0] || '');

  // console.log('graph: ', { data, config, myData: data[selectedFrequency], selectedFrequency, xvars });

  // console.log('graph data: ', { data, config, xvars, _id, index, originalMessageId, userFeedback, firstResponse, className });

  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  const frequencyOptions: { name: string; value: string }[] = xvars.map((val) => ({
    name: val,
    value: val,
  }));

  const kmbOptions: { name: string; value: Local_KMB }[] = [
    {
      name: 'k',
      value: 'k',
    },
    {
      name: 'm',
      value: 'm',
    },
    {
      name: 'b',
      value: 'b',
    },
  ];

  const rangeOptions: { name: string; value: number }[] = timeRanges.map((val, index) => ({
    name: timeRangeToSting(val, timeRanges.length - 1 === index),
    value: val,
  }));

  // register graph theme object
  registerTheme('dark_theme', dark_theme);
  registerTheme('light_theme', light_theme);

  useEffect(() => {
    calculateYearPercentage(timeRange);
  }, [data, timeRange, selectedFrequency, graphDisplayType, showPercentChange, visibleRight]);

  // Update table data any time it changes
  useEffect(() => {
    configureTableData();
  }, [selectedTableFrequency]);

  function configureTableData() {
    const myData = data[selectedTableFrequency];

    if (!myData) return;

    const companies = myData.dimensions.filter((dim) => dim !== 'x_value');

    // To Do: Have this listen to time ranges
    setTableData(myData.y_values);
    setTableDataColumns([
      {
        field: 'x_value',
        header: selectedTableFrequency,
      },
      ...companies.map((elem) => ({
        field: elem,
        header: elem,
      })),
    ]);

    setCSVData(generateCSVData(myData.y_values, config));
  }

  function calculateYearPercentage(years: number) {
    const myData = data[selectedFrequency];

    if (!myData) return;

    // Extract years from the data and sort them
    const yearsList = myData?.y_values
      // @ts-ignore
      .map((item) => parseInt(item.x_value))
      .sort((a, b) => a - b);

    // Get the earliest and latest year
    const startYear = yearsList[0];
    const currentYear = new Date().getFullYear();

    // Calculate the total range of years
    const totalYears = currentYear - startYear;

    // Calculate the percentage
    const percentage = (years / totalYears) * 100;

    // Gets the max value from 'y_values'
    const maxValue = Math.max(...myData?.y_values.map((item) => Math.max(...Object.values(item).filter((val) => typeof val === 'number'))));

    setTimeRanges([5, 10, totalYears]);

    selectedFrequency;
    let datasetToUse = [];

    if (showPercentChange) {
      // @ts-ignore
      if (selectedFrequency === 'annual' || selectedFrequency === 'yearly') datasetToUse = myData?.percent_yearly_change;
      // @ts-ignore
      else if (selectedFrequency === 'quarterly') datasetToUse = myData?.percent_quarterly_change;
      else datasetToUse = [];
    } else {
      datasetToUse = myData?.y_values;
    }

    /**
     * These are common graph options. Unless we update 'mergeObjects' to not suck, we need to re-declare options
     * in specific graphs (such as 'lineGraphOptions' below) since 'mergeObjects' is not overriding any re-declared options.
     */
    let newGraphOptions = {
      dataset: {
        dimensions: myData?.dimensions || [],
        source: datasetToUse,
      },
      legend: {
        top: 5,
        left: 0,
      },
      grid: {
        left: '0%',
        right: '2%',
        containLabel: true,
      },
      dataZoom: [
        {
          type: 'inside',
          start: 100 - percentage,
          end: 100,
          filterMode: 'filter',
        },
        {
          start: 0,
          end: 100,
        },
      ],
      toolbox: {
        show: true,
        feature: {
          myTool1: {
            show: true,
            title: '% Change',
            icon: 'path://M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z',
            onclick: () => setShowPercentChange(!showPercentChange),
            iconStyle: {
              borderWidth: 0,
              color: showPercentChange ? 'var(--primary-light)' : '#999',
            },
          },
          myTool2: {
            show: true,
            title: `${graphDisplayType === 'bar' ? 'Line' : 'Bar'} graph`,
            icon:
              graphDisplayType === 'bar'
                ? 'path://m126-220-46-46 300-300 160 161 298-335 42 41-340 384-160-159-254 254Z'
                : 'path://M660-160v-280h140v280H660Zm-250 0v-640h140v640H410Zm-250 0v-440h140v440H160Z',
            onclick: () => {
              setShowPercentChange(false);
              setGraphDisplayType(graphDisplayType === 'bar' ? 'line' : 'bar');
            },
            iconStyle: {
              borderWidth: 0,
              color: '#999',
            },
          },
          myTool3: {
            show: true,
            title: 'Table',
            icon: 'path://M160-224.615v-510.77q0-26.654 18.981-45.634Q197.961-800 224.615-800h510.77q26.654 0 45.634 18.981Q800-762.039 800-735.385v510.77q0 26.654-18.981 45.634Q762.039-160 735.385-160h-510.77q-26.654 0-45.634-18.981Q160-197.961 160-224.615Zm40-369.231h560v-141.539q0-10.769-6.923-17.692T735.385-760h-510.77q-10.769 0-17.692 6.923T200-735.385v141.539Zm199.923 196.923h160.154v-156.923H399.923v156.923Zm0 196.923h160.154v-156.923H399.923V-200ZM200-396.923h159.923v-156.923H200v156.923Zm400.077 0H760v-156.923H600.077v156.923ZM224.615-200h135.308v-156.923H200v132.308q0 10.769 6.923 17.692T224.615-200Zm375.462 0h135.308q10.769 0 17.692-6.923T760-224.615v-132.308H600.077V-200Z',
            onclick: () => {
              configureTableData();
              setVisibleRight(true);
            },
            iconStyle: {
              borderWidth: 0,
              color: visibleRight ? 'var(--primary-light)' : '#999',
            },
          },
          restore: { show: true },
          saveAsImage: { show: true, title: 'Save', name: `Castello.ai - ${config.title}` || 'Castello.ai Chart' },
        },
      },
    };

    if (showPercentChange) {
      const lineGraphOptions = {
        tooltip: {
          trigger: 'axis',
          valueFormatter: (value: number) => `${value}%`,
          axisPointer: {
            type: 'cross',
            label: {
              color: '#fff',
              backgroundColor: '#565655',
              formatter: ({ value }: { value: number | string }) => {
                // String will the the title, number will be the actual 'value'
                if (typeof value === 'string') return value;
                return `${limitDecimalPlaces(value, 2)}%`;
              },
            },
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
        },
        yAxis: {
          axisLabel: {
            type: 'value',
            formatter: '{value}%',
          },
        },
      };
      // eslint-disable-next-line
      newGraphOptions = mergeObjects(newGraphOptions, lineGraphOptions);
    } else {
      // GraphOptions Setup
      if (graphDisplayType === 'bar') {
        const barGraphOptions = {
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value: number) => formatNumberBasedOnMaxNumber(value, 2, maxValue),
            axisPointer: {
              type: 'shadow',
            },
          },
          grid: {},
          xAxis: {
            type: 'category',
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value: number) => formatNumberBasedOnMaxNumber(value, 0, maxValue),
            },
          },
        };
        // eslint-disable-next-line
        newGraphOptions = mergeObjects(newGraphOptions, barGraphOptions);
      }
      if (graphDisplayType === 'line') {
        const lineGraphOptions = {
          tooltip: {
            trigger: 'axis',
            valueFormatter: (value: number) => formatNumberBasedOnMaxNumber(value, 2, maxValue),
            axisPointer: {
              type: 'cross',
              label: {
                color: '#fff',
                backgroundColor: '#565655',
                formatter: ({ value }: { value: number | string }) => {
                  if (typeof value === 'string') return value;
                  return formatNumberBasedOnMaxNumber(value, 0, maxValue);
                },
              },
            },
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: (value: number) => formatNumberBasedOnMaxNumber(value, 0, maxValue),
            },
          },
        };
        // eslint-disable-next-line
        newGraphOptions = mergeObjects(newGraphOptions, lineGraphOptions);
      }
    }

    // eslint-disable-next-line
    let newSeries: GraphSeries[] = config.series.map((item) => {
      const displayType = showPercentChange ? 'line' : graphDisplayType;

      // eslint-disable-next-line
      const newObj = mergeObjects(item, { type: displayType });

      if (displayType === 'line') {
        // eslint-disable-next-line
        newObj['type'] = 'line';
        // eslint-disable-next-line
        // newObj['areaStyle'] = {};
        // eslint-disable-next-line
        newObj['emphasis'] = {
          focus: 'series',
        };
        // eslint-disable-next-line
        newObj['smooth'] = true;
        // eslint-disable-next-line
        newObj['symbol'] = 'circle';
        // eslint-disable-next-line
        newObj['symbolSize'] = 5;
        // eslint-disable-next-line
        newObj['sampling'] = 'average';
      }

      // eslint-disable-next-line
      return newObj;
    });

    // eslint-disable-next-line
    newGraphOptions = mergeObjects(newGraphOptions, { series: newSeries });
    setGraphOptions(newGraphOptions);
  }

  // Table data formatter
  const tableItemDataFormatter = (data: Local_Graph_Table_Props, options: ColumnBodyOptions) => {
    // Return 'x_value' as is
    if (options.field === 'x_value') return data[options.field];

    // Return formatted number
    return kmbFormatter(data[options.field] as number, tableKMB);
  };

  const dataCy = `server-response-block-graph`;
  let citationLink = '';

  if (config?.graph_type === 'finmetric') {
    citationLink = 'https://www.nasdaq.com/market-activity';
  } else if (config?.graph_type === 'worldbank') citationLink = 'https://data.worldbank.org/indicator';
  else if (config?.graph_type === 'economic') citationLink = 'https://fred.stlouisfed.org/';
  else citationLink = 'https://www.nasdaq.com/market-activity/';

  // console.log('graph: ', { data, config, xvars, _id, index, originalMessageId, userFeedback, firstResponse, className });
  const blockId = `response-block-server-${config?.title.replaceAll(' ', '-').replaceAll(',', '')}`;

  return (
    <div id={blockId} className={`response-block-wrapper ${lastResponse ? 'response-block-last-response' : ''}`} data-cy={dataCy}>
      <Sidebar
        visible={visibleRight}
        header={config.title}
        position="right"
        className="collapsible-sidebar"
        onHide={() => setVisibleRight(false)}
        style={{ paddingTop: nativeInsets?.top || 0, paddingBottom: nativeInsets?.bottom || 0 }}
      >
        <div className="collapsible-sidebar-header">
          <div className="graph-menu-wrapper">
            <div className="graph-menu-left">
              <SelectButton
                value={selectedTableFrequency}
                onChange={(e) => setSelectedTableFrequency(e.value as string)}
                optionLabel="name"
                options={frequencyOptions}
                allowEmpty={false}
                unselectable={false}
              />
              <SelectButton value={tableKMB} onChange={(e) => setTableKMB(e.value as Local_KMB)} optionLabel="name" options={kmbOptions} allowEmpty={false} unselectable={false} />
            </div>

            <div className="graph-menu-right">
              {tableData && csvData && (
                <CSVLink filename={csvData.filename} data={csvData?.data} headers={csvData?.headers} className="icon-row-item">
                  <Button icon={'pi pi-download'} className="smallRegular">
                    Download CSV
                  </Button>
                </CSVLink>
              )}
            </div>
          </div>
        </div>
        <DataTable value={tableData} scrollable rows={TABLE_DATA_DEFAULT_ROW_COUNT}>
          {tableDataColumns.map((col, i) => (
            <Column frozen={i === 0 ? true : false} body={tableItemDataFormatter} key={col.field} field={col.field} header={col.header} />
          ))}
        </DataTable>
      </Sidebar>
      <div className={`response-block-common-style server-response response-block-graph-wrapper`}>
        <ResponseBlockIcon usertype={'server'} visible={firstResponse} />
        {window.location?.pathname?.includes('/dashboard') && <div className="drag-handle" />}
        <div className="title-wrapper">
          <div className="inner-title-wrapper">
            <div className="graph-title mediumBold">{config.title}</div>
            <div className="title-icon-row">
              <i className={`pi pi-external-link xsmallRegular clickable`} onClick={() => window.open(citationLink, '_blank')} />
            </div>
          </div>

          <div className="graph-menu-wrapper">
            <SelectButton
              value={selectedFrequency}
              onChange={(e) => {
                // Set table data options same as graph options
                setSelectedTableFrequency(e.value as string);
                setSelectedFrequency(e.value as string);
              }}
              optionLabel="name"
              options={frequencyOptions}
              allowEmpty={false}
              unselectable={false}
            />

            <SelectButton
              className="desktop-only"
              value={timeRange}
              onChange={(e) => setTimeRange(e.value as number)}
              optionLabel="name"
              options={rangeOptions}
              allowEmpty={false}
              unselectable={false}
            />
          </div>
        </div>

        <div className="chart-wrapper" id={_id}>
          <ReactECharts
            ref={echartsRef}
            notMerge
            option={graphOptions}
            theme={activeTheme === 'dark-theme' ? 'dark_theme' : 'light_theme'}
            className={className}
            style={{ height: '100%', width: '100%' }}
          />
        </div>
      </div>
      <ResponseBlockFeedback dataRef={echartsRef} tableData={tableData} config={config} type={'graph'} messageId={originalMessageId} visible={lastResponse} userFeedback={userFeedback} />
    </div>
  );
});
