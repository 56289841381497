import { Button } from 'primereact/button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { _sendVerificationEmail } from '../../api';
import { TOAST_DURATION } from '../../utils/constants';
import { useToast } from '../../providers';
import { useAuth, useCommon } from '../../hooks';
import { BeautifyError } from '../../utils';
import './index.scss';

const EmailVerification = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { fullSignOutMutation } = useCommon();
  const { getUser } = useAuth();

  const [allowResend, setAllowResend] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  const resendEmailVerification = () => {
    setLoading(true);
    setAllowResend(false);

    setTimeout(() => {
      setLoading(false);
      setAllowResend(true);

      _sendVerificationEmail()
        .then(() => {
          toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Resent email verification', life: TOAST_DURATION });
        })
        .catch((e) => {
          // eslint-disable-next-line
          toast?.current?.show({ severity: 'error', summary: 'Error', detail: BeautifyError(e), life: TOAST_DURATION });
          setAllowResend(true);
          setLoading(false);
        });
    }, 500);
  };

  return (
    <div className="email-verification-screen-wrapper">
      <div className="email-verification-content-wrapper">
        <div className="title xxlargeBold">Thanks for signing up!</div>
        <div className="subtitle smallRegular m-b-xs">
          We've sent an email to
          <span className="smallBold m-l-xs" style={{ color: 'var(--primary)' }}>
            {getUser()?.email || 'you.'}
          </span>
        </div>
        <div className="subtitle smallRegular m-b-xxl">Before we continue, please verify your email.</div>

        <div className="subtitle smallRegular m-b-sm">Don't see an email yet?</div>
        <Button className="smallMedium m-b-sm" data-cy="resend-email-verification-button" onClick={resendEmailVerification} disabled={!allowResend} loading={loading}>
          Resend Email Verification
        </Button>
        <div className="subtitle smallRegular  m-b-sm">or</div>

        <Button
          className="smallMedium m-b-md"
          onClick={() => {
            fullSignOutMutation.mutateAsync().then((signedOut) => {
              if (signedOut) navigate('/');
            });
          }}
          severity="secondary"
          loading={fullSignOutMutation.isLoading}
          data-cy="email-verification-logout-button"
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default EmailVerification;
