import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import { _createDashboard, _deleteDashboard, _getDashboardById, _getDashboards, _searchDashboards, _updateDashboard } from '../api';
import {
  ApiBody_Delete_Dashboard_Props,
  ApiBody_Get_Chats_Props,
  ApiBody_Post_Dashboard_Props,
  ApiBody_Put_Dashboard_Props,
  ApiResponse_Dashboard_Document_Props,
  ApiResponse_Dashboard_Graph_Item_Props,
  ApiResponse_Dashboard_Props,
  ApiResponse_Get_Dashboard_Props,
  Local_Dashboard_History_Props,
  Local_Dashboard_History_Update_Props,
  Query_Chat_Search_Props,
  Query_GetDashboardById_Props,
  initialDashboardHistory,
  logError,
} from '../utils';
import { useGraph } from './useGraph';
import { createContext, useContext } from 'react';

interface UseDashboardApiProps {
  // To get the mutation props, comment out the prop here, then get the intellisence from the actual function.
  createDashboardMutation: UseMutationResult<ApiResponse_Dashboard_Document_Props, unknown, ApiBody_Post_Dashboard_Props, unknown>;
  getDashboardsByIdMutation: UseMutationResult<ApiResponse_Get_Dashboard_Props, unknown, Query_GetDashboardById_Props, unknown>;
  getDashboardsMutation: UseMutationResult<ApiResponse_Dashboard_Props, unknown, ApiBody_Get_Chats_Props, unknown>;
  deleteDashboardMutation: UseMutationResult<string, unknown, ApiBody_Delete_Dashboard_Props, unknown>;
  dashboardSearchMutation: UseMutationResult<ApiResponse_Dashboard_Props, unknown, Query_Chat_Search_Props, unknown>;
  updateDashboardMutation: UseMutationResult<ApiResponse_Dashboard_Document_Props, unknown, ApiBody_Put_Dashboard_Props, unknown>;
  getDashboardHistory: () => Local_Dashboard_History_Props;
  getFilteredDashboards: () => ApiResponse_Dashboard_Document_Props[];
  setFilteredDashboards: (filteredDashboards: ApiResponse_Dashboard_Document_Props[]) => void;
  setActiveDashboard: (dashboardId: string) => void;
  getActiveDashboard: () => string;
  updateDashboardHistory: (props: Local_Dashboard_History_Update_Props) => void;
  addToDashboardMap: (dashboard: ApiResponse_Dashboard_Document_Props, graphs: ApiResponse_Dashboard_Graph_Item_Props[]) => void;
  resetDashboard: () => void;
}

// ------------------ Context ------------------
export const DashboardApiContext = createContext<UseDashboardApiProps | null>(null);

// ------------------ Provider ------------------
export const useDashboardApi = (): UseDashboardApiProps => {
  const context = useContext(DashboardApiContext);
  if (!context) {
    throw new Error('useDashboardApi must be used within a ChatApiProvider');
  }
  return context;
};

// ------------------ Dashboard API Hook ------------------
export const useDashboard = () => {
  const queryClient = useQueryClient();
  const { addToGraphMap } = useGraph();

  const createDashboardMutation = useMutation({
    mutationFn: async ({ title }: ApiBody_Post_Dashboard_Props) => _createDashboard({ title }),
    onSuccess: (data: ApiResponse_Dashboard_Document_Props) => {
      console.log('createDashboardMutation success', data);
      addToDashboardMap(data, []);
    },
    onError: (e) => {
      logError(e, 'createDashboardMutation');
    },
  });

  // Fetch our Dashboard history
  const getDashboardsMutation = useMutation({
    mutationFn: ({ pageNumber }: ApiBody_Get_Chats_Props) => _getDashboards({ pageNumber }),
    onSuccess: ({ dashboards }: ApiResponse_Dashboard_Props) => {
      console.log('getDashboardsMutation success: ', dashboards);
      dashboards.map((dashboard) => {
        addToDashboardMap(dashboard, []);
      });
    },
    onError: (e) => {
      logError(e, 'getDashboardsMutation');
    },
  });

  // Gets dashboard by ID
  const getDashboardsByIdMutation = useMutation({
    mutationFn: ({ dashboardId }: Query_GetDashboardById_Props) => _getDashboardById({ dashboardId }),
    onSuccess: ({ dashboard, graphs }: ApiResponse_Get_Dashboard_Props) => {
      console.log('getDashboardsByIdMutation success: ', { dashboard, graphs });
      addToDashboardMap(dashboard, graphs);
    },
    onError: (e) => {
      logError(e, 'getDashboardsByIdMutation');
    },
  });

  // Delete a dashboard
  const deleteDashboardMutation = useMutation({
    mutationFn: ({ dashboardId }: ApiBody_Delete_Dashboard_Props) => _deleteDashboard({ dashboardId }),
    onSuccess: (dashboardIdToRemove: string) => {
      console.log('deleteDashboardMutation success: ', dashboardIdToRemove);
      deleteDashboardHistoryItem(dashboardIdToRemove);
      setActiveDashboard('');
    },
    onError: (e) => {
      logError(e, 'deleteDashboardMutation');
    },
  });

  // Dashboard search function
  const dashboardSearchMutation = useMutation({
    mutationFn: ({ searchQuery, pageNumber }: Query_Chat_Search_Props) => _searchDashboards({ searchQuery, pageNumber }),
    onSuccess: ({ dashboards }: ApiResponse_Dashboard_Props) => {
      console.log('dashboardSearchMutation success: ', dashboards);
      setFilteredDashboards(dashboards);
    },
    onError: (e) => {
      logError(e, 'dashboardSearchMutation');
    },
  });

  // Dashboard update function
  const updateDashboardMutation = useMutation({
    mutationFn: (props: ApiBody_Put_Dashboard_Props) => _updateDashboard(props),
    onError: (e) => {
      logError(e, 'updateDashboardMutation');
    },
  });

  // Deletes an item from chat history
  function deleteDashboardHistoryItem(chatId: string) {
    const newChatHistory = { ...getDashboardHistory() };
    delete newChatHistory[chatId];
    setDashboardHistory(newChatHistory);
  }

  // Adds a chat to the map
  const addToDashboardMap = (dashboard: ApiResponse_Dashboard_Document_Props, graphs: ApiResponse_Dashboard_Graph_Item_Props[]) => {
    const graphIdArray: string[] = [];
    if (graphs) {
      // Add each incoming graph to GraphHistory
      graphs.forEach((graph) => {
        addToGraphMap(graph);
        graphIdArray.push(graph._id);
      });
    }

    // console.log('addToDashboardMap: ', { [dashboard._id]: dashboard });
    updateDashboardHistory({ dashboardIdToUpdate: dashboard._id, dashboard, graphs: graphIdArray });
  };

  // Updates an item in dashboard history (can also add an item if a unique chatId is passed in)
  function updateDashboardHistory({ dashboardIdToUpdate, dashboard, graphs = [] }: Local_Dashboard_History_Update_Props) {
    const dashboardHistory = getDashboardHistory();

    // console.log('updateDashboardHistory 1: ', { dashboardIdToUpdate, dashboard, graphs });

    // Update the chatHistory with the new arrays
    const updatedDashboardHistory = {
      ...dashboardHistory,
      [dashboardIdToUpdate]: {
        ...dashboardHistory[dashboardIdToUpdate],
        dashboard: dashboard || dashboardHistory[dashboardIdToUpdate].dashboard,
        graphs: graphs || dashboardHistory[dashboardIdToUpdate].graphs,
      },
    };

    // console.log('updateDashboardHistory 2: ', updatedDashboardHistory);

    // Set the updated chat history
    setDashboardHistory(updatedDashboardHistory);
  }

  const getDashboardHistory = (): Local_Dashboard_History_Props => {
    return queryClient.getQueryData('dashboardHistory') || initialDashboardHistory;
  };

  // Sets the dashboard history
  const setDashboardHistory = (dashboardHistory: Local_Dashboard_History_Props) => {
    queryClient.setQueryData('dashboardHistory', dashboardHistory);
  };

  // Returns filtered dashboard data
  const getFilteredDashboards = (): ApiResponse_Dashboard_Document_Props[] => {
    return queryClient.getQueryData('filteredDashboards') || [];
  };

  // Set filtered dashboard data
  const setFilteredDashboards = (filteredDashboards: ApiResponse_Dashboard_Document_Props[]) => {
    return queryClient.setQueryData('filteredDashboards', filteredDashboards);
  };

  const setActiveDashboard = (dashboardId: string) => {
    queryClient.setQueryData('activeDashboard', dashboardId);
  };

  const getActiveDashboard = (): string => {
    return queryClient.getQueryData('activeDashboard') || '';
  };

  // Reset the dashboard queries
  const resetDashboard = () => {
    queryClient.setQueryData('activeDashboard', '');
    queryClient.setQueryData('filteredDashboards', []);
    queryClient.setQueryData('dashboardHistory', initialDashboardHistory);
    queryClient.setQueryData('dashboardMap', {});
  };

  return {
    createDashboardMutation,
    getDashboardsByIdMutation,
    getDashboardsMutation,
    deleteDashboardMutation,
    dashboardSearchMutation,
    updateDashboardMutation,
    getDashboardHistory,
    getFilteredDashboards,
    setFilteredDashboards,
    setActiveDashboard,
    getActiveDashboard,
    addToDashboardMap,
    updateDashboardHistory,
    resetDashboard,
  };
};
