import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { getRedirectResult } from 'firebase/auth';
import router from './router';
import { _signinApi } from './api';
import { useCommon, usePublic, useTheme } from './hooks';
// import { TurnstileOverlay } from './components';
import { Local_RN_Inset_Props, getNativeInsets, logError } from './utils';
import { auth } from './services/firebase';
import { logEvent } from './services/analytics';
import { TOAST_DURATION } from './utils/constants';
import { getStorageItem, setStorageItem, storageItems } from './utils/localStorage';
import { useToast } from './providers/toastProvider';
import LoadingScreen from './screens/loading-screen';
import './index.scss';

function App() {
  logEvent('app_opened');

  const { getTheme, setTheme } = useTheme();
  const { setOnlineStatus, getOnlineStatus } = useCommon();
  const { createGuestSessionMutation } = usePublic();
  const toast = useToast();

  const { data: isOnline = true } = useQuery('online', getOnlineStatus);
  const nativeInsets = getNativeInsets();

  const [loadingApp, setLoadingApp] = useState(true);
  const [networkFailed, setNetworkFailed] = useState(false);

  // const [showTurnstile, setShowTurnstile] = useState(false);

  // Actions to run once the app mounts
  function onMountActions() {
    getRedirectResult(auth)
      .then(async (firebaseUser) => {
        if (firebaseUser) {
          await _signinApi(firebaseUser);
        }
      })
      .catch((error) => {
        logError(error, 'getRedirectResult', 'Error getting redirect result');
      });

    // Test URL for native insets
    // http://localhost:8080/?isNativeApp=true&nativeInsets={%22bottom%22:34,%22right%22:0,%22top%22:59,%22left%22:0}

    const queryParams = new URLSearchParams(location.search);
    const isNativeAppParam = queryParams.get('isNativeApp') === 'true';
    const nativeInsetsParam = queryParams.get('nativeInsets');

    // Check if this is running via our mobile app
    if (!getStorageItem(storageItems.isNativeApp) && (window.isNativeApp || isNativeAppParam)) {
      setStorageItem(storageItems.isNativeApp, true);
    }

    // Check for incoming native app insets
    if (nativeInsetsParam) {
      try {
        const decodedNativeInsets: Local_RN_Inset_Props = JSON.parse(decodeURIComponent(nativeInsetsParam)) as Local_RN_Inset_Props;

        // Validate the parsed object to ensure it has the expected structure
        if (
          decodedNativeInsets &&
          typeof decodedNativeInsets === 'object' &&
          decodedNativeInsets.top !== undefined &&
          decodedNativeInsets.bottom !== undefined &&
          decodedNativeInsets.left !== undefined &&
          decodedNativeInsets.right !== undefined
        ) {
          setStorageItem(storageItems.nativeInsets, decodedNativeInsets);
        } else {
          logError(new Error('Invalid insets'), 'Middleware', `Error parsing nativeInsets: ${nativeInsetsParam}`);
        }
      } catch (err) {
        logError(err, 'Middleware', `Error parsing nativeInsets: ${nativeInsetsParam}`);
      }
    }
  }

  useEffect(() => {
    const handler = async () => {
      onMountActions();
      // eslint-disable-next-line
      const foundTheme = await getTheme();
      // eslint-disable-next-line
      setTheme(foundTheme); // Set the theme to the found theme
      setStorageItem(storageItems.completedWelcomeScreens, true); // Temporary bypass for welcome screens, requested by Zain

      if (!getStorageItem(storageItems.guestId)) {
        await createGuestSessionMutation.mutateAsync(); // Creates a 'guestId' in local storage if it doesn't exist
      }
      setOnlineStatus(navigator.onLine); // Set the online status to the current online status
      setLoadingApp(false); // Set loading to false
    };

    handler().catch((e) => logError(e, 'App.useEffect'));
  }, []);

  // Listen for online/offline events
  useEffect(() => {
    const goOnline = () => setOnlineStatus(true);
    const goOffline = () => setOnlineStatus(false);

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  useEffect(() => {
    // Network is offline
    if (!isOnline) {
      setNetworkFailed(true);
      console.log('Network is offline'); // Don't remove this log
      toast?.current?.show({ severity: 'warn', summary: 'Offline', detail: "It looks like you've lost connection", life: TOAST_DURATION * 2 });
    }

    // Network is online & it failed previously
    if (isOnline && networkFailed) {
      setNetworkFailed(false);
      console.log('Network was recovered'); // Don't remove this log
      toast?.current?.show({ severity: 'success', summary: 'Online', detail: "You're back online!", life: TOAST_DURATION });
    }
  }, [isOnline]);

  // // When the app loads, load up Turnstile script
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
  //   script.async = true;
  //   script.defer = true;
  //   script.onload = () => turnstileLoad(); // Call the turnstileLoad function when the script loads
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  // Loads the turnstile widget, and handles the callback, which will show/hide the widget
  // const turnstileLoad = () => {
  //   // @ts-ignore
  //   // eslint-disable-next-line
  //   turnstile.render('#turnstile_widget', {
  //     sitekey: process.env.REACT_APP_CLOUDFLARE_CLIENT_KEY,
  //     theme: 'light',
  //     // @ts-ignore
  //     // eslint-disable-next-line
  //     callback: (token, preClearanceObtained) => {
  //       // eslint-disable-next-line
  //       console.log('hit turnstileLoad callback: ', { token, preClearanceObtained });
  //       // Hide Turnstile on success
  //       setShowTurnstile(false);
  //     },
  //     // eslint-disable-next-line
  //     'error-callback': (e: any) => {
  //       console.log('hit turnstileLoad error: ', e);

  //       // Hide this on localhost
  //       if (process.env.REACT_APP_NODE_ENV !== 'development') {
  //         // Show Turnstile on validation error
  //         setShowTurnstile(true);
  //       }
  //     },
  //   });
  // };

  // 'dvh' seems to solve mobile viewport issues
  return (
    <div className="screen-wrapper" style={{ height: '100dvh', paddingBottom: nativeInsets?.bottom ? nativeInsets?.bottom / 2 : nativeInsets?.bottom }}>
      {/* <TurnstileOverlay show={showTurnstile} /> */}
      {loadingApp ? <LoadingScreen /> : <RouterProvider router={router} />}
    </div>
  );
}

export default App;
