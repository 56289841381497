import { InfiniteData, UseMutationResult, useMutation, useQueryClient } from 'react-query';
import {
  _newChat,
  _getChats,
  _deleteChat,
  _searchChats,
  _updateChat,
  _updateMessage,
  _checkQueryStatus,
  _getAccessToken,
  _sendFeedback,
  pinMessage,
  unpinMessage,
  _getAllMessagesUntilDate,
} from '../api';
import {
  ApiBody_Post_Chats_Messages_Props,
  ApiResponse_Chat_Props,
  ApiResponse_Followup_Props,
  ApiResponse_Message_Props,
  Query_ChatApiMutation_Props,
  Component_ResponseBlock_Props,
  parseServerResponse,
  initialChatData,
  initialChatAPIMutation,
  initialResponseBlocks,
  ApiBody_Get_Chats_Props,
  ApiResponse_Chat_Document_Props,
  Util_Message_Mutation_Props,
  ApiBody_Delete_Chat_Props,
  Query_Chat_Search_Props,
  ApiBody_Put_Chat_Props,
  ApiResponse_Post_Chat_Message_Props,
  Local_Chat_History_Props,
  initialChatHistory,
  ApiResponse_Put_Message_Props,
  initialUserFeedback,
  Local_Chat_History_Update_Props,
  ApiResponse_Chats_Status_Props,
  Message_Map_Props,
  convertDateToEpoch,
  Local_SetStreamChat_Props,
  parseGraphs,
  getRandomString,
  Local_Stream_Map,
  Local_Citations_Props,
  Local_Authentication_Status_Props,
  ApiRequest_Stream_Chat_Props,
  Local_Put_Message_Props,
  ApiResponse_Feedback_Props,
  ApiBody_Authenticated_Feedback_Props,
  logError,
  Local_TogglePinMutation_Props,
  ApiResponse_Get_AllMessagesUntil_Props,
  ApiRequest_Get_AllMessagesUntil_Props,
  ApiResponse_Get_Chats_Messages_Props,
} from '../utils';
import { createContext, useContext, useEffect, useState } from 'react';

// @ts-ignore
// eslint-disable-next-line
import ndjsonStream from 'can-ndjson-stream';
import { useAuth } from './useAuth';
import { _createPublicChat } from '../api/public';
import { MAXIMUM_QUERY_CHARACTER_LIMIT } from '../utils/constants';
import { getStorageItem, removeStorageItem, setStorageItem, storageItems } from '../utils/localStorage';
import { BackendApiError } from '../errors';
import DOMPurify from 'dompurify';
import { useChatListStore } from '../store';

interface UseChatApiProps {
  // To get the mutation props, comment out the prop here, then get the intellisence from the actual function.
  chatAPIMutation: UseMutationResult<ApiResponse_Post_Chat_Message_Props, unknown, Query_ChatApiMutation_Props, unknown>;
  getChatsMutation: UseMutationResult<ApiResponse_Chat_Props, unknown, ApiBody_Get_Chats_Props, unknown>;
  deleteChatMutation: UseMutationResult<string, unknown, ApiBody_Delete_Chat_Props, unknown>;
  chatSearchMutation: UseMutationResult<ApiResponse_Chat_Props, unknown, Query_Chat_Search_Props, unknown>;
  updateChatMutation: UseMutationResult<ApiResponse_Chat_Document_Props, unknown, ApiBody_Put_Chat_Props, unknown>;
  updateMessageMutation: UseMutationResult<ApiResponse_Put_Message_Props, unknown, Local_Put_Message_Props, unknown>;
  chatStatusMutation: UseMutationResult<ApiResponse_Chats_Status_Props, unknown, void, unknown>;
  createFeedbackMutation: UseMutationResult<ApiResponse_Feedback_Props, unknown, ApiBody_Authenticated_Feedback_Props, unknown>;
  toggleMessagePinMutation: UseMutationResult<ApiResponse_Chat_Document_Props, unknown, Local_TogglePinMutation_Props, unknown>;
  getAllMessagesUntilMutation: UseMutationResult<ApiResponse_Get_AllMessagesUntil_Props, unknown, ApiRequest_Get_AllMessagesUntil_Props, unknown>;
  updateChatHistory: (props: Local_Chat_History_Update_Props) => void;
  sendChat: ({ prompt, chatId }: ApiBody_Post_Chats_Messages_Props) => void;
  resetChat: () => void;
  getFilteredChats: () => ApiResponse_Chat_Document_Props[];
  setFilteredChats: (filteredChats: ApiResponse_Chat_Document_Props[]) => void;
  getChatHistory: () => Local_Chat_History_Props;
  setChatHistory: (chatHistory: Local_Chat_History_Props) => void;
  setChatStream: ({ group_id, data }: Local_SetStreamChat_Props) => void;
  getChatStream: () => Local_Stream_Map;
}

// ------------------ Context ------------------
export const ChatApiContext = createContext<UseChatApiProps | null>(null);

// ------------------ Provider ------------------
export const useChatApi = (): UseChatApiProps => {
  const context = useContext(ChatApiContext);
  if (!context) {
    throw new Error('useChatApi must be used within a ChatApiProvider');
  }
  return context;
};

// ------------------ Chat API Hook ------------------
export const useChat = () => {
  const queryClient = useQueryClient();
  const { getUser } = useAuth();
  const [activeMessage, setActiveMessage] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
    const handleBeforeUnload = (event: any) => {
      if (activeMessage) {
        const message = 'Your answer is currently streaming. Are you sure you want to leave? This will stop the current stream.';
        // eslint-disable-next-line
        event.returnValue = message; // For most browsers
        return message; // For some older browsers
      }
    };

    // Attach the event listener
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [activeMessage]);

  // Our chat mutation function
  const chatAPIMutation = useMutation({
    onMutate: () => {
      setActiveMessage(true);
    },
    mutationFn: async ({ type, prompt, chatId = '' }: Query_ChatApiMutation_Props): Promise<ApiResponse_Post_Chat_Message_Props> => {
      const user = getUser();
      const guestId = getStorageItem(storageItems.guestId) as string;

      const authenticationStatus: Local_Authentication_Status_Props = user ? 'authenticated' : guestId ? 'guest' : 'none';

      if (type === 'new') {
        let newChat = {} as ApiResponse_Chat_Document_Props;

        // Create new chat based on auth type
        if (authenticationStatus === 'authenticated') {
          newChat = await _newChat({ prompt });
        } else if (authenticationStatus === 'guest') {
          newChat = (await _createPublicChat({ prompt, guestId }))?.chat;
        }

        updateChatHistory({
          chatIdToUpdate: newChat._id,
          chat: newChat,
          messages: {},
          formattedMessages: [],
          followupQuestions: [],
          citations: {},
          error: { prompt: '', message: '' },
        });

        // setActiveChat(newChat._id);
        return await streamChat({ prompt, chatId: newChat._id });
      } else if (type === 'existing') {
        updateChatHistory({ chatIdToUpdate: chatId, error: { prompt: '', message: '' } });
        return await streamChat({ prompt, chatId });
      }
      return initialChatAPIMutation;
    },
    mutationKey: ['chat', 'messages'],
    onSuccess: ({ chat, message }: ApiResponse_Post_Chat_Message_Props) => {
      console.log('chatAPIMutation success: ', { chat, message });

      if (chat._id) {
        removeStorageItem(storageItems.lastMessage);
        queryClient.setQueryData('chatStream', new Map());
        addToChatMap(chat, [message]);
      }
      setActiveMessage(false);
    },
    // eslint-disable-next-line
    onError: (e: any) => {
      // eslint-disable-next-line
      setMessageError(e?.message);
      setActiveMessage(false);
      logError(e, 'chatAPIMutation');
    },
    onSettled: () => {
      setActiveMessage(false);
    },
  });

  const setMessageError = (message: string) => {
    const chatHistory = getChatHistory();

    const lastActiveChatId = getStorageItem(storageItems.lastActiveChatId) as string;
    const lastMessage = getStorageItem(storageItems.lastMessage);

    // Update the chatHistory with the new arrays
    const updatedChatHistory = {
      ...chatHistory,
      [lastActiveChatId]: {
        ...chatHistory[lastActiveChatId],
        error: {
          // @ts-ignore
          // eslint-disable-next-line
          prompt: lastMessage.value,
          message: message || 'There was an error processing this request. Please refresh and try again.',
        },
      },
    };

    // Set the updated chat history
    setChatHistory(updatedChatHistory);
  };

  // Chat search function
  const chatSearchMutation = useMutation({
    mutationFn: ({ searchQuery, pageNumber }: Query_Chat_Search_Props) => _searchChats({ searchQuery, pageNumber }),
    mutationKey: ['filteredChats'],
    onSuccess: ({ chats }: ApiResponse_Chat_Props) => {
      console.log('chatSearchMutation success: ', chats);
      setFilteredChats(chats);
    },
    onError: (e) => {
      logError(e, 'chatSearchMutation');
    },
  });

  // Chat update function
  const updateChatMutation = useMutation({
    mutationFn: (props: ApiBody_Put_Chat_Props) => _updateChat(props),
    mutationKey: ['chat'],
    onError: (e) => {
      logError(e, 'updateChatMutation');
    },
  });

  // Message update function
  const updateMessageMutation = useMutation({
    mutationFn: ({ chatId, messageId, reaction, reasons, additionalFeedback }: Local_Put_Message_Props) => {
      const user = getUser();
      const guestId = getStorageItem(storageItems.guestId) as string;

      const authenticationStatus: Local_Authentication_Status_Props = user ? 'authenticated' : guestId ? 'guest' : 'none';

      return _updateMessage({
        chatId,
        messageId,
        userFeedback: {
          reaction,
          reasons,
          additionalFeedback,
        },
        guestId: authenticationStatus === 'authenticated' ? '' : guestId,
      });
    },
    mutationKey: ['messages'],
    onSuccess: ({ chatId, message }: ApiResponse_Put_Message_Props) => {
      const { upsertMessage } = useChatListStore.getState(); // Use the latest state from Zustand
      upsertMessage({ chatId, message });
    },
    onError: (e) => {
      logError(e, 'updateMessageMutation');
    },
  });

  // Fetch our chat history (loads sidebar chats)
  const getChatsMutation = useMutation({
    mutationFn: ({ pageNumber }: ApiBody_Get_Chats_Props) => _getChats({ pageNumber }),
    mutationKey: ['chats'],
    onSuccess: ({ chats }: ApiResponse_Chat_Props) => {
      console.log('getChatsMutation success: ', chats);
      chats.map((chat) => {
        addToChatMap(chat, []);
      });
    },
    onError: (e) => {
      logError(e, 'getChatsMutation');
    },
  });

  // Fetch our chat history
  const deleteChatMutation = useMutation({
    mutationFn: ({ chatId }: ApiBody_Delete_Chat_Props) => _deleteChat({ chatId }),
    mutationKey: ['chats'],
    onSuccess: (chatIdToRemove: string) => {
      const { deleteItem } = useChatListStore.getState(); // Use the latest state from Zustand
      console.log('deleteChatMutation success: ', chatIdToRemove);
      deleteItem(chatIdToRemove);
    },
    onError: (e) => {
      logError(e, 'deleteChatMutation');
    },
  });

  // Fetch our chat history
  const chatStatusMutation = useMutation({
    mutationFn: _checkQueryStatus,
    mutationKey: ['chatStatus'],
    onError: (e) => {
      logError(e, 'chatStatusMutation');
    },
  });

  const createFeedbackMutation = useMutation({
    mutationFn: _sendFeedback,
    onError: (e) => {
      logError(e, 'createFeedbackMutation');
    },
  });

  // Our chat mutation function
  const toggleMessagePinMutation = useMutation({
    mutationFn: async ({ isPinned, chatId, messageId }: Local_TogglePinMutation_Props) => {
      return isPinned ? await unpinMessage({ chatId, messageId }) : await pinMessage({ chatId, messageId });
    },
    onSuccess: (data, variables) => {
      const { messageList, upsertMessage, upsertChatList } = useChatListStore.getState(); // Use the latest state from Zustand

      const foundMessage = messageList[data._id]?.find((message) => message._id === variables.messageId);

      // Update the message if it exists
      if (foundMessage) {
        upsertMessage({ chatId: data._id, message: { ...foundMessage, isPinned: !variables.isPinned } });
      }

      upsertChatList(data._id, data); // Update sidebar item
    },
    onError: (e) => {
      logError(e, 'toggleMessagePinMutation');
    },
  });

  const getAllMessagesUntilMutation = useMutation({
    mutationFn: _getAllMessagesUntilDate,
    onSuccess: ({ chat, messages, currentPage, pageSize }) => {
      console.log('getAllMessagesUntilMutation success: ', { chat, messages, currentPage, pageSize });
      const { upsertMessage } = useChatListStore.getState(); // Use the latest state from Zustand

      messages.forEach((message) => {
        upsertMessage({ chatId: chat._id, message });
      });

      /* eslint-disable */
      // @ts-ignore
      queryClient.setQueryData<InfiniteData<ApiResponse_Get_Chats_Messages_Props>>(['chat-v2', chat._id], (chatData) => {
        // This updates RQ data so that we don't re-fetch pages we've already fetched
        const newPages = { ...chatData };

        newPages.pageParams = newPages?.pageParams?.slice(0, 1).concat(Array.from({ length: currentPage - 1 }, (_, i) => i + 1));

        for (let i = 0; i < messages.length / pageSize - 1; i++) {
          // @ts-ignore
          newPages.pages[i] = {
            chat: chat,
            messages: messages.slice(i * pageSize, (i + 1) * pageSize),
            // @ts-ignore
            pageParam: i,
          };
        }

        return newPages;
      });
      /* eslint-enable */
    },
    onError: (e) => {
      logError(e, 'getAllMessagesUntilMutation');
    },
  });

  // Updates an item in chat history (can also add an item if a unique chatId is passed in)
  function updateChatHistory({ chatIdToUpdate, chat, formattedMessages, citations, messages, error }: Local_Chat_History_Update_Props) {
    const chatHistory = getChatHistory();

    // To Do: Make it so this only rerenders w/ new data
    // @ts-ignore
    // eslint-disable-next-line
    const newFormattedMessages = formattedMessages ? formattedMessages.sort((a, b) => b?.modifiedTimestamp - a?.modifiedTimestamp) : chatHistory[chatIdToUpdate].formattedMessages;
    const latestMessageId = newFormattedMessages[0]?.originalMessageId;
    let newFollowupQuestions: ApiResponse_Followup_Props = [];

    if (latestMessageId && chatHistory[chatIdToUpdate]) {
      if (chatHistory[chatIdToUpdate].messages[latestMessageId]) {
        // Try to use latest message in chatHistory, the first incoming 'message' could be from previous message history
        newFollowupQuestions = chatHistory[chatIdToUpdate].messages[latestMessageId].aiGeneratedData.followupQuestions as ApiResponse_Followup_Props;
      } else if (messages) {
        // If chat history messages don't exist yet, use the first latest incoming message
        newFollowupQuestions = messages[latestMessageId].aiGeneratedData.followupQuestions as ApiResponse_Followup_Props;
      }
    }

    // Update the chatHistory with the new arrays
    const updatedChatHistory = {
      ...chatHistory,
      [chatIdToUpdate]: {
        ...chatHistory[chatIdToUpdate],
        chat: chat || chatHistory[chatIdToUpdate]?.chat,
        messages: messages || chatHistory[chatIdToUpdate]?.messages,
        formattedMessages: newFormattedMessages,
        followupQuestions: newFollowupQuestions,
        citations: citations || chatHistory[chatIdToUpdate]?.citations,
        error: error || chatHistory[chatIdToUpdate]?.error || { prompt: '', message: '' },
      },
    };

    // Set the updated chat history
    setChatHistory(updatedChatHistory);
  }

  // Sets the chat history
  const setChatHistory = (chatHistory: Local_Chat_History_Props) => {
    const user = getUser();
    const guestId = getStorageItem(storageItems.guestId);

    const authenticationStatus: Local_Authentication_Status_Props = user ? 'authenticated' : guestId ? 'guest' : 'none';

    if (authenticationStatus === 'guest') {
      setStorageItem(storageItems.savedChatHistory, chatHistory);
    }

    queryClient.setQueryData('chatHistory', chatHistory);
  };

  // Adds a chat to the map
  const addToChatMap = (chat: ApiResponse_Chat_Document_Props, messages: ApiResponse_Message_Props[]) => {
    const chatHistory = getChatHistory();
    const newMessageMap: Message_Map_Props = chatHistory[chat._id] ? { ...chatHistory[chat._id].messages } : {};

    let newFormattedMessages: Component_ResponseBlock_Props[] = chatHistory[chat._id] ? [...chatHistory[chat._id].formattedMessages] : [];
    let newCitations = chatHistory[chat._id] ? { ...chatHistory[chat._id].citations } : {};

    messages.forEach((message) => {
      // New message to add to our chat history
      if (!newMessageMap[message._id]) {
        newMessageMap[message._id] = message;
        const context = createMessageContext([message]);
        newFormattedMessages = [...context.messages, ...newFormattedMessages];
        newCitations = { ...newCitations, ...context.citations };
      }
    });

    // console.log('addToChatMap: ', { chat, messages, newMessageMap, newFormattedMessages, newCitations });

    updateChatHistory({ chatIdToUpdate: chat._id, chat, messages: newMessageMap, formattedMessages: newFormattedMessages, citations: newCitations });
  };

  const getChatHistory = (): Local_Chat_History_Props => {
    return queryClient.getQueryData('chatHistory') || initialChatHistory;
  };

  // Returns chat data
  const getFilteredChats = (): ApiResponse_Chat_Document_Props[] => {
    return queryClient.getQueryData('filteredChats') || [];
  };

  // Set chat data
  const setFilteredChats = (filteredChats: ApiResponse_Chat_Document_Props[]) => {
    return queryClient.setQueryData('filteredChats', filteredChats);
  };

  // Returns chat data
  const getChatStream = (): Local_Stream_Map => {
    return queryClient.getQueryData('chatStream') || new Map<string, Component_ResponseBlock_Props>();
  };

  // Returns chat data
  /* eslint-disable */
  const setChatStream = async ({ group_id, data, chatId }: Local_SetStreamChat_Props) => {
    const currentDataMap = getChatStream();

    // console.log(`setChatStream: ${group_id}`, { data, currentDataMap, currentDataMapTwo: Object.fromEntries(currentDataMap) });
    // console.log('setChatStream', data);

    if (data && data.type === 'text') {
      const itemTemplate: Component_ResponseBlock_Props = {
        usertype: 'server',
        value: data.response, // Assuming you want to initialize with the response
        type: 'text',
        _id: `${Date.now().toString()}-client`,
        index: 0,
        originalMessageId: '',
        modifiedTimestamp: convertDateToEpoch(new Date().toISOString()),
        userFeedback: initialUserFeedback, // Assuming it's defined somewhere
        firstResponse: false,
        lastResponse: false,
      };

      if (currentDataMap.has(group_id)) {
        const existingEntry = currentDataMap.get(group_id);
        if (existingEntry) {
          // @ts-ignore
          existingEntry.value += data.response;
          currentDataMap.set(group_id, existingEntry);
        }
      } else {
        currentDataMap.set(group_id, itemTemplate);
      }
    }
    // @ts-ignore
    else if (data && data.type === 'status') {
      // @ts-ignore
      if (currentDataMap.has('status') && data?.group_id === currentDataMap.get('status')?.group_id) {
        const existingEntry = currentDataMap.get('status');
        if (existingEntry) {
          // @ts-ignore
          const newData = (existingEntry.data += data.response);
          // @ts-ignore
          existingEntry.value = {
            data: newData,
            // @ts-ignore
            group_id: data.group_id,
          };
          currentDataMap.set('status', existingEntry);
        }
      } else {
        // @ts-ignore
        currentDataMap.set('status', { data: data.response, group_id: data.group_id });
      }
    } else if (data && data.type === 'graph') {
      const graphData = {
        ...parseGraphs(data),
        _id: `-${getRandomString(10)}-server`,
        index: 0,
        originalMessageId: '',
        modifiedTimestamp: convertDateToEpoch(Date.now().toString(), 0),
        userFeedback: initialUserFeedback,
        usertype: 'server',
        firstResponse: false,
        lastResponse: false,
      };
      // @ts-ignore
      currentDataMap.set(group_id, graphData);
    } else if (data && data.type === 'tradingview') {
      const tradingViewData = {
        config: data.config,
        type: 'tradingview',
        _id: `-${getRandomString(10)}-server`,
        index: 0,
        originalMessageId: '',
        modifiedTimestamp: convertDateToEpoch(Date.now().toString(), 0),
        userFeedback: initialUserFeedback,
        usertype: 'server',
        firstResponse: false,
        lastResponse: false,
      };
      // @ts-ignore
      currentDataMap.set(group_id, tradingViewData);
    }

    queryClient.setQueryData('chatStream', currentDataMap);
    return new Promise((resolve) => resolve(true));
  };
  /* eslint-enable */

  // Sends a chat
  const sendChat = ({ prompt }: ApiBody_Post_Chats_Messages_Props) => {
    const chatHistory = getChatHistory();
    const activeChat = '';

    const messages = chatHistory[activeChat]?.formattedMessages || [];
    const newChat = messages?.length <= 0;

    const outgoingMessage: Component_ResponseBlock_Props = {
      usertype: 'client',
      value: prompt,
      type: 'text',
      _id: `${Date.now().toString()}-client`,
      index: 0,
      originalMessageId: '',
      modifiedTimestamp: convertDateToEpoch(new Date().toISOString()),
      userFeedback: initialUserFeedback,
      firstResponse: false,
      lastResponse: false,
    };

    // Add latest messge to local storage
    setStorageItem(storageItems.lastMessage, outgoingMessage);

    // Sanitize the prompt
    const sanitizedPrompt = DOMPurify.sanitize(prompt || '', { USE_PROFILES: { html: false } });

    if (!prompt) {
      // No prompt was given
      const errorMessage = 'Please enter a message';
      setMessageError(errorMessage);
      logError(new Error(errorMessage), 'sendChat');
      return;
    } else if (prompt.length > MAXIMUM_QUERY_CHARACTER_LIMIT) {
      // Prompt was too long
      const errorMessage = `Your message is too long. Please keep it under ${MAXIMUM_QUERY_CHARACTER_LIMIT} characters.`;
      setMessageError(errorMessage);
      logError(new Error(errorMessage), 'sendChat');
      return;
    } else {
      // Good message
      if (newChat) {
        // New chat (first message)
        chatAPIMutation.mutateAsync({ type: 'new', prompt: sanitizedPrompt });
      } else {
        // Existing Chat
        chatAPIMutation.mutateAsync({ type: 'existing', prompt: sanitizedPrompt, chatId: activeChat });
      }
    }
  };

  const createMessageContext = (incomingMessages: ApiResponse_Message_Props[]): Util_Message_Mutation_Props => {
    const parsedMessages: Component_ResponseBlock_Props[] = [];
    let parsedFollowupQuestions: ApiResponse_Followup_Props = [];
    let parsedCitations: Local_Citations_Props = {};

    incomingMessages.map((incomingMessage) => {
      // Parse the server response
      const { messages, followupQuestions, citations } = parseServerResponse(incomingMessage);
      parsedFollowupQuestions = followupQuestions;
      parsedCitations = citations;

      // Map through the new item(s) and add it to messages
      messages.map((item: Component_ResponseBlock_Props) => {
        if (item) {
          parsedMessages.push({ ...item });
        }
      });

      if (incomingMessage?.prompt) {
        const questionPrompt: Component_ResponseBlock_Props = {
          usertype: 'client',
          value: incomingMessage?.prompt,
          type: 'text',
          _id: incomingMessage?._id.toString().concat('-client'),
          index: 0,
          originalMessageId: incomingMessage?._id.toString(),
          modifiedTimestamp: convertDateToEpoch(incomingMessage?.createdAt),
          userFeedback: incomingMessage.userFeedback,
          firstResponse: false,
          lastResponse: false,
        };
        parsedMessages.push(questionPrompt);
      }
    });

    return { messages: parsedMessages, followupQuestions: parsedFollowupQuestions, citations: parsedCitations };
  };

  /* eslint-disable */
  async function streamChat({ prompt, chatId = '' }: ApiBody_Post_Chats_Messages_Props): Promise<ApiResponse_Post_Chat_Message_Props> {
    if (!chatId) {
      throw new Error('chatId is required');
    }

    const user = getUser();
    const guestId = getStorageItem(storageItems.guestId) as string;

    const authenticationStatus: Local_Authentication_Status_Props = user ? 'authenticated' : guestId ? 'guest' : 'none';

    const uri = authenticationStatus === 'authenticated' ? `chats/${chatId}/messages/stream` : `public/chats/${chatId}/messages/stream`;

    setStorageItem(storageItems.lastActiveChatId, chatId);

    const accessToken = await _getAccessToken({});

    let messageBody: ApiRequest_Stream_Chat_Props = { prompt };
    if (authenticationStatus === 'guest') messageBody['guestId'] = guestId;

    const abortController = new AbortController();
    const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/${uri}`, {
      method: 'POST',
      body: JSON.stringify(messageBody),
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      signal: abortController.signal,
    });

    let finalData: ApiResponse_Post_Chat_Message_Props = initialChatAPIMutation;

    const exampleReader = ndjsonStream(response.body).getReader();

    while (true) {
      const result = await exampleReader.read();

      // TODO: Type cast this to the chunk types
      const data = result.value;

      if (result.done) break;
      else if (data?.is_complete) {
        finalData = data;
        break;
      } else if (data?.error) {
        throw new BackendApiError(data.error);
      } else {
        const responses = data?.responses || [];

        for (const entry of responses) {
          await setChatStream({ group_id: JSON.stringify(entry?.group_id) || '999', data: entry });
        }
      }
    }

    return { chat: finalData.chat, message: finalData.message };
  }
  /* eslint-enable */

  // Reset the chat queries
  const resetChat = () => {
    queryClient.setQueryData('chat', initialChatData); // Save new messages
    queryClient.setQueryData('messages', initialResponseBlocks); // Save new messages
    queryClient.setQueryData('filteredChats', []); // Save new messages
    queryClient.setQueryData('chatHistory', initialChatHistory); // Save new messages
    queryClient.setQueryData('chatStatus', null); // Save new messages
  };

  return {
    chatAPIMutation,
    getChatsMutation,
    deleteChatMutation,
    chatSearchMutation,
    updateChatMutation,
    updateMessageMutation,
    chatStatusMutation,
    createFeedbackMutation,
    toggleMessagePinMutation,
    getAllMessagesUntilMutation,
    updateChatHistory,
    sendChat,
    resetChat,
    getFilteredChats,
    setFilteredChats,
    getChatHistory,
    setChatHistory,
    setChatStream,
    getChatStream,
  };
};
