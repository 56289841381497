import { useQuery } from 'react-query';
import { useEffect, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AdvancedRealTimeChart, ColorTheme, EconomicCalendar, StockHeatmap, SymbolInfo, SymbolOverview } from 'react-ts-tradingview-widgets';
import { defaultTheme } from '../../../utils/constants';
import { useTheme } from '../../../hooks';
import { ApiResponse_Responses_Props, Local_UserFeedback_Props, TradingViewWidgetTypes } from '../../../utils';
import { FeedbackBlock } from '../';
import './index.scss';

interface TradingViewWidgetProps {
  isLoading?: boolean;
  config: {
    widget: TradingViewWidgetTypes;
    // eslint-disable-next-line
    [key: string]: any;
  };
  messageId: string;
  userFeedback: Local_UserFeedback_Props;
  response: ApiResponse_Responses_Props;
  isComplete?: boolean;
  shouldShowFeedback?: boolean;
  isPinned: boolean;
}

export const TradingViewBlock = ({ isLoading, config, messageId, response, userFeedback, isComplete, shouldShowFeedback, isPinned }: TradingViewWidgetProps) => {
  const { getTheme } = useTheme();
  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);
  const colorTheme: ColorTheme = activeTheme === 'dark-theme' ? 'dark' : 'light';

  const widgetRef = useRef(null);

  const removeElem = () => {
    const e = document.getElementsByClassName('tradingview-widget-copyright');

    if (e && e[0]) {
      e[0].remove();
    }
  };

  useEffect(() => {
    removeElem();
  }, []);

  return (
    <div className="response-block-wrapper custom-drag-handle no-select" data-cy="server-response-block-tradingview">
      <div className="drag-handle" />
      <div ref={widgetRef} className={`response-block-common-style server-response response-block-widget-wrapper ${config.widget}`}>
        {isLoading && <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" animationDuration="1.5s" />}

        {config.widget === 'advanced_realtime_chart_widget' && <AdvancedRealTimeChart theme={colorTheme} autosize {...config} />}
        {config.widget === 'stock_heatmap_widget' && <StockHeatmap colorTheme={colorTheme} autoSize {...config} />}
        {config.widget === 'economic_calendar_widget' && <EconomicCalendar colorTheme={colorTheme} autosize {...config} />}
        {config.widget === 'symbol_info_widget' && <SymbolInfo colorTheme={colorTheme} autosize {...config} />}
        {config.widget === 'symbol_overview_widget' && <SymbolOverview colorTheme={colorTheme} dateFormat={"dd MMM 'yy"} height={'500px'} width={'100%'} {...config} />}
      </div>
      {shouldShowFeedback && isComplete && <FeedbackBlock dataRef={widgetRef} messageId={messageId} response={response} userFeedback={userFeedback} isPinned={isPinned} />}
    </div>
  );
};
