import { memo } from 'react';
import { ResponseBlockIcon, GraphBlock, TradingViewBlock, TextResponseBlock } from '../../components';
import { ApiResponse_Message_Props, ApiResponse_Responses_Props, initialUserFeedback } from '../../utils';
import { isEqual } from 'lodash';

interface ChatResponseProps {
  completeMessage?: ApiResponse_Message_Props;
  responses?: ApiResponse_Responses_Props[];
  isScrolling: boolean;
  activeStream?: boolean;
}

const arePropsEqual = (prevProps: ChatResponseProps, nextProps: ChatResponseProps) => {
  return (
    isEqual(prevProps.completeMessage, nextProps.completeMessage) &&
    isEqual(prevProps.responses, nextProps.responses) &&
    isEqual(prevProps.isScrolling, nextProps.isScrolling) &&
    isEqual(prevProps.activeStream, nextProps.activeStream)
  );
};

export const ChatResponse = memo(({ activeStream, completeMessage, responses = [], isScrolling }: ChatResponseProps) => {
  if (completeMessage) {
    responses = completeMessage?.aiGeneratedData?.responses || [];
  }

  const blockData = {
    messageId: completeMessage?._id || `temp-block`,
    userFeedback: completeMessage?.userFeedback || initialUserFeedback,
    isComplete: completeMessage?.aiGeneratedData?.is_complete || false,
    isPinned: completeMessage?.isPinned || false,
  };

  return (
    <div className="response-block-over-wrapper">
      {responses?.length > 0 && <ResponseBlockIcon usertype="server" visible />}

      <div className="response-block-wrapper" style={{ marginBottom: activeStream ? '0px' : 'var(--spacing-md)' }}>
        {responses.map((response, index) => {
          switch (response.type) {
            case 'text':
            case 'document':
              return (
                <TextResponseBlock
                  response={response}
                  messageId={blockData.messageId}
                  message={response.response}
                  key={response.group_id}
                  userFeedback={blockData.userFeedback}
                  isComplete={blockData.isComplete}
                  shouldShowFeedback={index === responses.length - 1}
                  isPinned={blockData.isPinned}
                />
              );

            case 'tradingview':
              return (
                <div className="chat-item-scroll-cover" key={response.group_id}>
                  {isScrolling && <div className="cover-overlay" />}
                  <TradingViewBlock
                    response={response}
                    messageId={blockData.messageId}
                    config={response.config}
                    userFeedback={blockData.userFeedback}
                    isComplete={blockData.isComplete}
                    shouldShowFeedback={index === responses.length - 1}
                    isPinned={blockData.isPinned}
                  />
                </div>
              );

            case 'graph':
              return (
                <div className="chat-item-scroll-cover" key={response.group_id}>
                  {isScrolling && <div className="cover-overlay" />}
                  <GraphBlock
                    data={response}
                    response={response}
                    messageId={blockData.messageId}
                    userFeedback={blockData.userFeedback}
                    isComplete={blockData.isComplete}
                    shouldShowFeedback={index === responses.length - 1}
                    isPinned={blockData.isPinned}
                  />
                </div>
              );

            default:
              return null;
          }
        })}
      </div>
    </div>
  );
}, arePropsEqual);
