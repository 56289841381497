import * as Sentry from '@sentry/react';
import { extraErrorDataIntegration, httpClientIntegration } from '@sentry/integrations';
import { useEffect } from 'react';
import { useNavigationType, useLocation, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import { Local_User_Props, isWebview } from '../utils';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_NODE_ENV,
  integrations: [
    // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#react-router-v6
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin, process.env.REACT_APP_BACKEND_API_URL!],
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    Sentry.replayCanvasIntegration(),
    extraErrorDataIntegration({ depth: 5 }),
    httpClientIntegration(),
  ],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', process.env.REACT_APP_BACKEND_API_URL!],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.2, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production (eg 10%).
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  beforeSend: (event) => {
    // Ignore event if in development
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      return null;
    }

    return event;
  },
});

const userAgent = navigator.userAgent ?? '';
Sentry.setTag('isWebview', isWebview(userAgent));
Sentry.setTag('isNativeApp', window.isNativeApp);

export const setSentryUser = (user: Local_User_Props) => {
  Sentry.setUser({
    email: user.email,
    id: user._id,
  });
};

export const clearSentryUser = () => {
  Sentry.setUser({});
};
