import { useMutation } from 'react-query';
import { ApiResponse_Public_CreateAnonymousSession_Props, logError } from '../utils';
import { _createPublicFeedback, _createPublicSession } from '../api/public';
import { setStorageItem, storageItems } from '../utils/localStorage';

// ------------------ Public API Hook ------------------
export const usePublic = () => {
  const createGuestSessionMutation = useMutation({
    mutationFn: _createPublicSession,
    onSuccess: (data: ApiResponse_Public_CreateAnonymousSession_Props) => {
      setStorageItem(storageItems.guestId, data?.guest?._id || '');
    },
    onError: (e) => {
      logError(e, 'createGuestSessionMutation');
    },
  });

  const createPublicFeedbackMutation = useMutation({
    mutationFn: _createPublicFeedback,
    onError: (e) => {
      logError(e, 'createPublicFeedbackMutation');
    },
  });

  return { createGuestSessionMutation, createPublicFeedbackMutation };
};
