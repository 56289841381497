import GoogleIcon from '../google-icon';
import { Inner_Chip_Props, Local_Send_Chat_Error_Props, isMobile } from '../../utils';
import { useChatApiV2, useSidebar } from '../../hooks';
import { useParams } from 'react-router-dom';
import { useChatListStore } from '../../store';
import { useToast } from '../../providers';
import { TOAST_DURATION } from '../../utils/constants';
import './index.scss';

interface ChipBarLineItemProps {
  data: Inner_Chip_Props[];
}

export const ChipBarData = ({ data }: ChipBarLineItemProps) => {
  const params = useParams<{ id: string }>();
  const toast = useToast();
  const { setSidebar } = useSidebar();

  const { useStreamChatMutation, sendChat } = useChatApiV2();
  const { streamMap } = useChatListStore();

  const isMutatingCurrentChat = streamMap[params?.id as string];
  const isActivelyStreamingCurrentChat = isMutatingCurrentChat && useStreamChatMutation.isLoading;

  const handleClick = (item: Inner_Chip_Props) => {
    if (isActivelyStreamingCurrentChat) return;

    sendChat({ prompt: item.text, chatId: params.id })
      .then(() => {
        if (isMobile()) {
          setSidebar(false);
        }
      })
      .catch((error: Local_Send_Chat_Error_Props) => {
        toast?.current?.show({ severity: 'warn', summary: error.title, detail: error.message, life: TOAST_DURATION });
      });
  };

  return (
    <>
      {data.map((item, index) => {
        return (
          <div
            key={item.text}
            className={`sidebar-chip-item-wrapper ${isActivelyStreamingCurrentChat ? 'disabled-cursor' : ''}`}
            onClick={() => handleClick(item)}
            style={{ animationDelay: `${0.05 * index}s` }}
          >
            <div className="sidebar-chip-item-icon no-select">
              <GoogleIcon name={item.icon} />
            </div>
            <div className="sidebar-chip-text-wrapper">
              <div className="sidebar-chip-item-title smallBold">{item.title}</div>
              <div className="sidebar-chip-item-text smallRegular">{item.text}</div>
            </div>
          </div>
        );
      })}
    </>
  );
};
