import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { InfiniteQueryObserverResult, useQuery } from 'react-query';
import { ApiResponse_Get_Chats_Messages_Props } from '../../utils';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useChatScroll } from '../../providers';
import { debounce, isEqual, throttle } from 'lodash';
import { defaultTheme } from '../../utils/constants';
import { useTheme } from '../../hooks';
import './index.scss';

interface InfiniteLoaderProps<T> {
  data?: T[];
  isFetching: boolean;
  loadNewDataFrom?: 'top' | 'bottom';
  hasNextPage?: boolean;
  pageEndLabel?: string;
  renderHeader?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  keyExtractor: (item: T) => string;
  renderRow: (item: T, index: number) => React.ReactNode;
  fetchNextPage?: () => Promise<InfiniteQueryObserverResult<ApiResponse_Get_Chats_Messages_Props, unknown>>;
  className?: string;
}

// Needs to use 'useInfiniteQuery' RQ hook to work
const InfiniteLoaderInner = <T,>({
  renderHeader,
  renderFooter,
  data,
  loadNewDataFrom = 'bottom',
  isFetching,
  hasNextPage,
  pageEndLabel,
  keyExtractor,
  fetchNextPage,
  renderRow,
  className,
}: InfiniteLoaderProps<T>) => {
  const { setScrollData } = useChatScroll();
  const [showMoreContentArrow, setShowMoreContentArrow] = useState<boolean>(false);
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [isLockedToBottom, setIsLockedToBottom] = useState(true);

  const scrollableDivRef = useRef<HTMLDivElement>(null); // Ref for the scrollable chat history div
  const prevScrollHeightRef = useRef<number>(0); // Ref to store the previous scroll height
  const observerRef = useRef<IntersectionObserver | null>(null); // Ref for the IntersectionObserver
  const lastElementRef = useRef<HTMLDivElement | null>(null); // Ref for the last element in the list
  const isInitialMount = useRef(true); // Ref to check if it's the initial mount

  const { getTheme } = useTheme();
  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  // On mount
  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollableDivRef.current) {
        clearInterval(interval);
        // Scroll to the bottom of the div on initial mount
        scrollableDivRef.current.scrollTo({
          top: scrollableDivRef.current.scrollHeight,
          behavior: 'auto',
        });
        isInitialMount.current = false;
      }
    }, 100);
  }, []);

  // Stop scrolling debounce function
  const handleStopScrolling = useCallback(
    debounce(() => {
      setIsScrolling(false);
      setScrollData({ isScrolling: false });
    }, 300),
    [setScrollData],
  );

  // Throttled check scroll position function
  const checkScrollPosition = useCallback(
    // Need to trottle, otherwise it gets called too frequently and lags the UI during scroll
    throttle(() => {
      if (!scrollableDivRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 25; // Allowing a small margin

      // Logic to show more content arrow
      setShowMoreContentArrow(!isBottom);

      // Update scroll data for other components
      if (!isScrolling) {
        setIsScrolling(true);
        setScrollData({ isScrolling: true });
      }

      if (isBottom) {
        setIsLockedToBottom(true);
      } else {
        setIsLockedToBottom(false);
      }

      // Trigger the debounced function to set isScrolling to false
      handleStopScrolling();
    }, 100),
    [setScrollData, handleStopScrolling, isScrolling],
  );

  // Effect to lock to bottom if isLockedToBottom is true
  useEffect(() => {
    const div = scrollableDivRef.current;
    if (isLockedToBottom && div) {
      div.scrollTop = div.scrollHeight;
    }
  }, [data, isLockedToBottom]);

  // Listen for scroll events
  useEffect(() => {
    const div = scrollableDivRef.current;
    if (div) div.addEventListener('scroll', checkScrollPosition);
    return () => {
      if (div) div.removeEventListener('scroll', checkScrollPosition);
    };
  }, [checkScrollPosition]);

  // Observer for the last or first element to trigger fetchNextPage
  const setIntersectionObserver = useCallback(
    (node: HTMLDivElement) => {
      if (isFetching || !hasNextPage) return;

      if (observerRef.current) observerRef.current.disconnect();

      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !isFetching && isInitialMount.current === false) {
          prevScrollHeightRef.current = scrollableDivRef.current?.scrollHeight || 0;
          fetchNextPage?.().then(() => {
            // Adjust scroll position to maintain the view. Without this, scroll position stays at top and keeps fetching new data
            if (loadNewDataFrom === 'top' && scrollableDivRef.current) {
              const newScrollHeight = scrollableDivRef.current.scrollHeight;
              const scrollHeightDifference = newScrollHeight - prevScrollHeightRef.current;
              scrollableDivRef.current.scrollTo({
                top: scrollHeightDifference,
                behavior: 'auto',
              });
            }
          });
        }
      });

      if (node) observerRef.current.observe(node);
      lastElementRef.current = node;
    },
    [isFetching, hasNextPage, fetchNextPage, loadNewDataFrom],
  );

  // Renders the loader or 'No More Data' text, depending on the scenario
  const renderNextPageLoader = () => {
    // This stops loader from showing on new chat
    const checkForScroll = loadNewDataFrom === 'top' ? isScrolling : true;
    return (
      <>
        {/* No More Data */}
        {isFetching && !hasNextPage && checkForScroll && (
          <div className="infinite-loader-footer">
            <span className="text smallRegular">{pageEndLabel}</span>
          </div>
        )}

        {/* 'is-loading display */}
        {isFetching && checkForScroll && (
          <div className="infinite-loader-footer">
            <ProgressSpinner style={{ width: '30px', height: '30px' }} strokeWidth="3" fill="transparent" animationDuration="1.5s" />
          </div>
        )}
      </>
    );
  };

  const parentNode = scrollableDivRef.current?.parentElement?.className;

  return (
    <div ref={scrollableDivRef} className={`infinite-loader-wrapper ${className}`}>
      {/* Can we make this work, so we don't need to reverse our chat messages? */}
      {/* <div ref={scrollableDivRef} className={`infinite-loader-wrapper ${className}`} style={{ flexDirection: loadNewDataFrom === 'top' ? 'column-reverse' : 'column' }}> */}
      {loadNewDataFrom === 'top' && renderNextPageLoader()}

      {renderHeader?.()}
      {data?.map((item, index) => (
        <div ref={loadNewDataFrom === 'top' ? (index === 0 ? setIntersectionObserver : null) : index === data.length - 1 ? setIntersectionObserver : null} key={keyExtractor(item) || index}>
          {renderRow(item, index)}
        </div>
      ))}
      {renderFooter?.()}

      {parentNode === 'chat-response-wrapper' && showMoreContentArrow && data && data.length > 0 && (
        // To Do: Turn this into a component
        <div
          className="more-content-wrapper clickable"
          onClick={() => scrollableDivRef.current?.scrollTo({ top: scrollableDivRef.current.scrollHeight, behavior: 'smooth' })}
          style={{ backgroundColor: activeTheme === 'light-theme' ? 'rgba(255,255,255,.6)' : 'rgba(0,0,0,.6)' }}
        >
          <i className="pi pi-arrow-down smallRegular" />
        </div>
      )}

      {loadNewDataFrom === 'bottom' && renderNextPageLoader()}
    </div>
  );
};

const arePropsEqual = <T,>(prevProps: InfiniteLoaderProps<T>, nextProps: InfiniteLoaderProps<T>) => {
  return (
    isEqual(prevProps.data, nextProps.data) &&
    isEqual(prevProps.isFetching, nextProps.isFetching) &&
    isEqual(prevProps.hasNextPage, nextProps.hasNextPage) &&
    isEqual(prevProps.keyExtractor, nextProps.keyExtractor) &&
    isEqual(prevProps.renderFooter, nextProps.renderFooter) &&
    isEqual(prevProps.renderHeader, nextProps.renderHeader) &&
    isEqual(prevProps.renderRow, nextProps.renderRow)
  );
};

// InfiniteLoader.whyDidYouRender = true;
export const InfiniteLoader = memo(InfiniteLoaderInner, arePropsEqual) as <T>(props: InfiniteLoaderProps<T>) => JSX.Element;
