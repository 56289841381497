import GoogleIcon from '../google-icon';
import { ApiResponse_Chat_Document_Props, ApiResponse_Pinned_Message_Props, setIntervalWithTimeout } from '../../utils';
import { useChat, useCommon, useTheme } from '../../hooks';
import { useQuery } from 'react-query';
import { TOAST_DURATION, defaultTheme } from '../../utils/constants';
import { memo, useEffect, useRef, useState } from 'react';
import { useToast } from '../../providers';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { useChatListStore } from '../../store';
import { isEqual } from 'lodash';
import './index.scss';
import { en } from '../../utils/language';

interface SidebarHistoryListItemProps {
  item: ApiResponse_Chat_Document_Props;
  index: number;
  selectedId: string;
  onClick: (id: string) => void;
  // eslint-disable-next-line
  onMenuClick: (event: any, id: string) => void;
  onTitleEdited: (newTitle: string | null) => void;
  editingTitle: string | null;
  timeFrame: string;
  showTimeFrame: boolean;
}

const arePropsEqual = (prevProps: SidebarHistoryListItemProps, nextProps: SidebarHistoryListItemProps) => {
  return (
    isEqual(prevProps.item, nextProps.item) &&
    isEqual(prevProps.index, nextProps.index) &&
    isEqual(prevProps.selectedId, nextProps.selectedId) &&
    isEqual(prevProps.editingTitle, nextProps.editingTitle)
  );
};

export const SidebarHistoryListItem = memo(({ item, index, selectedId, onClick, onMenuClick, onTitleEdited, editingTitle, timeFrame, showTimeFrame = false }: SidebarHistoryListItemProps) => {
  const toast = useToast();
  const { id } = useParams();
  const { getTheme } = useTheme();
  const { getUserAuthStatus } = useCommon();
  const inputRef = useRef<HTMLInputElement>(null);
  const { expandedChats, updateExpandedChats, getCurrentChatStatus, streamMap } = useChatListStore();

  const { toggleMessagePinMutation, getAllMessagesUntilMutation } = useChat();

  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  const [newTitle, setNewTitle] = useState(item.title);

  const authStatus = getUserAuthStatus();
  const activeChatStatus = getCurrentChatStatus(item._id);

  // Listen for when user blurs input field
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editingTitle && inputRef.current && !inputRef.current.contains(event.target as Node)) {
        // console.log('event.target: ', { item, a: event.target, b: inputRef.current, c: inputRef.current?.contains(event.target as Node) });
        inputRef.current.blur();
        onTitleEdited(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingTitle]);

  // Scrolls the user to the pinned message that was clicked
  const scrollToPinnedMessage = (pinnedMessage: ApiResponse_Pinned_Message_Props) => {
    const foundPinnedMessage = document.getElementById(`question-header-${pinnedMessage._id}`);

    if (foundPinnedMessage) {
      // Ensures DOM updates are completed before running code that relies on those updates
      requestAnimationFrame(() => {
        foundPinnedMessage?.scrollIntoView({ behavior: 'smooth' });
      });
    } else {
      // Option 1: Get all messages until the pinned message
      getAllMessagesUntilMutation.mutateAsync({ chatId: id as string, untilCreatedAtDate: pinnedMessage.createdAt }).then(() => {
        // Check if the pinned message is now in the DOM, retry every 250ms
        const interval = setIntervalWithTimeout(
          () => {
            const foundPinnedMessage = document.getElementById(`question-header-${pinnedMessage._id}`);
            if (foundPinnedMessage) {
              clearInterval(interval);

              requestAnimationFrame(() => {
                foundPinnedMessage.scrollIntoView({ behavior: 'smooth' });
              });
            }
          },
          250,
          10000,
        );
      });

      // Option 2: Poll for the pinned message
      // Wait for the chat to finish processing
      // while (!foundPinnedMessage) {
      //   console.log('hit while loop');
      //   await fetchNextPage();
      //   foundPinnedMessage = document.getElementById(`question-header-${pinnedMessage._id}`);

      //   if (foundPinnedMessage) {
      //     console.log('hit scroll');
      //     setTimeout(() => {
      //       requestAnimationFrame(() => {
      //         foundPinnedMessage?.scrollIntoView({ behavior: 'smooth' });
      //       });
      //     }, 500);
      //   }
      // }
    }
  };

  // Renders a pinned message item
  const renderPinnedMessages = (pinnedMessage: ApiResponse_Pinned_Message_Props, index: number) => {
    return (
      <div key={index} className="pinned-message-wrapper" onClick={() => scrollToPinnedMessage(pinnedMessage)}>
        <div className="pinned-item-prompt smallRegular">{pinnedMessage.prompt}</div>
        <GoogleIcon
          name={'keep_off'}
          classname="clickable"
          // @ts-ignore
          size={'100%'}
          onClick={(e) => {
            e.stopPropagation();

            toggleMessagePinMutation.mutateAsync({ chatId: id as string, messageId: pinnedMessage._id, isPinned: true }).then((success) => {
              if (success) {
                toast?.current?.show({ severity: 'success', summary: 'Success', detail: en.messages.messageUnpinned, life: TOAST_DURATION });
              } else {
                toast?.current?.show({ severity: 'error', summary: 'Error', detail: en.messages.messageUnpinFailure, life: TOAST_DURATION });
              }
            });
          }}
        />
      </div>
    );
  };

  // Renders the rename chat input field
  const renderRenameTitle = () => (
    <InputText
      ref={inputRef}
      value={newTitle}
      placeholder={item.title}
      onChange={(e) => setNewTitle(e.target.value)}
      className="chat-rename-input smallRegular"
      autoFocus
      type="text"
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          onTitleEdited(newTitle);
        }
        if (e.key === 'Escape') {
          e.preventDefault();
          onTitleEdited(null);
        }
      }}
    />
  );

  // Renders the title of the chat item
  const renderTitle = (isSelectedItem: boolean) => (
    <div className="item-title-wrapper">
      {editingTitle && isSelectedItem ? (
        // Display rename chat input
        renderRenameTitle()
      ) : (
        <>
          {(streamMap[item._id] || activeChatStatus.status === 'processing') && <GoogleIcon name="hourglass_top" size={18} classname="loading-icon" />}
          {/* Drop-down arrow */}
          {item?.pinnedMessages.length > 0 && (
            <GoogleIcon
              name={expandedChats[item._id] ? 'keyboard_arrow_down' : 'chevron_right'}
              classname="accordian-arrow"
              size={24}
              onClick={(e) => {
                e.stopPropagation();
                updateExpandedChats(item._id, !expandedChats[item._id]);
              }}
            />
          )}
          {/* Chat Title */}
          <div className={`item-title ${isSelectedItem ? 'selected' : ''}`}>{item.title}</div>
          {/* Dot menu */}
          {authStatus === 'authenticated' && isSelectedItem && (
            <GoogleIcon
              name={'more_horiz'}
              // @ts-ignore
              // eslint-disable-next-line
              onClick={(event) => {
                // eslint-disable-next-line
                event.stopPropagation();
                onMenuClick(event, item?._id);
              }}
              size={24}
              aria-controls="popup_menu_left"
              aria-haspopup
            />
          )}
        </>
      )}
    </div>
  );

  // Renders a chat list item
  const renderListItem = (item: ApiResponse_Chat_Document_Props) => {
    const isSelectedItem = item?._id === selectedId || item?._id === id;

    return (
      <div key={item?._id} className={`item ${isSelectedItem ? 'selected' : 'clickable'}`} onClick={() => (!editingTitle ? onClick(item?._id) : () => {})}>
        {renderTitle(isSelectedItem)}

        {expandedChats[item._id] && item?.pinnedMessages.length > 0 && (
          <div className="pinned-messages-wrapper">{item?.pinnedMessages?.slice().map((pinnedMessage, index) => renderPinnedMessages(pinnedMessage, index))}</div>
        )}
      </div>
    );
  };

  return (
    <div key={item._id} className="sidebar-history-item-wrapper" style={{ animationDelay: `${0.05 * index}s` }}>
      {showTimeFrame && (
        <div className={`date-text xsmallBold`} style={{ color: activeTheme === 'dark-theme' ? 'var(--text-dark)' : 'var(--text-light)' }}>
          {timeFrame}
        </div>
      )}
      <div>{renderListItem(item)}</div>
    </div>
  );
}, arePropsEqual);
