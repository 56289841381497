import { memo, useEffect, useState } from 'react';
import { useChat, useCommon, usePublic } from '../../hooks';
import { MINIMUM_FEEDBACK_LENGTH, TOAST_DURATION } from '../../utils/constants';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useOverlay, useToast } from '../../providers';
import { isNativeApp } from '../../utils';
import { getStorageItem, storageItems } from '../../utils/localStorage';
import './index.scss';

export const GeneralFeedbackModal = memo(() => {
  const toast = useToast();
  const { getUserAuthStatus } = useCommon();
  const { createFeedbackMutation } = useChat();
  const { createPublicFeedbackMutation } = usePublic();
  const { hideDialog } = useOverlay();

  const authStatus = getUserAuthStatus();

  const [email, setEmail] = useState('');
  const [generalFeedback, setGeneralFeedback] = useState('');
  const [allowSend, setAllowSend] = useState(false);

  useEffect(() => {
    if (generalFeedback && generalFeedback.length >= MINIMUM_FEEDBACK_LENGTH) {
      setAllowSend(true);
    } else {
      setAllowSend(false);
    }
  }, [email, generalFeedback]);

  const sendGeneralFeedback = () => {
    if (authStatus !== 'authenticated') {
      createPublicFeedbackMutation.mutateAsync({ response: generalFeedback, guestId: getStorageItem(storageItems.guestId) as string, email }).then((success) => {
        if (success) {
          toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Feedback received! Thank you for your input.', life: TOAST_DURATION });
          hideDialog();
        } else {
          toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Feedback failed to send. Please try re-submitting, or refresh and try again.', life: TOAST_DURATION });
          console.log('Error sending feedback');
        }
      });
    } else {
      createFeedbackMutation.mutateAsync({ response: generalFeedback }).then((success) => {
        if (success) {
          toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Feedback received! Thank you for your input.', life: TOAST_DURATION });
          hideDialog();
        } else {
          toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Feedback failed to send. Please try re-submitting, or refresh and try again.', life: TOAST_DURATION });
          console.log('Error sending feedback');
        }
      });
    }
  };

  return (
    <div className="common-modal general-feedback-modal-wrapper">
      <div className="inner-content-wrapper">
        <div className="title mediumRegular m-b-md">We appreciate any feedback that our community may have for us. {!isNativeApp() && 'Please keep in mind that Castello is still in beta.'}</div>

        {authStatus !== 'authenticated' && <InputText type="email" placeholder="Email (optional)" value={email} onChange={(e) => setEmail(e.target.value)} className="sign-up-input m-b-md" />}

        <InputTextarea rows={10} cols={30} value={generalFeedback} placeholder="Message Castello..." onChange={(e) => setGeneralFeedback(e.target.value)} className="general-feedback-input m-b-md" />

        <Button className="smallMedium" disabled={!allowSend} onClick={sendGeneralFeedback} loading={createPublicFeedbackMutation.isLoading}>
          Submit Feedback
        </Button>
      </div>
    </div>
  );
});
