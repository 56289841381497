import { http } from '.';
import {
  ApiBody_Public_Feedback_Props,
  ApiRequest_Public_CreateChat_Props,
  ApiResponse_Public_CreateAnonymousSession_Props,
  ApiResponse_Public_CreateChat_Props,
  ApiResponse_Feedback_Props,
} from '../utils';

export const _createPublicSession = async (): Promise<ApiResponse_Public_CreateAnonymousSession_Props> => {
  const uri = `public/anonymousSession`;

  return await http<ApiResponse_Public_CreateAnonymousSession_Props>({ method: 'post', uri });
};

export const _createPublicChat = async ({ prompt, guestId }: ApiRequest_Public_CreateChat_Props): Promise<ApiResponse_Public_CreateChat_Props> => {
  const uri = `public/chats`;

  return await http<ApiResponse_Public_CreateChat_Props>({ method: 'post', uri, data: { prompt, guestId } });
};

export const _createPublicFeedback = async ({ response: feedback, guestId, email }: ApiBody_Public_Feedback_Props): Promise<ApiResponse_Feedback_Props> => {
  const uri = `public/feedback`;
  if (!feedback) throw new Error('Feedback response is required');
  if (!guestId) throw new Error('No authentication found. Please login to submit feedback');

  const messageBody: ApiBody_Public_Feedback_Props = { response: feedback, guestId };

  if (email) messageBody['email'] = email;

  return await http<ApiResponse_Feedback_Props>({ method: 'post', uri, data: messageBody });
};
