import { Button } from 'primereact/button';
import GoogleIcon from '../google-icon';
import './index.scss';

interface SidebarButtonProps {
  customLeftIcon?: React.ReactNode;
  leftIcon?: string;
  leftIconOutline?: boolean;
  text: string;
  rightIcon?: string;
  rightIconOutline?: boolean;
  className?: string;
  onClick: () => void;
  id?: string;
  active?: boolean;
  dataCy?: string;
}

export const SidebarButton = ({ id, active = false, text, leftIcon, rightIcon, className, customLeftIcon, leftIconOutline, rightIconOutline, dataCy, onClick }: SidebarButtonProps) => {
  return (
    <Button id={id} className={`sidebar-button smallRegular ${active ? 'active' : ''} ${className}`} onClick={onClick} data-cy={dataCy}>
      {leftIcon && <GoogleIcon name={leftIcon} outline={leftIconOutline || false} />}
      {customLeftIcon}
      <div className="sidebar-button-text">{text}</div>
      {rightIcon && <GoogleIcon name={rightIcon} outline={rightIconOutline || false} />}
    </Button>
  );
};
