import { ExampleQuestionItemProps, getRandomNumber } from '../utils';
import { investIcon, learnIcon, newsIcon, tradeIcon } from '../utils/constants';
import { companyList } from './companies';

export const exampleQuestions: ExampleQuestionItemProps[] = [
  {
    title: 'What are the risk factors',
    subtitle: "present in Google's last quarterly earning",
    icon: tradeIcon,
  },
  {
    title: 'Can you explain',
    subtitle: 'the Black–Scholes model',
    icon: learnIcon,
  },
  {
    title: 'What is the PE ratio of',
    subtitle: 'JPM and Citi over last 10 years',
    icon: investIcon,
  },
  {
    title: 'What is the latest news',
    subtitle: `on ${companyList[getRandomNumber(0, companyList.length - 1)]}`,
    icon: newsIcon,
  },
];
