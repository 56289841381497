import { memo, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme, useChatApi } from '../../hooks';
import html2canvas from 'html2canvas';
import {
  ApiResponse_Message_Reaction_Props,
  Component_ResponseBlockFeedback_Props,
  Local_CSV_Export_Props,
  logError,
  parseCitation,
  generateCSVData,
  Local_UserFeedback_Props,
  Local_DownvoteFeedback_sate,
} from '../../utils';
import { useOverlay, useToast } from '../../providers';
import { TOAST_DURATION, defaultTheme } from '../../utils/constants';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { CSVLink } from 'react-csv';
import { DownvoteFeedbackModal } from '../../modals';
import GoogleIcon from '../google-icon';
import { useChatListStore } from '../../store';
import './index.scss';

// eslint-disable-next-line
export const ResponseBlockFeedback = memo(({ dataRef, type, visible, messageId, userFeedback, tableData, config }: Component_ResponseBlockFeedback_Props) => {
  const toast = useToast();
  const { getTheme } = useTheme();
  const { getChatHistory, updateMessageMutation } = useChatApi();
  const { activeChatId } = useChatListStore();
  const { showDialog } = useOverlay();

  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);
  const { data: chatHistory = {} } = useQuery('chatHistory', getChatHistory);

  const [csvData, setCSVData] = useState<Local_CSV_Export_Props>();
  const downvoteFeedbackStateRef = useRef<Local_DownvoteFeedback_sate>(null);
  const citationMenu = useRef<Menu>(null);

  const getCitations = chatHistory[activeChatId]?.citations || {};
  const relevantCitations = getCitations[messageId] || [];

  useEffect(() => {
    if (tableData && config) {
      setCSVData(generateCSVData(tableData, config));
    }
  }, [tableData]);

  useEffect(() => {
    const scrollListener = document.getElementsByClassName('infinite-loader-wrapper');
    // eslint-disable-next-line
    const handleScroll = (event: any) => {
      // console.log('hit scroll', { menu: citationMenu.current, top: scrollListener[0].getBoundingClientRect().top, y: scrollListener[0].getBoundingClientRect(). });
      // @ts-ignore
      // eslint-disable-next-line
      citationMenu.current?.hide(event);
    };

    if (scrollListener.length > 0) {
      scrollListener[0].addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // If the downvote feedback modal was closed without submitting feedback
    if (downvoteFeedbackStateRef.current === 'closed') {
      // Submit downvote feedback with no additional feedback or reasons
      submitDownvoteFeedback({ incomingReaction: 'bad', additionalFeedback: userFeedback?.additionalFeedback, reasons: userFeedback?.reasons, newReaction: true });
    }
  }, [downvoteFeedbackStateRef.current]);

  const showDownvoteFeedbackModal = () => {
    downvoteFeedbackStateRef.current = 'open'; // Set state to 'open'
    showDialog({
      header: 'Downvote Feedback',
      content: (
        <DownvoteFeedbackModal
          onSubmit={({ additionalFeedback, reaction, reasons }) => {
            downvoteFeedbackStateRef.current = 'submitted'; // Set state to 'submitted'
            submitDownvoteFeedback({ incomingReaction: reaction, additionalFeedback, reasons, newReaction: true });
          }}
        />
      ),
      onHide: () => {
        // Only set state to 'closed' if feedback was not 'submitted' via the modal
        if (downvoteFeedbackStateRef.current !== 'submitted') {
          downvoteFeedbackStateRef.current = 'closed';
        }
      },
    });
  };

  const items: MenuItem[] = [
    {
      items: relevantCitations.map(
        (key, index): MenuItem => ({
          label: `[${index + 1}] ${parseCitation(key)}`,
          command: () => {
            window.open(key, '_blank')?.focus();
          },
        }),
      ),
    },
  ];

  function handleCopyImage() {
    const handler = async () => {
      // eslint-disable-next-line
      if (dataRef && type === 'text') await copyTextToClipboard(dataRef);
      // eslint-disable-next-line
      else if (dataRef.current && type === 'graph') await createBase64Graph();
      // eslint-disable-next-line
      else if (dataRef.current && type === 'tradingview') await copyWidgetToClipboard();
      else return;
    };

    handler().catch((e) => logError(e, 'handleCopyImage', 'Error copying image to clipboard'));
  }

  async function copyTextToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Text copied to clipboard', life: TOAST_DURATION });
    } catch (e) {
      toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to copy to clipboard', life: TOAST_DURATION });
      logError(e, 'copyTextToClipboard', 'Error copying text to clipboard');
    }
  }

  function createBase64Graph() {
    // Step 1: Capture the Graph Image
    // @ts-ignore
    // eslint-disable-next-line
    const base64Image = dataRef.current.getEchartsInstance().getDataURL({
      pixelRatio: 2,
      backgroundColor: 'var(--background)',
    });

    // Step 2: Create Canvas and Convert to Blob
    const image = new Image();
    // eslint-disable-next-line
    image.src = base64Image;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext('2d');
      // @ts-ignore
      ctx.drawImage(image, 0, 0);

      // Add watermark
      // @ts-ignore
      ctx.font = '20px Arial';
      // @ts-ignore
      ctx.fillStyle = 'rgba(255, 255, 255, 0.5)'; // Semi-transparent white
      // @ts-ignore
      ctx.fillText('Castello.ai', canvas.width - 200, canvas.height - 30);

      // Convert canvas to Blob
      // eslint-disable-next-line
      canvas.toBlob(async (blob) => {
        // Step 3: Copy the blob to the clipboard
        await copyBlobToClipboard(blob);
      });
    };
  }

  // @ts-ignore
  async function copyBlobToClipboard(blob) {
    try {
      // eslint-disable-next-line
      const item = new ClipboardItem({ 'image/png': blob });
      await navigator.clipboard.write([item]);
      toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Image copied to clipboard', life: TOAST_DURATION });
    } catch (e) {
      toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to copy image to clipboard', life: TOAST_DURATION });

      logError(e, 'copyBlobToClipboard', 'Error copying graph image to clipboard');
    }
  }

  async function copyWidgetToClipboard() {
    // @ts-ignore
    // eslint-disable-next-line
    const ref = dataRef?.current?.firstChild?.id || '';

    // eslint-disable-next-line
    const widgetElement = document.getElementById(ref);

    try {
      if (widgetElement) {
        // @ts-ignore
        const canvas = await html2canvas(widgetElement);
        // Convert canvas to Blob
        // eslint-disable-next-line
        canvas.toBlob(async (blob) => {
          // Step 3: Copy the blob to the clipboard
          await copyBlobToClipboard(blob);
        });
      } else {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to copy graph to clipboard', life: TOAST_DURATION });
      }
    } catch (e) {
      toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to copy graph to clipboard', life: TOAST_DURATION });

      logError(e, 'copyBlobToClipboard', 'Failed to copy widget');
    }
  }

  function handleDownvoteFeedback(incomingReaction: ApiResponse_Message_Reaction_Props) {
    let newReaction = false;

    if (userFeedback?.reaction === 'good' && incomingReaction === 'good') incomingReaction = null;
    if (userFeedback?.reaction === 'bad' && incomingReaction === 'bad') incomingReaction = null;

    if (incomingReaction) newReaction = true;

    if (newReaction && incomingReaction === 'bad') {
      showDownvoteFeedbackModal();
    } else {
      submitDownvoteFeedback({ incomingReaction, newReaction, reasons: userFeedback?.reasons, additionalFeedback: userFeedback?.additionalFeedback });
    }
  }

  function submitDownvoteFeedback({
    newReaction,
    incomingReaction,
    reasons = null,
    additionalFeedback = null,
  }: {
    newReaction: boolean;
    incomingReaction: ApiResponse_Message_Reaction_Props;
    reasons: Local_UserFeedback_Props['reasons'];
    additionalFeedback: Local_UserFeedback_Props['additionalFeedback'];
  }) {
    if (reasons === null && additionalFeedback && additionalFeedback?.length > 0) {
      // If there is additional feedback but no reasons, set 'reasons' to empty array (Francesco wants this behavior)
      reasons = [];
    }

    updateMessageMutation
      .mutateAsync({ chatId: activeChatId, messageId, reaction: incomingReaction, reasons, additionalFeedback })
      .then((e) => {
        if (newReaction) {
          toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Thank you for your feedback', life: TOAST_DURATION });
        }
        console.log('updateChatMutation success', e);
      })
      .catch(() => {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to send feedback', life: TOAST_DURATION });
      });
  }

  return (
    <div className="icon-row-wrapper" style={{ display: visible ? 'flex' : 'none' }}>
      <div className="left">
        <>
          {type === 'graph' && tableData && csvData && (
            <CSVLink filename={csvData.filename} data={csvData?.data} headers={csvData?.headers} className="icon-row-item">
              <i className="pi pi-download clickable mediumRegular" />
            </CSVLink>
          )}

          {type !== 'tradingview' && (
            <GoogleIcon
              name={'content_copy'}
              // @ts-ignore
              // eslint-disable-next-line
              onClick={handleCopyImage}
              size={24}
            />
          )}
          <GoogleIcon
            name={'thumb_down'}
            // @ts-ignore
            // eslint-disable-next-line
            onClick={() => handleDownvoteFeedback('bad')}
            size={24}
            data-cy="response-feedback-bad"
          />
          <GoogleIcon
            name={'thumb_up'}
            // @ts-ignore
            // eslint-disable-next-line
            onClick={() => handleDownvoteFeedback('bad')}
            size={24}
            data-cy="response-feedback-good"
          />
          {/* <i
            data-cy="response-feedback-bad"
            className={`pi ${userFeedback?.reaction === 'bad' ? 'pi-thumbs-down-fill' : 'pi-thumbs-down'} icon-row-item clickable mediumRegular`}
            onClick={() => handleDownvoteFeedback('bad')}
          />
          <i
            data-cy="response-feedback-good"
            className={`pi ${userFeedback?.reaction === 'good' ? 'pi-thumbs-up-fill' : 'pi-thumbs-up'} icon-row-item clickable mediumRegular`}
            onClick={() => handleDownvoteFeedback('good')}
          /> */}
        </>
      </div>
      <div className="right">
        <Menu model={items} popup popupAlignment="left" ref={citationMenu} id="citation-popup-menu" />
        {relevantCitations && relevantCitations.length > 0 && (
          <div
            className="citation clickable xsmallMedium no-select"
            // @ts-ignore
            // eslint-disable-next-line
            onClick={(event) => citationMenu.current.toggle(event)}
            style={{ color: activeTheme === 'dark-theme' ? 'var(--background-dark)' : 'var(--text-dark)' }}
          >
            Sources
          </div>
        )}
      </div>
    </div>
  );
});
