import { InputText } from 'primereact/inputtext';
import { useQuery } from 'react-query';
import { useCommon, useTheme } from '../../hooks';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BeautifyError, logError } from '../../utils';
import { MINIMUM_PASSWORD_LENGTH, SLOGAN, TOAST_DURATION, defaultTheme } from '../../utils/constants';
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { useToast } from '../../providers';
import { getStorageItem, removeStorageItem, storageItems } from '../../utils/localStorage';
import './index.scss';

const ResetPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { fullSignOutMutation } = useCommon();

  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [error, setError] = useState<string | boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  // useAuth
  const { getTheme } = useTheme();

  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  useEffect(() => {
    const token = getStorageItem(storageItems.resetPassword) as string;
    if (token) setToken(token);
  }, []);

  // Listen for changes in sign-in fields
  useEffect(() => {
    setError(false);
    setButtonDisabled(!password || !confirmPassword || password.length < MINIMUM_PASSWORD_LENGTH || confirmPassword.length < MINIMUM_PASSWORD_LENGTH);
  }, [password, confirmPassword]);

  const resetPassword = () => {
    setLoading(true);

    if (password !== confirmPassword) {
      setError(true);
      setLoading(false);

      toast?.current?.show({ severity: 'error', summary: 'Error', detail: "Passwords don't match", life: TOAST_DURATION });
      return;
    }

    setTimeout(() => {
      confirmPasswordReset(auth, token, password)
        .then(() => {
          removeStorageItem(storageItems.resetPassword);
          toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Your password has been reset. Please login to continue.', life: TOAST_DURATION });
          removeStorageItem(storageItems.state);

          setLoading(false);
          fullSignOutMutation.mutateAsync().then((signedOut) => {
            if (signedOut) navigate('/');
          });
        })
        .catch((e) => {
          // eslint-disable-next-line
          toast?.current?.show({ severity: 'error', summary: 'Error', detail: BeautifyError(e), life: TOAST_DURATION });
          setLoading(false);
          logError(e, 'confirmPasswordReset');
        });
    }, 500);
  };

  const inputTheme = activeTheme === 'dark-theme' ? 'dark-input' : 'light-input';

  return (
    <div className="reset-password-screen-wrapper">
      <div className="reset-password-content-wrapper">
        <div className="title xxlargeBold">Castello</div>
        <div className="subtitle smallRegular">{SLOGAN}</div>

        <InputText
          type="password"
          placeholder="New Password"
          value={password}
          data-cy="reset-password-form"
          data-sentry-mask="true" // Hide from Sentry replays
          onChange={(e) => setPassword(e.target.value)}
          className={`reset-password-input m-b-md ${error && 'p-invalid'} ${inputTheme}`}
        />
        <InputText
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          data-cy="reset-password-confirmation-form"
          data-sentry-mask="true" // Hide from Sentry replays
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={`reset-password-input m-b-md ${error && 'p-invalid'} ${inputTheme}`}
        />
        <Button className="smallMedium m-b-md" data-cy="confirm-reset-password-button" onClick={resetPassword} disabled={buttonDisabled} loading={loading}>
          Reset Password
        </Button>

        <div className="reset-password-text-wrapper smallRegular">
          Back to
          <span className="reset-password-text mediumMedium clickable no-select" onClick={() => navigate('/chat')}>
            Castello
          </span>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
