import { http } from '.';
import {
  ApiResponse_Dashboard_Document_Props,
  ApiBody_Post_Dashboard_Props,
  ApiBody_Get_Chats_Props,
  ApiResponse_Dashboard_Props,
  Query_GetDashboardById_Props,
  ApiResponse_Get_Dashboard_Props,
  ApiBody_Delete_Dashboard_Props,
  Query_Chat_Search_Props,
  ApiBody_Put_Dashboard_Props,
} from '../utils';

export const _createDashboard = async ({ title }: ApiBody_Post_Dashboard_Props): Promise<ApiResponse_Dashboard_Document_Props> => {
  const uri = `dashboards`;

  return await http<ApiResponse_Dashboard_Document_Props>({ method: 'post', uri, data: { title } });
};

export const _getDashboards = async ({ pageNumber = 0 }: ApiBody_Get_Chats_Props): Promise<ApiResponse_Dashboard_Props> => {
  const uri = `dashboards?pageNumber=${pageNumber}`;

  return await http<ApiResponse_Dashboard_Props>({ method: 'get', uri });
};

export const _getDashboardById = async ({ dashboardId = '' }: Query_GetDashboardById_Props): Promise<ApiResponse_Get_Dashboard_Props> => {
  const uri = `dashboards/${dashboardId}`;

  return await http<ApiResponse_Get_Dashboard_Props>({ method: 'get', uri });
};

export const _deleteDashboard = async ({ dashboardId = '' }: ApiBody_Delete_Dashboard_Props): Promise<string> => {
  const uri = `dashboards/${dashboardId}`;

  await http({ method: 'delete', uri });
  return dashboardId;
};

export const _searchDashboards = async ({ searchQuery = '', pageNumber = 0 }: Query_Chat_Search_Props): Promise<ApiResponse_Dashboard_Props> => {
  const uri = `dashboards/search?searchQuery=${searchQuery}&pageNumber=${pageNumber}`;

  return await http<ApiResponse_Dashboard_Props>({ method: 'get', uri });
};

export const _updateDashboard = async ({ dashboardId, ...props }: ApiBody_Put_Dashboard_Props): Promise<ApiResponse_Dashboard_Document_Props> => {
  const uri = `dashboards/${dashboardId}`;

  return await http<ApiResponse_Dashboard_Document_Props>({ method: 'put', uri, data: { ...props } });
};
