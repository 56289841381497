import { Local_ReleaseNotes_Props } from '../utils';

export const releaseNotes: Local_ReleaseNotes_Props = {
  'Monday, January 29th, 2024': ['Released beta app', 'UI improvements'],
  'Tuesday, February 6th, 2024': ['Improved auth flow', 'Improved dashboard controls', 'UI improvements', 'Various bug fixes'],
  'Monday, February 12th, 2024': ['Added chat response streaming', 'Improved markdown', 'Improved dashboard graphs', 'UI/UX improvements', 'Various bug fixes'],
  'Monday, February 26th, 2024': ['No auth needed to access Castello now!', 'Welcome screen rework', 'Improved UI/UX', 'Various bug fixes'],
  'Sunday, March 3rd, 2024': ['Added collapsible sidebar', 'Added feedback form', 'Fixed issue with color flashing on page-load'],
  'Monday, March 11th, 2024': [
    'Added retry button for failed responses',
    'Fixed error where failed stream results would disappear',
    'Added internet connectivity warnings',
    'Fixed issue that caused an infinite load screen',
    'Significantly improved error reporting to better track and handle issues',
    'Improved auth flow',
  ],
  'Sunday, March 17th, 2024': [
    'Improved mobile experience via styling tweaks',
    'Improved visibility on mobile devices',
    'Locked mobile viewport to prevent zooming and unnecessary scrolling',
    'Added warning pop-up to alert user when they attempt to leave during an active chat stream',
    'Fixed issue with sidebar opening and closing randomly',
    'Moved navigation items to sidebar',
  ],
  'Friday, March 22nd, 2024': [
    'Improved graph readability and responsiveness',
    'Added "percent change" view to graphs',
    'Added CSV download to graphs (found within the table-view of a graph)',
    'Temporarily disabled Dashboards and payments. They will be back soon!',
    'Fixed issue with app height on mobile devices',
    'Styling updates',
  ],
  'Tuesday, April 9th, 2024': [
    'Fixed issue where graphs and widgets would inturrupt scrolling',
    'Fixed auth issue with email verification',
    'Improved error handling and reporting',
    'Fixed Toast (alert) display on mobile devices',
    'Fixed issue with sidebar closing at incorrect times',
    'Improved button hitbox for mobile devices',
    'Fixed issue with TradingView widget showing Apple stock by default',
    'Improved Google auth for mobile',
  ],
  'Thursday, April 18th, 2024': [
    'Added Apple Sign In option',
    'Added (optional) feedback prompt when user downvotes a response',
    'Fixed issue with retry button not working',
    'Fixed issue with sources sometimes not opening',
  ],
  'Friday, June 7th, 2024': [
    'Reworked sidebar to be more useful, adding dynamic questions, a new look, and pinning',
    'Pinning! Gone are the days of endlessly scrolling to find a previous answer. Now, you can pin your chats to easily access them later',
    'Multi-chat support! You can now have multiple chats at once',
    'Chat context! You can now see the context of a chat in the sidebar',
    'Fixed issue with reloading',
    'Various bug fixes',
  ],
  'Friday, June 21st, 2024': [
    'Improved sidebar functionality on mobile devices',
    'Added support for math equations in chat',
    'Improved new chat UI',
    'Added support links in the footer of new chat',
  ],
};
