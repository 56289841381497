import { memo } from 'react';
import { releaseNotes } from '../../mockdata/releaseNotes';
import packageJson from '../../../package.json';
import './index.scss';

export const ReleaseLogsModal = memo(() => {
  return (
    <div className="common-modal release-logs-modal-wrapper">
      <div className="item-wrapper">
        <div className="item-title smallBold">Current App Version:</div>
        <div className="item-value smallRegular">{packageJson?.version || '1.0.0'}</div>
      </div>

      {/* To Do: Hook this up to some sort of CMS */}
      {Object.keys(releaseNotes)
        .reverse()
        .map((key) => (
          <div className="item-wrapper">
            <div className="item-title smallBold">{key}</div>
            <div className="item-value smallRegular">
              <ul>
                {releaseNotes[key].map((note) => (
                  <li>{note}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
    </div>
  );
});
