import { ReactComponent as apple } from './apple.svg';
import { ReactComponent as castello_black } from './castello_black.svg';
import { ReactComponent as castello_white } from './castello_white.svg';
import { ReactComponent as google } from './google.svg';
import { ReactComponent as logo_black } from './logo_black.svg';
import { ReactComponent as logo_white } from './logo_white.svg';
import { ReactComponent as top_crown_black } from './top_crown_black.svg';
import { ReactComponent as top_crown_primary } from './top_crown_primary.svg';
import { ReactComponent as top_crown_white } from './top_crown_white.svg';
const icons = {
  apple,
  castello_black,
  castello_white,
  google,
  logo_black,
  logo_white,
  top_crown_black,
  top_crown_primary,
  top_crown_white,
};
export default icons;
