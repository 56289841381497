export const en = {
  titles: {
    wait: 'Just a moment',
  },
  messages: {
    processingBackgroundStreamRequest: 'Your request is currently being processed',

    messagePinned: 'Message pinned to sidebar',
    messageUnpinned: 'Message unpinned from sidebar',
    messagedPinSuccess: 'Message pinned successfully',
    messagePinFailure: 'Faileld to pin message',
    messageUnpinFailure: 'Faileld to unpin message',
  },
};
