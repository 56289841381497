import { ApiResponse_Followup_Props } from '../../../utils';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useCommon } from '../../../hooks';
import { useQuery } from 'react-query';
import { storageItems } from '../../../utils/localStorage';
import './index.scss';

interface FollowupQuestionBlockProps {
  questions: ApiResponse_Followup_Props;
  onClickFollowUpQuestion: (question: string) => void;
}

export const FollowupQuestionBlock = ({ questions, onClickFollowUpQuestion }: FollowupQuestionBlockProps) => {
  const { getFollowupQuestionsVisible, setFollowupQuestionsVisible } = useCommon();

  const { data: visible = true } = useQuery(storageItems.followupQuestionsVisible.key, getFollowupQuestionsVisible);

  const areFollowupQuestionsOpen = (): number | null => {
    if (visible) {
      return 0;
    } else {
      return null;
    }
  };

  return (
    <div className="followup-question-block-wrapper">
      <Accordion
        activeIndex={areFollowupQuestionsOpen()}
        onTabChange={() => {
          setFollowupQuestionsVisible(!visible);
        }}
      >
        <AccordionTab header="Related Questions" headerClassName="related-question-accordian-header">
          {questions?.map((question) => (
            <div onClick={() => onClickFollowUpQuestion(question)} className={`followup-question-item-wrapper followup-question-animation`} data-cy="follow-up-prompt-suggestion" key={question}>
              <span className="follow-up-prompt-text smallMedium">{question}</span>
            </div>
          ))}
        </AccordionTab>
      </Accordion>
    </div>
  );
};
