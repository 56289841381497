import { defaultInsets } from './constants';
import { StorageItem, StorageKey } from './types';

const storageItems: Record<StorageKey, StorageItem<unknown>> = {
  theme: { key: 'theme', defaultValue: 'light-theme' },
  completedWelcomeScreens: { key: 'completedWelcomeScreens', defaultValue: true }, // Should be 'false', but bypassing for now, requested by Zain
  sidebar: { key: 'sidebar', defaultValue: true },
  state: { key: 'state', defaultValue: '' },
  isNativeApp: { key: 'isNativeApp', defaultValue: false },
  nativeInsets: { key: 'nativeInsets', defaultValue: defaultInsets },
  lastActiveChatId: { key: 'lastActiveChatId', defaultValue: '' },
  savedChatHistory: { key: 'savedChatHistory', defaultValue: {} },
  lastMessage: { key: 'lastMessage', defaultValue: '' },
  lastMessageMap: { key: 'lastMessageMap', defaultValue: {} },
  followupQuestionsVisible: { key: 'followupQuestionsVisible', defaultValue: true },
  resetPassword: { key: 'resetPassword', defaultValue: '' },
  pendingVerification: { key: 'pendingVerification', defaultValue: '' },
  guestId: { key: 'guestId', defaultValue: '' },
};

function setStorageItem<T>(item: StorageItem<T>, value: T): void {
  try {
    const valueToStore = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(item.key, valueToStore);
  } catch (error) {
    console.error(`Error saving ${item.key} to localStorage`, error);
  }
}

function getStorageItem<T>(item: StorageItem<T>): T {
  try {
    const value = localStorage.getItem(item.key);
    if (value) {
      // Attempt to detect if the string is JSON. If the first character is '{' or '[', parse it.
      if (value[0] === '{' || value[0] === '[') {
        return JSON.parse(value) as T;
      } else {
        // If the value doesn't look like JSON, return it directly.
        // This assumes non-JSON values are strings. Adjust as needed for other types.
        return value as unknown as T;
      }
    }
    return item.defaultValue;
  } catch (error) {
    console.error(`Error getting ${item.key} from localStorage`, error);
    return item.defaultValue;
  }
}

function removeStorageItem<T>(item: StorageItem<T>): void {
  localStorage.removeItem(item.key);
}

export { setStorageItem, getStorageItem, removeStorageItem, storageItems };
