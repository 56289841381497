import { memo, useState } from 'react';
import { downvoteResponseReasons } from '../../utils/constants';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useOverlay } from '../../providers';
import { Checkbox } from 'primereact/checkbox';
import { Local_UserFeedback_Props } from '../../utils';
import { useChatApi } from '../../hooks';
import './index.scss';

interface DownvoteFeedbackModalProps {
  onSubmit: ({ additionalFeedback, reaction, reasons }: Local_UserFeedback_Props) => void;
}

export const DownvoteFeedbackModal = memo(({ onSubmit }: DownvoteFeedbackModalProps) => {
  const { hideDialog } = useOverlay();
  const { updateMessageMutation } = useChatApi();

  const [additionalFeedback, setAdditionalFeedback] = useState('');
  const [downvoteList, setDownvoteList] = useState<Record<string, boolean>>({});

  // Handle downvote feedback change
  const onDownvoteFeedbackChange = (e: string) => {
    const newDownvoteList = { ...downvoteList };

    if (newDownvoteList[e]) delete newDownvoteList[e];
    else newDownvoteList[e] = true;

    setDownvoteList(newDownvoteList);
  };

  function submitFeedback() {
    onSubmit({ additionalFeedback: additionalFeedback ? additionalFeedback : null, reaction: 'bad', reasons: Object.keys(downvoteList) });
    hideDialog();
  }

  return (
    <div className="common-modal downvote-feedback-modal-wrapper">
      <div className="inner-content-wrapper">
        <div className="title mediumRegular m-b-md">To help us improve our answers, let us know what went wrong.</div>

        <div className="downvote-checkbox-group">
          {Object.keys(downvoteResponseReasons).map((reason) => (
            <div key={reason} className="downvote-checkbox-wrapper" onClick={() => onDownvoteFeedbackChange(reason)}>
              <Checkbox className="downvote-checkbox" inputId={reason} name={reason} value={reason} checked={downvoteList[reason]} />
              <label htmlFor="ingredient1" className="downvote-checkbox-label no-select">
                {downvoteResponseReasons[reason]}
              </label>
            </div>
          ))}
        </div>

        <InputTextarea
          rows={5}
          cols={30}
          value={additionalFeedback ?? ''}
          placeholder="Any additional feedback?"
          onChange={(e) => setAdditionalFeedback(e.target.value)}
          className="downvote-feedback-input m-b-md"
        />

        <Button className="smallMedium" onClick={submitFeedback} loading={updateMessageMutation.isLoading}>
          Submit Feedback
        </Button>
      </div>
    </div>
  );
});
