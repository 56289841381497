import './index.scss';

interface IconProps {
  name: string;
  size?: number;
  outline?: boolean;
  classname?: string;
  color?: string;
  style?: React.CSSProperties;
  filled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const GoogleIcon = ({ name, size, outline, color, filled = false, classname = '', style, onClick }: IconProps) => {
  if (!size && outline) size = 18;

  return (
    <span
      className={`material-symbols-outlined google-icon no-select ${filled && 'filled-icon'} ${outline && 'google-icon-border'} ${classname}`}
      style={{ fontSize: size || 24, color, ...style }}
      onClick={onClick}
    >
      {name}
    </span>
  );
};

export default GoogleIcon;
