import GoogleIcon from '../google-icon';
import { useEffect, useRef, useState } from 'react';
import { Local_Sidebar_Menu_Item_Props } from '../../utils';
import { theme } from '../../utils/theme';
import { useQuery } from 'react-query';
import { useTheme } from '../../hooks';
import { defaultTheme } from '../../utils/constants';
import './index.scss';

interface SidebarActionMenuProps {
  data: Local_Sidebar_Menu_Item_Props[];
  visible: boolean;
  toggleMenu: () => void;
}

export const SidebarActionMenu = ({ data, visible = false, toggleMenu }: SidebarActionMenuProps) => {
  const { getTheme } = useTheme();

  const menuRef = useRef<HTMLDivElement | null>(null);
  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  const [menuBottom, setMenuBottom] = useState(0);

  const renderMenuItems = () => {
    return data.map(({ icon, label, command }, index) => (
      <div key={index} onClick={command}>
        <div className="sidebar-action-menu-item" data-cy={`sidebar-${icon}-button`}>
          {icon && <GoogleIcon name={icon} />}
          {label}
        </div>
      </div>
    ));
  };

  useEffect(() => {
    const foundUserActionButton = document.getElementById('userActionButton');

    if (foundUserActionButton) {
      const rect = foundUserActionButton.getBoundingClientRect();
      setMenuBottom(window.innerHeight - rect.bottom + rect.height + parseInt(theme[activeTheme].spacing.xs));
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && foundUserActionButton && !foundUserActionButton.contains(event.target as Node)) {
        toggleMenu(); // Close the menu
      }
    };

    if (visible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [visible, toggleMenu]);

  return (
    <div ref={menuRef} className={`sidebar-action-menu-wrapper no-select ${visible ? 'visible' : ''}`} style={{ bottom: `${menuBottom}px` }}>
      {renderMenuItems()}
    </div>
  );
};
