// @ts-ignore
// eslint-disable-next-line
import ndjsonStream from 'can-ndjson-stream';

import { _getAccessToken, http } from '.';
import {
  ApiResponse_Get_Chats_Messages_Props,
  Query_LoadChatMutation_Props,
  ApiBody_Get_Chats_Props,
  ApiBody_Post_Chats_Messages_Props,
  ApiResponse_Chat_Props,
  ApiBody_Delete_Chat_Props,
  Query_Chat_Search_Props,
  ApiBody_Put_Chat_Props,
  ApiResponse_Chat_Document_Props,
  ApiResponse_Post_Chat_Props,
  ApiBody_Put_Message_Props,
  ApiResponse_Put_Message_Props,
  ApiResponse_Message_Props,
  ApiResponse_Chats_Status_Props,
  Local_UserFeedback_Props,
  ApiBody_Authenticated_Feedback_Props,
  ApiResponse_Feedback_Props,
  ApiResponse_Responses_Props,
  ApiRequest_Stream_Chat_Props,
  Local_Authentication_Status_Props,
  Local_User_Props,
  ApiBody_Post_Pin_Message_Props,
  ApiRequest_Get_AllMessagesUntil_Props,
  ApiResponse_Get_AllMessagesUntil_Props,
} from '../utils';
import { Readable } from 'stream';
import { BackendApiError } from '../errors';
import { getStorageItem, storageItems } from '../utils/localStorage';

export const _newChat = async ({ prompt }: ApiBody_Post_Chats_Messages_Props): Promise<ApiResponse_Chat_Document_Props> => {
  const uri = `chats`;

  const data = await http<ApiResponse_Post_Chat_Props>({ method: 'post', uri, data: { prompt } });
  return data.chat;
};

export const _getChats = async ({ pageNumber = 0 }: ApiBody_Get_Chats_Props): Promise<ApiResponse_Chat_Props> => {
  const uri = `chats?pageNumber=${pageNumber}`;
  return await http<ApiResponse_Chat_Props>({ method: 'get', uri });
};

export const _loadChatById = async ({ chatId = '', pageNumber = 0 }: Query_LoadChatMutation_Props): Promise<ApiResponse_Get_Chats_Messages_Props> => {
  const accessToken = await _getAccessToken({});

  if (!chatId) {
    throw new Error('chatId is required');
  }

  // Needed, as 'useChatsQuery' keeps trying this call for guests infinitely
  if (!accessToken) {
    throw new Error('accessToken is required');
  }

  const uri = `chats/${chatId}/messages?pageNumber=${pageNumber || 0}`;
  return await http<ApiResponse_Get_Chats_Messages_Props>({ method: 'get', uri });
};

export const _deleteChat = async ({ chatId = '' }: ApiBody_Delete_Chat_Props): Promise<string> => {
  if (!chatId) {
    throw new Error('chatId is required');
  }

  const uri = `chats/${chatId}`;

  await http({ method: 'delete', uri });
  return chatId;
};

export const _searchChats = async ({ searchQuery = '', pageNumber = 0 }: Query_Chat_Search_Props): Promise<ApiResponse_Chat_Props> => {
  const uri = `chats/search?searchQuery=${searchQuery}&pageNumber=${pageNumber}`;

  return await http<ApiResponse_Chat_Props>({ method: 'get', uri });
};

export const _updateChat = async ({ chatId, ...props }: ApiBody_Put_Chat_Props): Promise<ApiResponse_Chat_Document_Props> => {
  if (!chatId) {
    throw new Error('chatId is required');
  }

  const uri = `chats/${chatId}`;

  return await http<ApiResponse_Chat_Document_Props>({ method: 'put', uri, data: { ...props } });
};

// Downvote feedback
export const _updateMessage = async ({ chatId, messageId, userFeedback, guestId }: ApiBody_Put_Message_Props): Promise<ApiResponse_Put_Message_Props> => {
  if (!chatId) {
    throw new Error('chatId is required');
  }

  let uri = '';

  const outgoingData: { userFeedback: Local_UserFeedback_Props; guestId?: string } = {
    userFeedback,
  };

  if (guestId) {
    // Guest response
    uri = `public/chats/${chatId}/messages/${messageId}`;
    outgoingData['guestId'] = guestId;
  } else {
    // Auth response
    uri = `chats/${chatId}/messages/${messageId}`;
  }

  const data = await http<ApiResponse_Message_Props>({ method: 'put', uri, data: outgoingData });

  return {
    chatId,
    message: data,
  };
};

export const _checkQueryStatus = async (): Promise<ApiResponse_Chats_Status_Props> => {
  const uri = `chats/status`;

  return await http<ApiResponse_Chats_Status_Props>({ method: 'get', uri });
};

// General feedback, NOT downvote feedback
export const _sendFeedback = async ({ response: feedback }: ApiBody_Authenticated_Feedback_Props): Promise<ApiResponse_Feedback_Props> => {
  const uri = `feedback`;

  return await http<ApiResponse_Feedback_Props>({ method: 'post', uri, data: { response: feedback } });
};

/* eslint-disable */
export const _streamChat = async ({
  user,
  chatId,
  prompt,
  onStream,
}: {
  user: Local_User_Props | null;
  chatId?: string;
  prompt: string;
  onStream: (options: { groupId: string; data: ApiResponse_Responses_Props }) => void;
}) => {
  const guestId = getStorageItem(storageItems.guestId) as string;
  const authenticationStatus: Local_Authentication_Status_Props = user ? 'authenticated' : guestId ? 'guest' : 'none';
  const uri = authenticationStatus === 'authenticated' ? `chats/${chatId}/messages/stream` : `public/chats/${chatId}/messages/stream`;

  // setStorageItem(storageItems.lastActiveChatId, chatId);

  const accessToken = await _getAccessToken({});

  let messageBody: ApiRequest_Stream_Chat_Props = { prompt };
  if (authenticationStatus === 'guest') messageBody['guestId'] = guestId;

  let messageHeaders: Record<string, string> = { 'Content-Type': 'application/json' };
  if (authenticationStatus === 'authenticated') messageHeaders['Authorization'] = `Bearer ${accessToken}`;

  const abortController = new AbortController();
  const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/${uri}`, {
    method: 'POST',
    body: JSON.stringify(messageBody),
    headers: messageHeaders,
    signal: abortController.signal,
  });

  const reader: Readable = ndjsonStream(response.body).getReader();

  let finalData = {};

  while (true) {
    const result = await reader.read();

    // eslint-disable-next-line
    const data = result.value;

    if (result.done) {
      break;
    } else if (data?.is_complete) {
      finalData = data;
      break;
    } else if (data?.error) {
      throw new BackendApiError(data.error);
    } else {
      const responses = data?.responses || [];

      for (const entry of responses) {
        onStream({ groupId: JSON.stringify(entry?.group_id) || '999', data: entry });
      }
    }
  }

  return finalData;
};

export const pinMessage = async ({ chatId = '', messageId = '' }: ApiBody_Post_Pin_Message_Props): Promise<ApiResponse_Chat_Document_Props> => {
  if (!messageId || !chatId) throw new Error('Required fields are missing');

  const uri = `chats/${chatId}/pinnedMessages`;

  return await http({ method: 'post', uri, data: { messageId } });
};

export const unpinMessage = async ({ chatId = '', messageId = '' }: ApiBody_Post_Pin_Message_Props): Promise<ApiResponse_Chat_Document_Props> => {
  if (!messageId || !chatId) throw new Error('Required fields are missing');

  const uri = `chats/${chatId}/pinnedMessages/${messageId}`;

  return await http({ method: 'delete', uri });
};

export const _getAllMessagesUntilDate = async ({ chatId, untilCreatedAtDate }: ApiRequest_Get_AllMessagesUntil_Props): Promise<ApiResponse_Get_AllMessagesUntil_Props> => {
  const uri = `chats/${chatId}/allMessages?untilCreatedAtDate=${untilCreatedAtDate}`;
  return await http({ method: 'get', uri });
};
