import { UseInfiniteQueryResult, UseMutationResult, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { _getChats, _loadChatById, _newChat, _streamChat } from '../api';
import {
  ApiBody_Post_Chats_Messages_Props,
  ApiRequest_Stream_Chat_Props_V2,
  ApiResponse_Chat_Document_Props,
  ApiResponse_Chat_Props,
  ApiResponse_Get_Chats_Messages_Props,
  ApiResponse_Message_Props,
  ApiResponse_Post_Chat_Message_Props,
  Local_Authentication_Status_Props,
  addItemToLastMessageMap,
  removeItemFromLastMessageMap,
  initialAIGeneratedData,
  initialUserFeedback,
  logError,
} from '../utils';
import { createContext, useContext } from 'react';
import DOMPurify from 'dompurify';
import { uniqueId } from 'lodash';
import { useAuth } from './useAuth';
import { getStorageItem, setStorageItem, storageItems } from '../utils/localStorage';
import { _createPublicChat } from '../api/public';
import { useChatListStore } from '../store';
import { en } from '../utils/language';

// We can't pass {} directly to createContext, so we need to create a type for it. But this seems to pass w/o TS errors, and we don't need to define each mutation, which is nice.
interface UseChatApiV2Props {
  // eslint-disable-next-line
  useStreamChatMutation: UseMutationResult<any, unknown, ApiRequest_Stream_Chat_Props_V2, unknown>;
  useCreateChatMutation: UseMutationResult<ApiResponse_Chat_Document_Props, unknown, ApiBody_Post_Chats_Messages_Props, unknown>;
  useChatsQuery: () => UseInfiniteQueryResult<ApiResponse_Chat_Props, unknown>;
  useChatByIdQuery: (id: string) => UseInfiniteQueryResult<ApiResponse_Get_Chats_Messages_Props, unknown>;
  sendChat: (props: ApiBody_Post_Chats_Messages_Props) => Promise<boolean>;
  setStreamStatus: (status: string) => void;
  getStreamStatus: () => string;
  setChatError: (error: string) => void;
  getChatError: () => string;
}

// ------------------ Context ------------------
export const ChatApiContextV2 = createContext<UseChatApiV2Props | null>(null);

// ------------------ Provider ------------------
export const useChatApiV2 = (): UseChatApiV2Props => {
  const context = useContext(ChatApiContextV2);
  if (!context) {
    throw new Error('useChatApiV2 must be used within ChatApiProviderV2');
  }
  return context;
};

export const useChatV2 = () => {
  const { getUser } = useAuth();
  const queryClient = useQueryClient();

  const useStreamChatMutation = useMutation({
    // @ts-ignore
    onMutate: (variables) => {
      const { upsertMessage, addStreamMapItem } = useChatListStore.getState(); // Use the latest state from Zustand

      console.log('useStreamChatMutation onMutate', variables);
      if (variables.isRetry) {
        return;
      }

      const newMessage: ApiResponse_Message_Props = {
        _id: variables.generatedId,
        prompt: variables.prompt,
        chatId: variables.chatId as string,
        createdAt: new Date().toISOString(),
        aiGeneratedData: initialAIGeneratedData,
        tickers: [],
        isPinned: false,
        userFeedback: initialUserFeedback,
      };

      upsertMessage({ chatId: variables.chatId as string, message: newMessage, addToStart: true });
      addStreamMapItem(variables.chatId as string);

      // Scroll to bottom of chat when user asks a new question
      const elem = document.getElementsByClassName('infinite-loader-wrapper chat-message-padding');
      if (elem[0] && elem[0]?.scrollHeight) {
        const scrollHeight = elem[0].scrollHeight;
        elem[0].scrollTo({ top: scrollHeight, behavior: 'smooth' });
      }
    },
    mutationFn: ({ prompt, chatId, onStream }: ApiRequest_Stream_Chat_Props_V2) => {
      return _streamChat({
        user: getUser(),
        chatId,
        prompt,
        onStream: (stream) => {
          const { updateStreamMapItem } = useChatListStore.getState(); // Use the latest state from Zustand

          onStream?.(stream);
          // @ts-ignore
          if (stream.data.type === 'status') {
            return;
          }

          updateStreamMapItem({ chatId: chatId as string, data: stream.data });
        },
      });
    },
    onSuccess: ({ chat, message }: ApiResponse_Post_Chat_Message_Props, variables) => {
      const { upsertMessage, upsertChatList, removeStreamMapItem } = useChatListStore.getState(); // Use the latest state from Zustand

      upsertChatList(chat._id, chat); // Update the chat list
      removeStreamMapItem(variables.chatId as string); // Remove the chat from the stream map
      upsertMessage({ chatId: variables.chatId as string, message: { ...message, _id: message._id }, generatedId: variables.generatedId }); // Update the chat message with the final result
      removeItemFromLastMessageMap(variables.chatId as string); // Remove the message from the last message map
    },
    onError: (error, variables) => {
      const { addChatErrorMapItem, removeStreamMapItem, removeMessage } = useChatListStore.getState(); // Use the latest state from Zustand
      addChatErrorMapItem({
        chatId: variables.chatId as string,
        generatedId: variables.generatedId,
        prompt: variables.prompt,
        error: (error as { message: string })?.message || 'Generic Network Error',
      }); // Save the message that errored
      removeMessage(variables.chatId as string, variables.generatedId); // Remove the errored message from the chat
      removeStreamMapItem(variables.chatId as string); // Remove the chat from the stream map
      removeItemFromLastMessageMap(variables.chatId as string); // Remove the message from the last message map
    },
  });

  const useChatByIdQuery = (id?: string) => {
    const user = getUser();
    const guestId = getStorageItem(storageItems.guestId) as string;
    const authenticationStatus: Local_Authentication_Status_Props = user ? 'authenticated' : guestId ? 'guest' : 'none';

    /**
     * RQ may not be needed in the future. We're making use of useInfiniteQuery for pagination, but beyond that and API load setates, Zustand is handling the rest.
     */

    // This returns the 'pages' array of messages
    return useInfiniteQuery({
      staleTime: Infinity,
      queryKey: ['chat-v2', id],
      retry: 0,
      queryFn: async ({ pageParam = 0 }: { pageParam?: number }) => {
        const { upsertMessage } = useChatListStore.getState(); // Use the latest state from Zustand

        const data = await _loadChatById({
          chatId: id,
          pageNumber: pageParam,
        });

        data.messages.forEach((message) => {
          upsertMessage({ chatId: id as string, message });
        });

        return {
          ...data,
          pageParam,
        };
      },

      getNextPageParam: (lastPage) => (lastPage?.messages?.length === 0 ? undefined : lastPage.pageParam + 1),
      enabled: authenticationStatus === 'authenticated' || !!id,
    });
  };

  // Chat pagination
  const useChatsQuery = () => {
    const user = getUser();
    const guestId = getStorageItem(storageItems.guestId) as string;
    const authenticationStatus: Local_Authentication_Status_Props = user ? 'authenticated' : guestId ? 'guest' : 'none';

    /**
     * RQ may not be needed in the future. We're making use of useInfiniteQuery for pagination, but beyond that and API load setates, Zustand is handling the rest.
     */

    // This returns the 'pages' array of chats
    return useInfiniteQuery({
      staleTime: Infinity,
      retry: 1,
      queryKey: ['chats-v2'],
      queryFn: async ({ pageParam = 0 }: { pageParam?: number }) => {
        const { chatList, upsertChatList, setChatList } = useChatListStore.getState(); // Use the latest state from Zustand

        const data = await _getChats({
          pageNumber: pageParam,
        });

        if (Object.keys(chatList).length <= 0) {
          // First time loading
          setChatList(data.chats);
        } else {
          // Update the chat list
          data.chats.forEach((chat) => {
            upsertChatList(chat._id, chat);
          });
        }

        return {
          ...data,
          pageParam,
        };
      },
      getNextPageParam: (lastPage) => (lastPage?.chats?.length === 0 ? undefined : lastPage.pageParam + 1),
      enabled: authenticationStatus === 'authenticated',
    });
  };

  const useCreateChatMutation = useMutation({
    mutationFn: _newChat,
  });

  const sendChat = async ({ prompt, chatId: incomingChatId }: ApiBody_Post_Chats_Messages_Props): Promise<boolean> => {
    // eslint-disable-next-line
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const { streamMap, upsertChatList, activeChatId, setActiveChatId, getCurrentChatStatus } = useChatListStore.getState(); // Use the latest state from Zustand

        let chatId = (incomingChatId as string) || activeChatId;
        const activeChatStatus = getCurrentChatStatus(chatId);

        // @ts-ignore
        const chatIsAlreadyProcessing = streamMap[chatId] || activeChatStatus.status === 'processing';

        // If we know the current chat is already processing, don't even let it get to the API
        if (chatIsAlreadyProcessing) {
          return reject({ title: en.titles.wait, message: en.messages.processingBackgroundStreamRequest });
        }

        // Sanitize the prompt
        const sanitizedPrompt = DOMPurify.sanitize(prompt || '', { USE_PROFILES: { html: false } });

        if (!chatId) {
          const user = getUser();
          const guestId = getStorageItem(storageItems.guestId) as string;
          const authenticationStatus: Local_Authentication_Status_Props = user ? 'authenticated' : guestId ? 'guest' : 'none';

          // New chat (first message)
          let newChat = {} as ApiResponse_Chat_Document_Props;

          // Create new chat based on auth type
          if (authenticationStatus === 'authenticated') {
            newChat = await _newChat({ prompt, chatId: '' });
          } else if (authenticationStatus === 'guest') {
            newChat = (await _createPublicChat({ prompt, guestId }))?.chat;
          }

          chatId = newChat._id;
          upsertChatList(newChat._id, newChat); // Add new chat to the chat list
        }

        setActiveChatId(chatId);
        setStorageItem(storageItems.lastActiveChatId, chatId); // TODO: I think we can remove all instances of this
        addItemToLastMessageMap(chatId, sanitizedPrompt); // Save the message to the last message map

        let statusId = '';
        useStreamChatMutation.mutateAsync(
          {
            generatedId: uniqueId(),
            chatId: chatId,
            prompt: sanitizedPrompt,
            isRetry: false,
            onStream: (stream) => {
              // @ts-ignore
              if (stream?.data.type === 'status') {
                if (statusId === stream.groupId) {
                  // @ts-ignore
                  // eslint-disable-next-line
                  setStreamStatus((streamStatus) => (streamStatus + stream?.data.response) as string);
                } else {
                  // @ts-ignore
                  // eslint-disable-next-line
                  setStreamStatus(stream?.data?.response as string);
                }
              }
              statusId = stream.groupId;
            },
          },
          {
            onSettled: () => {
              setStreamStatus('');
            },
          },
        );

        resolve(true);
      } catch (e) {
        logError(e, 'sendChat');
        reject({ title: 'Error', message: 'An error occurred when trying to send your message' });
      }
    });
  };

  const setStreamStatus = (status: string) => {
    queryClient.setQueryData('streamStatus', status);
  };

  const getStreamStatus = (): string => {
    return queryClient.getQueryData('streamStatus') || '';
  };

  const setChatError = (error: string) => {
    queryClient.setQueryData('chatError', error);
  };

  const getChatError = (): string => {
    return queryClient.getQueryData('chatError') || '';
  };

  return {
    useStreamChatMutation,
    useCreateChatMutation,
    useChatsQuery,
    useChatByIdQuery,
    sendChat,
    setStreamStatus,
    getStreamStatus,
    setChatError,
    getChatError,
  };
};
