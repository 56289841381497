import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useAuth, useCommon } from '../../hooks';
import { useState } from 'react';
import { useToast } from '../../providers';
import { TOAST_DURATION } from '../../utils/constants';
import { isMobile } from '../../utils';
import './index.scss';

const OnboardingScreen = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { acceptTOSAPIMutation } = useAuth();
  const { fullSignOutMutation } = useCommon();

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const userAgreed = () => {
    setButtonDisabled(true);
    acceptTOSAPIMutation
      .mutateAsync()
      .then(() => {
        toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Welcome to Castello', life: TOAST_DURATION });
        navigate('/chat');
      })
      .catch(() => {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'There was an error when accepting. Please refresh and try again.', life: TOAST_DURATION });
      })
      .finally(() => {
        setButtonDisabled(false);
      });
  };

  return (
    <div className="onboarding-screen-wrapper">
      <div className="onboarding-content-wrapper">
        <div className="title xxlargeBold m-b-md">Before you begin</div>

        <div className="smallRegular m-b-md">
          By clicking{' '}
          <b>
            <i>Agree</i>
          </b>
          , you agree to our{' '}
          <span className="link clickable" onClick={() => window.open('https://castello.ai/legal', '_blank')}>
            Terms
          </span>{' '}
          and have read our{' '}
          <span className="link clickable" onClick={() => window.open('https://castello.ai/legal', '_blank')}>
            Privacy Policy
          </span>
          . <br />
          <br />
          Additionally, you acknowledge that{' '}
          <b>
            {/* TODO: Change to window.isNativeApp */}
            <i>Castello.ai is still {isMobile() ? 'being developed' : 'in beta'}</i>
          </b>
          , and that answers that you receive may not be accurate.
        </div>

        <Button className="smallMedium m-t-md" data-cy="agree-policy-button" onClick={userAgreed} loading={acceptTOSAPIMutation.isLoading} disabled={buttonDisabled}>
          Agree
        </Button>
        <Button
          severity="secondary"
          className="smallMedium m-t-md"
          data-cy="disagree-policy-button"
          onClick={() => {
            fullSignOutMutation.mutateAsync().then((signedOut) => {
              if (signedOut) navigate('/');
            });
          }}
          loading={fullSignOutMutation.isLoading}
        >
          Logout
        </Button>
      </div>
    </div>
  );
};

export default OnboardingScreen;
