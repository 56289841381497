import { useQuery } from 'react-query';
import { useSidebar } from '../../hooks';
import { Component_ChatLayout_Props } from '../../utils';
import Navbar from '../navbar';
import Sidebar from '../sidebar';
import SidebarController from '../sidebar-controller';
import Loader from '../loader';
import './index.scss';

export const ChatLayout = ({ type, loading = false, children }: Component_ChatLayout_Props) => {
  const { setSidebar, getSidebar } = useSidebar();
  const { data: sidebarVisible } = useQuery('sidebar', getSidebar);

  const toggleSidebar = () => {
    setSidebar(!sidebarVisible);
  };

  return (
    <div className="chat-screen-wrapper">
      <Sidebar type={type} />

      <div className={`chat-section-wrapper ${sidebarVisible ? 'shrink-view' : 'expanded-view'}`}>
        <SidebarController isSidebarVisible={sidebarVisible} onClick={toggleSidebar} />
        {loading ? (
          <Loader />
        ) : (
          <>
            <Navbar />
            {children}
          </>
        )}
      </div>
    </div>
  );
};
