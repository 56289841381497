import { memo, useEffect, useState } from 'react';
import { useAuth, useChatApiV2, useSidebar } from '../../hooks';
import { InputText } from 'primereact/inputtext';
import { MINIMUM_PASSWORD_LENGTH, TOAST_DURATION } from '../../utils/constants';
import { useToast, useOverlay } from '../../providers';
import { AUTH_TYPES, ApiResponse_User_Props, BeautifyError, isMobile, isValidEmail, logError } from '../../utils';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { _sendVerificationEmail } from '../../api';
import { getStorageItem, removeStorageItem, storageItems } from '../../utils/localStorage';
import RenderSVG from '../../components/renderSVG';
import { useChatListStore } from '../../store';
import './index.scss';

export const AuthModal = memo(({ navigate, type }: { navigate: (e: string) => void; type: 'signup' | 'signin' }) => {
  const toast = useToast();
  const { useChatsQuery } = useChatApiV2();
  const { setSidebar } = useSidebar();
  const { signUpAPIMutation, signInAPIMutation } = useAuth();
  const { hideDialog } = useOverlay();
  const { setChatList } = useChatListStore();
  const { setActiveChatId } = useChatListStore();

  const { fetchPreviousPage } = useChatsQuery();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [view, setView] = useState<'signup' | 'signin' | 'password-reset'>(type || 'signup');
  const [pendingVerificationCode, setPendingVerificationCode] = useState('');
  const [allowResend, setAllowResend] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const state = getStorageItem(storageItems.state);
    const pendingVerification = getStorageItem(storageItems.pendingVerification) as string;

    if (pendingVerification) {
      setPendingVerificationCode(pendingVerification);
      removeStorageItem(storageItems.pendingVerification);
    }

    // This will trigger if clicks the email verification link after they've logged out
    if (state && state === 'verified' && toast) {
      toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Email verified. Please login to continue.', life: TOAST_DURATION });
      removeStorageItem(storageItems.state);
    }
  }, []);

  // Listen for changes in form fields or view
  useEffect(() => {
    validateForm();
  }, [view, firstName, lastName, email, password]);

  const validateForm = () => {
    if (view === 'signup') {
      setButtonDisabled(!firstName || !lastName || !email || !password || Boolean(password.length < MINIMUM_PASSWORD_LENGTH) || !isValidEmail(email));
    } else if (view === 'signin') {
      setButtonDisabled(!email || !password || Boolean(password.length < 3) || !isValidEmail(email));
    } else if (view === 'password-reset') {
      setButtonDisabled(!email || !isValidEmail(email));
    } else setButtonDisabled(true);
  };

  const requestNewPassword = () => {
    setLoading(true);
    setAllowResend(false);

    sendPasswordResetEmail(auth, email)
      .then(() => {
        if (isMobile()) {
          setSidebar(false);
        }

        hideDialog();
        toast?.current?.show({ severity: 'success', summary: 'Success', detail: "We've sent an password reset link to your email.", life: TOAST_DURATION });
      })
      .catch((e) => {
        // eslint-disable-next-line
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: BeautifyError(e), life: TOAST_DURATION });
        logError(e, 'sendPasswordResetEmail');
      })
      .finally(() => {
        setAllowResend(true);
        setLoading(false);
      });
  };

  const signUp = () => {
    signUpAPIMutation
      .mutateAsync({ firstName, lastName, email, password })
      .then((user) => {
        if (isMobile()) {
          setSidebar(false);
        }
        hideDialog();
        handleAuth(user);
      })
      .catch((e) => {
        // eslint-disable-next-line
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: BeautifyError(e), life: TOAST_DURATION });
      });
  };

  // eslint-disable-next-line
  const signIn = (type: AUTH_TYPES) => {
    signInAPIMutation
      .mutateAsync({ type, email, password })
      .then((user) => {
        if (isMobile()) {
          setSidebar(false);
        }
        hideDialog();
        handleAuth(user);
      })
      .catch((e) => {
        // eslint-disable-next-line
        if (e?.code !== 'auth/cancelled-popup-request' && e?.code !== 'auth/popup-closed-by-user') {
          // eslint-disable-next-line
          toast?.current?.show({ severity: 'error', summary: 'Error', detail: BeautifyError(e), life: TOAST_DURATION });
        }
      });
  };

  const successfulSignIn = () => {
    toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Sign-in successful!', life: TOAST_DURATION });

    /**
     * Ideally, backend should create a new chat for the user on signup.
     */

    setChatList([]); // Clear chat list (if any)
    setActiveChatId(''); // Clear active chat
    fetchPreviousPage(); // Should essentially fetch page 0
    navigate('/chat');
  };

  // Logic on how auth should be handled, depending on the user's state
  function handleAuth(user: ApiResponse_User_Props | null) {
    if (!user) {
      toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Something went wrong, please login again', life: TOAST_DURATION });
    } else if (pendingVerificationCode) {
      // User tried to verify their email, but changed devices or browser and didn't have auth
      navigate(`/verify?mode=verifyEmail&oobCode=${pendingVerificationCode}`);
    } else if (!user?.emailVerified) {
      // User has not verified their email

      _sendVerificationEmail()
        .then(() => {
          toast?.current?.show({ severity: 'info', summary: 'Verification Requested', detail: "We've sent a verification link to your email.", life: TOAST_DURATION });
        })
        .catch((e) => {
          // eslint-disable-next-line
          toast?.current?.show({ severity: 'error', summary: 'Error', detail: BeautifyError(e), life: TOAST_DURATION });
        });

      navigate('/email-verification');
    } else if (!user?.tosAccepted || !user?.betaUseAccepted) {
      // User has not accepted TOS
      navigate('/onboarding');
    } else {
      // User has signed in successfully
      successfulSignIn();
    }
  }

  return (
    <div className="common-modal auth-modal-wrapper">
      <div className="inner-content-wrapper">
        <div className="title xlargeMedium">{view === 'signup' ? 'Sign up with Castello' : view === 'signin' ? 'Welcome back to Castello' : 'Reset your password'} </div>

        {(view === 'signup' || view === 'signin') && (
          <>
            {view === 'signup' && (
              <>
                <InputText type="text" data-cy={'sign-up-first-name-form'} placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="sign-up-input m-b-md" />
                <InputText type="text" data-cy="sign-up-last-name-form" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="sign-up-input m-b-md" />
              </>
            )}

            <InputText
              type="email"
              data-cy={view === 'signup' ? 'sign-up-email-form' : 'sign-in-email-form'}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="sign-up-input m-b-md"
            />
            <InputText
              type="password"
              data-cy={view === 'signup' ? 'sign-up-password-form' : 'sign-in-password-form'}
              data-sentry-mask="true" // Hide from Sentry replays
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="sign-up-input m-b-md"
            />

            {view === 'signup' ? (
              <Button className="smallMedium" data-cy="sign-up-button" onClick={signUp} loading={signUpAPIMutation.isLoading} disabled={buttonDisabled}>
                Sign Up
              </Button>
            ) : (
              <Button className="smallMedium" data-cy="sign-in-button" onClick={() => signIn('local')} loading={signInAPIMutation.isLoading} disabled={buttonDisabled}>
                Start Chatting
              </Button>
            )}

            <Divider />

            <>
              <Button className={`sso-button smallMedium m-b-md`} onClick={() => signIn('google')}>
                <RenderSVG name={'google'} size={18} classname={'svg-style'} />
                Continue with Google
              </Button>
              <Button className={`sso-button smallMedium`} onClick={() => signIn('apple')}>
                <RenderSVG name={'apple'} size={18} classname={'svg-style'} />
                Continue with Apple
              </Button>
              <Divider />
            </>

            <div className="sign-up-text-wrapper smallRegular">
              {view === 'signup' ? 'Already have an account?' : view === 'signin' ? "Don't have an account?" : ''}
              <span className="sign-up-text mediumMedium clickable no-select" onClick={() => setView(view === 'signup' ? 'signin' : 'signup')}>
                {view === 'signup' ? 'Sign In' : view === 'signin' ? 'Sign Up' : ''}
              </span>
            </div>
          </>
        )}

        {view === 'password-reset' && (
          <>
            <InputText type="email" data-cy={'reset-password-form'} placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="sign-up-input m-b-md" />
            <Button className="smallMedium m-b-md" onClick={requestNewPassword} disabled={!allowResend && buttonDisabled} loading={loading}>
              Reset Password
            </Button>
          </>
        )}

        {view !== 'signup' && (
          <div className="forgot-password-text smallRegular clickable no-select m-t-md" onClick={() => setView(view === 'password-reset' ? 'signup' : 'password-reset')}>
            {view === 'password-reset' ? 'Back to Sign Up' : 'Forgot Password?'}
          </div>
        )}
      </div>
    </div>
  );
});
