import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import Lottie, { Options } from 'react-lottie';
import { NotFound } from '../../assets/lotties';
import './index.scss';

const notFoundAnimationOptions: Options = {
  loop: true,
  autoplay: true,
  animationData: NotFound,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const NotFoundScreen = () => {
  const navigate = useNavigate();

  return (
    <div id="not-found-screen">
      <div className="not-found-response-wrapper">
        <div className="headingMedium m-b-sm">Page Not Found</div>
        <div className="smallRegular">No page matching that URL was found</div>
        <div className="lottie-wrapper m-b-md">
          <Lottie options={notFoundAnimationOptions} height={'100%'} width={'100%'} isStopped={false} isPaused={false} />
        </div>
        <Button className="button" onClick={() => navigate('/')} data-cy="not-found-button">
          Back to Castello
        </Button>
      </div>
    </div>
  );
};

export default NotFoundScreen;
