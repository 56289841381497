import { GeneralFeedbackModal } from '../../modals/general-feedback';
import { useOverlay } from '../../providers';
import './index.scss';

interface ChatFooterProps {
  bottomMessage?: string;
}

interface ChatFooterItem {
  text: string;
  onClick: () => void;
}

export const ChatFooter = ({ bottomMessage }: ChatFooterProps) => {
  const { showDialog } = useOverlay();

  const footerItems: ChatFooterItem[] = [
    {
      text: 'Discord',
      onClick: () => window.open('https://discord.gg/JB3e3pc6zD', '_blank'),
    },
    {
      text: 'Privacy',
      onClick: () => window.open('https://castello.ai/legal', '_blank'),
    },
    {
      text: 'Company',
      onClick: () => window.open('https://www.transcrypts.com/castello-ai-analysis-model', '_blank'),
    },
    {
      text: 'Blog',
      onClick: () => window.open('https://www.transcrypts.com/company#news', '_blank'),
    },
    {
      text: 'Feedback',
      onClick: () =>
        showDialog({
          header: 'Feedback',
          content: <GeneralFeedbackModal />,
          onHide: () => {},
          closeOnEscape: false,
        }),
    },
  ];

  const renderItem = ({ text, onClick }: ChatFooterItem) => {
    return (
      <div key={text} className="chat-footer-item clickable" onClick={onClick}>
        {text}
      </div>
    );
  };

  return (
    <div className="chat-footer-wrapper">
      {bottomMessage && <div className="chat-footer-bottom-text smallRegular">{bottomMessage}</div>}

      <div className="chat-footer-items-wrapper smallRegular">{footerItems.map((item) => renderItem(item))}</div>
    </div>
  );
};
