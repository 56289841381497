import { useEffect, useRef, useState } from 'react';
import { ResponseBlockGraph, ResponseBlockWidget } from '../../components';
import { useQuery } from 'react-query';
import { useDashboardApi, useGraph, useSidebar, useTheme } from '../../hooks';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CoreProps, Responsive, WidthProvider } from 'react-grid-layout';
import { Button } from 'primereact/button';
import { ApiBody_Post_Layout_Object_Props, LayoutItem, LayoutSizes, LayoutState, TradingViewWidgetTypes } from '../../utils';
import { useOverlay, useToast } from '../../providers';
import { GraphSearchModal } from '../../modals';
import _ from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import { TOAST_DURATION, dashboardBreakpoints, dashboardColumns, dashboardGraphSizeRestrictions, dashboardTradingViewGraphSizeRestrictions, defaultTheme } from '../../utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import Navbar from '../../components/navbar';
import Sidebar from '../../components/sidebar';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const Dashboard = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { getTheme } = useTheme();
  const { setSidebar, getSidebar } = useSidebar();

  const { id: navigationUrl } = useParams();

  // Hooks
  const { getActiveDashboard, getDashboardHistory, getDashboardsByIdMutation, updateDashboardMutation } = useDashboardApi();
  const { getGraphHistory } = useGraph();

  const { data: activeDashboard = '' } = useQuery('activeDashboard', getActiveDashboard);
  const { data: dashboardHistory = {} } = useQuery('dashboardHistory', getDashboardHistory);
  const { data: graphHistory = {} } = useQuery('graphHistory', getGraphHistory);
  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);
  const { data: sidebarVisible } = useQuery('sidebar', getSidebar);

  const { showDialog } = useOverlay();

  // State
  const [currentBreakpoint, setCurrentBreakpoint] = useState<LayoutSizes>('lg');
  const [compactType, setCompactType] = useState<CoreProps['compactType']>('vertical');
  const [layouts, setLayouts] = useState<LayoutState>({ lg: [], md: [], sm: [], xs: [] });
  const [activeLayout, setActiveLayout] = useState<LayoutItem[]>([]);
  // const prevDashboardHistoryRef = useRef<Local_Dashboard_History_Object_Props>();
  const [ranInitialGraphQuery, setRanInitialGraphQuery] = useState(false);
  const prevIsLoadingRef = useRef(false);

  const graphs: string[] = dashboardHistory[activeDashboard]?.graphs || [];
  const showAdvanedDetails = false; // Whether or not to show the advanced details

  // When dashboard history changes
  // useEffect(() => {
  //   if (prevDashboardHistoryRef.current && !_.isEqual(prevDashboardHistoryRef.current, dashboardHistory[activeDashboard])) {
  //     checkForLayoutChanges(getLayoutFromDashboard());
  //   }

  //   // Updating the ref to the current prop for the next render
  //   prevDashboardHistoryRef.current = dashboardHistory[activeDashboard];

  //   // You can also handle otherProps changes here if needed
  // }, [dashboardHistory[activeDashboard]]);

  // When graph data changes
  useEffect(() => {
    // console.log('hit new data: ', { graphs, layouts, dashboard: dashboardHistory[activeDashboard], layout: dashboardHistory[activeDashboard]?.dashboard?.layout });

    if (!ranInitialGraphQuery && dashboardHistory[activeDashboard]?.dashboard?.layout?.length > 0) {
      initLayout();
    }
  }, [activeDashboard, graphs]);

  // Listen for changes to chat id
  useEffect(() => {
    if (activeDashboard) {
      navigate(`/dashboard/${activeDashboard}`);
      initLayout();
    }
  }, [activeDashboard]);

  // Initialize the layout
  const initLayout = () => {
    const actualLayout = getLayoutFromDashboard();
    setActiveLayout(actualLayout);
    setLayouts({ lg: actualLayout, md: actualLayout, sm: actualLayout, xs: actualLayout });
  };

  // Listen for 'getDashboardsByIdMutation' state change to toggle from loading to loaded
  useEffect(() => {
    // eslint-disable-next-line
    const currentIsLoading = getDashboardsByIdMutation.isLoading;
    const prevIsLoading = prevIsLoadingRef.current;

    // Check if isLoading was true in the previous render and is now false
    if ((prevIsLoading && !currentIsLoading) || !navigationUrl) {
      setRanInitialGraphQuery(true);
    }

    // Update the ref with the current isLoading value for the next render
    // eslint-disable-next-line
    prevIsLoadingRef.current = currentIsLoading;
  }, [getDashboardsByIdMutation.isLoading]);

  function getLayoutFromDashboard() {
    return formatLayout(dashboardHistory[activeDashboard]?.dashboard?.layout);
  }

  function formatLayout(layout: LayoutItem[] | ApiBody_Post_Layout_Object_Props[] = []) {
    const formattedLayout: LayoutItem[] = [];

    layout.forEach((layout) => {
      // @ts-ignore
      // eslint-disable-next-line
      const id: string = layout.graphId || layout.i || '';

      const isTradingViewWidget: boolean = graphHistory[id]?.graph?.data?.type === 'tradingview';

      const formattedLayoutItem: LayoutItem = {
        x: layout.x,
        y: layout.y,
        w: layout.w,
        h: layout.h,
        i: id,
        graphId: id,
        isBounded: true,
      };

      // If the widget is a tradingview widget, we need to set the min/max height/width
      if (isTradingViewWidget) {
        // @ts-ignore
        // eslint-disable-next-line
        const widgetType: TradingViewWidgetTypes = graphHistory[id]?.graph?.data?.config?.widget;

        if (dashboardTradingViewGraphSizeRestrictions[widgetType]?.minW) {
          formattedLayoutItem['minW'] = dashboardTradingViewGraphSizeRestrictions[widgetType]?.minW;
        }
        if (dashboardTradingViewGraphSizeRestrictions[widgetType]?.minH) {
          formattedLayoutItem['minH'] = dashboardTradingViewGraphSizeRestrictions[widgetType]?.minH;
        }
        if (dashboardTradingViewGraphSizeRestrictions[widgetType]?.maxW) {
          formattedLayoutItem['maxW'] = dashboardTradingViewGraphSizeRestrictions[widgetType]?.maxW;
        }
        if (dashboardTradingViewGraphSizeRestrictions[widgetType]?.maxH) {
          formattedLayoutItem['maxH'] = dashboardTradingViewGraphSizeRestrictions[widgetType]?.maxH;
        }
      } else {
        formattedLayoutItem['minW'] = dashboardGraphSizeRestrictions.minW;
        formattedLayoutItem['minH'] = dashboardGraphSizeRestrictions.minH;
      }
      formattedLayout.push(formattedLayoutItem);
    });
    return formattedLayout;
  }

  const onBreakpointChange = (breakpoint: LayoutSizes) => {
    setCurrentBreakpoint(breakpoint);
  };

  const onCompactTypeChange = () => {
    const newCompactType = compactType === 'horizontal' ? 'vertical' : compactType === 'vertical' ? null : 'horizontal';
    setCompactType(newCompactType);
  };

  const onLayoutChange = (layout: LayoutItem[], layouts: LayoutState) => {
    if (layout.length <= 0 || layouts[currentBreakpoint].length <= 0) return;

    // console.log('hit onLayoutChange');
    checkForLayoutChanges(layout);
  };

  function checkForLayoutChanges(incomingLayout: LayoutItem[]) {
    const strippedLayout = formatLayout(incomingLayout);

    const isEqualLayout = _.isEqual(activeLayout, strippedLayout);
    // console.log('hit onLayoutChange: ', { isEqualLayout, strippedLayout, activeLayout, incomingLayout, layouts, initialMountState });

    if (!isEqualLayout) {
      setActiveLayout(strippedLayout);
      setLayouts({ lg: strippedLayout, md: strippedLayout, sm: strippedLayout, xs: strippedLayout });
    }
  }

  const saveGraphs = () => {
    const title = dashboardHistory[activeDashboard]?.dashboard?.title;

    const layoutToSave = activeLayout.map(({ x, y, w, h, i }: LayoutItem) => {
      return { x, y, w, h, graphId: i };
    });

    updateDashboardMutation
      .mutateAsync({ dashboardId: activeDashboard, layout: layoutToSave, title, isFavorite: false })
      .then(() => {
        toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Dashboard changes saved', life: TOAST_DURATION });
      })
      .catch(() => {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'There was an error when trying to save your dashboard', life: TOAST_DURATION });
      });
  };

  const stringifyLayout = () => {
    return activeLayout?.map((l: LayoutItem) => {
      return (
        <div className="layoutItem" key={l.graphId}>
          <b>{l?.graphId?.substring(l?.graphId?.length - 4, l?.graphId?.length)}</b>: [{l.x}, {l.y}, {l.w}, {l.h}]
        </div>
      );
    });
  };

  const showGraphSearch = () => {
    showDialog({
      header: () => {
        return <div className="graph-search-modal-header">Select a Chart</div>;
      },
      // footer: graphSearchFooter,
      id: 'graph-search-modal',
      content: <GraphSearchModal />,
      onHide: () => {},
    });
  };

  const displayAdvancedDetails = () => {
    return (
      <>
        <div className="layoutJSON">
          Displayed as <code>[x, y, w, h]</code>:<div className="columns">{stringifyLayout()}</div>
        </div>
        <div>
          {/* @ts-ignore */}
          Current Breakpoint: {currentBreakpoint}. Number of cols: {dashboardColumns[currentBreakpoint]}
        </div>
        <div>Compaction type: {_.capitalize(compactType as string) || 'No Compaction'}</div>
        <div>
          <Button severity="secondary" onClick={onCompactTypeChange}>
            Change Compaction Type
          </Button>
        </div>
      </>
    );
  };

  const dashboardWithContent = dashboardHistory && Boolean(dashboardHistory[activeDashboard || '']?.dashboard?.layout?.length > 0);
  const showNewDashboardPrompt = !navigationUrl || (!dashboardHistory[activeDashboard] && !dashboardWithContent && !getDashboardsByIdMutation.isLoading);

  // console.log('dashboard: ', { dashboardHistory: dashboardHistory[activeDashboard], activeLayout, layouts, ranInitialGraphQuery });
  return (
    <div className="dashboard-screen-wrapper">
      <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
      <Sidebar type="dashboard" />
      <div className={`dashboard-section-wrapper ${sidebarVisible ? 'shrink-view' : 'expanded-view'}`}>
        {/* To Do: Make this a component */}
        <div className="sidebar-controller" onClick={() => setSidebar(!sidebarVisible)} data-cy="sidebar-controller">
          <div className={`sidebar-controller-top ${sidebarVisible ? '' : 'controller-top-collapsed'}`} />
          <div className={`sidebar-controller-bottom ${sidebarVisible ? '' : 'controller-bottom-collapsed'}`} />
        </div>
        <Navbar />
        {!showNewDashboardPrompt && (
          <div className="dashboard-navbar">
            <Button className="dashboard-button m-r-md smallRegular" icon="pi pi-plus" onClick={showGraphSearch}>
              <span className="dashboard-button-text">Add</span>
            </Button>
            <Button className="dashboard-button smallRegular" icon="pi pi-save" onClick={saveGraphs}>
              <span className="dashboard-button-text">Save</span>
            </Button>
          </div>
        )}

        {showAdvanedDetails && displayAdvancedDetails()}
        {!ranInitialGraphQuery || (!dashboardWithContent && getDashboardsByIdMutation.isLoading) ? (
          <div className="dashboard-loading-wrapper">
            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" animationDuration="1.5s" />
          </div>
        ) : (
          <>
            {showNewDashboardPrompt ? (
              <div className="new-dashboard-response-wrapper">
                <div className="example-question-container">
                  <div className="example-question-header">
                    <div className="xxlargeBold m-b-xs">Castello</div>
                    <div className="largeRegular">Visualize your data</div>
                    <Button className="new-dashboard-add-graph-button smallMedium m-t-md" icon="pi pi-plus" onClick={showGraphSearch}>
                      Add Your First Graph
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="grid-dashboard-response-wrapper">
                {ranInitialGraphQuery && (
                  <ResponsiveReactGridLayout
                    layouts={layouts}
                    onBreakpointChange={onBreakpointChange}
                    onLayoutChange={onLayoutChange}
                    useCSSTransforms={false}
                    compactType={compactType}
                    preventCollision={!compactType}
                    rowHeight={10}
                    breakpoints={dashboardBreakpoints}
                    cols={dashboardColumns}
                    draggableHandle={'.drag-handle'}
                  >
                    {activeLayout.map(({ graphId }, index) => {
                      console.log('hit activeLayout.map: ', { graphId, index, graphHistory, graph: graphHistory[graphId] });

                      if (graphHistory[graphId]) {
                        return (
                          <div key={graphId}>
                            {graphHistory[graphId].graph.data.type === 'graph' && (
                              <ResponseBlockGraph key={graphId} {...graphHistory[graphId].formattedGraphData} firstResponse={false} index={index} className="responsive-chart-height" />
                            )}

                            {graphHistory[graphId].graph.data.type === 'tradingview' && (
                              <div className="tradingview-dashboard-wrapper">
                                {/* @ts-ignore */}
                                <ResponseBlockWidget key={graphId} {...graphHistory[graphId].graph.data} theme={activeTheme} firstResponse={false} index={index} />
                              </div>
                            )}
                          </div>
                        );
                      } else return null;
                    })}
                  </ResponsiveReactGridLayout>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
