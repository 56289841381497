export * from './auth';
export * from './chat';
export * from './dashboard';
export * from './graph';

import axios from 'axios';
import { ApiBody_Request_Props, exponentialRetry, isAxiosApiError, logError } from '../utils';
import { _getAccessToken } from './auth';
import { BackendApiError } from '../errors';

// Generic HTTP call to our backend server
export const http = async <T>(props: ApiBody_Request_Props): Promise<T> => {
  try {
    let accessToken = await _getAccessToken({});

    // eslint-disable-next-line
    const { method = 'get', uri = '', data = null, headers = null, params = null } = props;

    const url = `${process.env.REACT_APP_BACKEND_API_URL}/${uri}`.trim();
    const query = { method, url, data: {}, headers: {}, params: {} };

    // Set the data
    // eslint-disable-next-line
    if (data && (method === 'post' || method === 'put' || method === 'delete' || method === 'patch')) query.data = data;

    // Set the params
    // eslint-disable-next-line
    if (params) query.params = params;

    return await exponentialRetry(
      async () => {
        // Only this portion will be re-run on retry. Any request modifications need to be modified here.
        const { data } = await axios<T>({
          ...query,
          // eslint-disable-next-line
          headers: headers || {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });
        return data;
      },
      {
        // @ts-ignore
        // eslint-disable-next-line
        shouldRetry: (e) => e?.response?.data?.error?.code === 'unverifiedEmail',
        beforeRetry: async () => {
          accessToken = await _getAccessToken({ forceRefresh: true });
        },
        maxRetries: 1,
      },
    );
  } catch (e) {
    let formattedError = e;
    if (isAxiosApiError(e)) {
      formattedError = new BackendApiError(e.response?.data?.error);
    }

    logError(formattedError, 'http');
    throw formattedError; // Re-throw the error to be caught by the caller
  }
};
