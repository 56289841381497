import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { formatNumber, isMobile } from '../../utils';
import { MAXIMUM_QUERY_CHARACTER_LIMIT, TOAST_DURATION } from '../../utils/constants';
import { useToast } from '../../providers';
import { en } from '../../utils/language';
import './index.scss';

interface ChatInputProps {
  value: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
  onSubmit: () => void;
}

export const ChatInput = ({ value, isDisabled, onChange, onSubmit }: ChatInputProps) => {
  const { id: navigationUrl } = useParams();
  const toast = useToast();

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const canSubmit = !isDisabled && value.trim().length > 0 && value.trim().length <= MAXIMUM_QUERY_CHARACTER_LIMIT;

  // Auto-focus on the input when the component mounts
  useEffect(() => {
    // Don't autofocus on mobile, it's annoying to get the keyboard pop-up
    if (isMobile()) {
      return;
    }

    inputRef.current?.focus();
  }, [navigationUrl]);

  const submit = () => {
    if (!canSubmit) {
      if (isDisabled) {
        toast?.current?.show({ severity: 'warn', summary: en.titles.wait, detail: en.messages.processingBackgroundStreamRequest, life: TOAST_DURATION });
      }
      return;
    }

    onSubmit();

    // Reset the input height after submitting
    if (inputRef.current) {
      inputRef.current.style.height = ''; // Removes the inline height style
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      submit();
    }
  };

  const onChangeInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className="input-section-wrapper">
      <div className="input-wrapper">
        <InputTextarea ref={inputRef} autoResize rows={1} value={value} placeholder="Message Castello..." onChange={onChangeInput} onKeyDown={onKeyDown} className="chat-input smallRegular" />
        <span className={`chat-input-button pi pi-send ${!canSubmit ? 'disabled' : 'enabled'}`} onClick={submit} />
      </div>
      <div className="input-bottom-text-wrapper">
        {value.length > MAXIMUM_QUERY_CHARACTER_LIMIT ? (
          // Show an error message if the user exceeds the character limit
          <div className="input-bottom-text error-text smallRegular">Your message is too long. You are {formatNumber(value.length - MAXIMUM_QUERY_CHARACTER_LIMIT)} characters over the limit.</div>
        ) : null}
      </div>
    </div>
  );
};
