import { defaultTheme } from './constants';
import {
  ApiResponse_Chat_Document_Props,
  ApiResponse_Followup_Props,
  ApiResponse_Message_Props,
  Component_ResponseBlock_Props,
  ApiResponse_One_Props,
  Local_Chat_History_Props,
  ApiResponse_Post_Chat_Message_Props,
  Local_UserFeedback_Props,
  Local_Dashboard_History_Props,
  Local_Graph_History_Props,
  Component_ResponseBlockGraph_Props,
  ApiResponse_Chats_Status_Inner_Props,
} from './types';

export const initialTheme = defaultTheme;
export const initialFollowupQuestions: ApiResponse_Followup_Props = [];

export const initialChatData: ApiResponse_Chat_Document_Props = {
  createdAt: '',
  isFavorite: false,
  sentLastMessageAt: '',
  pinnedMessages: [],
  tickers: [],
  title: '',
  userId: '',
  _id: '',
};

export const initialAIGeneratedData: ApiResponse_One_Props = {
  responses: [],
  is_complete: false,
};

export const initialMessageData: ApiResponse_Message_Props = {
  chatId: '',
  createdAt: '',
  prompt: '',
  _id: '',
  isPinned: false,
  tickers: [],
  aiGeneratedData: initialAIGeneratedData,
  userFeedback: {
    reaction: null,
    reasons: null,
    additionalFeedback: null,
  },
};

export const initialResponseBlocks: Component_ResponseBlock_Props[] = [];

export const initialChatAPIMutation: ApiResponse_Post_Chat_Message_Props = {
  chat: initialChatData,
  message: initialMessageData,
};

export const initialChatHistory: Local_Chat_History_Props = {};
export const initialDashboardHistory: Local_Dashboard_History_Props = {};
export const initialGraphHistory: Local_Graph_History_Props = {};

export const initialUserFeedback: Local_UserFeedback_Props = {
  reaction: null,
  reasons: [],
  additionalFeedback: null,
};

export const initialFormattedGraphData: Component_ResponseBlockGraph_Props = {
  type: 'finmetric',
  data: {},
  config: {
    title: '',
    currency: '',
    frequency: '',
    period: 5,
    graph_type: 'finmetric',
    plot: 'bar',
    y_axis_title: '',
    symbols: {},
    series: [],
  },
  xvars: [],
  _id: '',
  usertype: 'server',
  index: 0,
  originalMessageId: '',
  modifiedTimestamp: 0,
  userFeedback: {
    reaction: null,
    reasons: null,
    additionalFeedback: null,
  },
  firstResponse: false,
  lastResponse: false,
};

export const initialChatStatus: ApiResponse_Chats_Status_Inner_Props = {
  status: null,
  queuePosition: null,
};
