import { memo, useEffect, useRef } from 'react';
import { Component_ResponseBlockWidget_Props } from '../../utils';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AdvancedRealTimeChart, ColorTheme, EconomicCalendar, StockHeatmap, SymbolInfo, SymbolOverview } from 'react-ts-tradingview-widgets';
import { ResponseBlockFeedback } from '../response-block-feedback';
import { ResponseBlockIcon } from '../response-block-icon';
import './index.scss';

export const ResponseBlockWidget = memo(
  ({ config, theme = 'light-theme', loading = false, originalMessageId, userFeedback, firstResponse, lastResponse, className, index }: Component_ResponseBlockWidget_Props) => {
    // console.log('ResponseBlockWidget: ', { config, loading });
    const widgetRef = useRef(null); // Creating a ref

    const colorTheme: ColorTheme = theme === 'dark-theme' ? 'dark' : 'light';

    const removeElem = () => {
      const e = document.getElementsByClassName('tradingview-widget-copyright');

      if (e && e[0]) {
        e[0].remove();
      }
    };

    useEffect(() => {
      removeElem();
    }, []);

    // console.log('ResponseBlockWidget: ', { config, theme, loading, index, originalMessageId, userFeedback, firstResponse, className });

    const blockId = `response-block-server-${config.widget}-${originalMessageId}-${index}`;

    return (
      <div id={blockId} className={`response-block-wrapper custom-drag-handle no-select ${lastResponse ? 'response-block-last-response' : ''} ${className}`}>
        <div className="drag-handle" />
        <div ref={widgetRef} className={`response-block-common-style server-response response-block-widget-wrapper ${config.widget}`}>
          {firstResponse && <ResponseBlockIcon usertype={'server'} visible={firstResponse} />}
          {loading && <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" animationDuration="1.5s" />}
          {/* @ts-ignore */}
          {config.widget === 'advanced_realtime_chart_widget' && <AdvancedRealTimeChart theme={colorTheme} autosize {...config} />}
          {config.widget === 'stock_heatmap_widget' && <StockHeatmap colorTheme={colorTheme} autoSize {...config} />}
          {config.widget === 'economic_calendar_widget' && <EconomicCalendar colorTheme={colorTheme} autosize {...config} />}
          {config.widget === 'symbol_info_widget' && <SymbolInfo colorTheme={colorTheme} autosize {...config} />}
          {config.widget === 'symbol_overview_widget' && <SymbolOverview colorTheme={colorTheme} dateFormat={"dd MMM 'yy"} height={'500px'} width={'100%'} {...config} />}
        </div>
        <ResponseBlockFeedback dataRef={widgetRef} messageId={originalMessageId} type={'tradingview'} visible={lastResponse} userFeedback={userFeedback} />
      </div>
    );
  },
);
