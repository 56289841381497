import { ProgressSpinner } from 'primereact/progressspinner';
import './index.scss';

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" animationDuration="1.5s" />
    </div>
  );
};

export default LoadingScreen;
