import { Chip } from 'primereact/chip';
import { Sidebar_View_Props } from '../../utils';
import { getExampeChips } from '../../mockdata';
import './index.scss';

interface ChipBarProps {
  selected: Sidebar_View_Props;
  onSelect: (view: Sidebar_View_Props) => void;
}

export const ChipBar = ({ selected, onSelect }: ChipBarProps) => {
  const dataKeys = Object.keys(getExampeChips([]));
  return (
    <div className="chip-bar-wrapper">
      {dataKeys.map((key) => (
        <Chip key={key} label={key} className={`no-select ${selected === key ? 'active' : ''}`} onClick={() => onSelect(key as Sidebar_View_Props)} />
      ))}
    </div>
  );
};
