import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { applyActionCode, checkActionCode } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { Local_User_Props, VERIFICATION_TYPE_PROPS, capitalizeFirstLetter, logError, parseQueryParameters } from '../../utils';
import { TOAST_DURATION } from '../../utils/constants';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useAuth, useCommon } from '../../hooks';
import { getAuth } from 'firebase/auth';
import { _getAccessToken } from '../../api';
import { useToast } from '../../providers';
import { setStorageItem, storageItems } from '../../utils/localStorage';
import './index.scss';

/**
 * To Do:
 * - Setup an en.js file for all text within the app to have a centralized source of truth
 */

const Verify = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { getUser, setUser } = useAuth();
  const { fullSignOutMutation } = useCommon();

  const [loading, setLoading] = useState<boolean>(true);
  const [verificationType, setVerificationType] = useState<VERIFICATION_TYPE_PROPS>(null);
  const [verified, setVerified] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState<Record<string, string>>({});
  const [token, setToken] = useState<string>('');
  const [errorType, setErrorType] = useState<'email-mismatch' | 'invalid-token' | 'general' | ''>('');
  const [errorMessage, setErrorMessage] = useState('');

  // This isn't needed, but since we're using `React.StrictMode`, `verifyEmailAddress` runs twice, and should only run once
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(count + 1);
  }, []);

  useEffect(() => {
    if (count === 1) {
      setTimeout(() => {
        parseRoute();
      }, 1000);
    }
  }, [count]);

  useEffect(() => {
    if (!errorMessage && token) {
      if (queryParams['mode'] === 'verifyEmail') verifyEmailAddress(token); // Verify Email
      else if (queryParams['mode'] === 'resetPassword') {
        // Password Reset
        setStorageItem(storageItems.resetPassword, token);
        navigate('/reset-password');
      }
    }
  }, [token]);

  // Parse the url as we load the page
  const parseRoute = () => {
    // Extract variables
    const tempQueryParams = parseQueryParameters(window.location.href);
    const tempToken = tempQueryParams['oobCode'] || '';

    // Set state variables
    setQueryParams(tempQueryParams);
    setToken(tempToken);
    setVerificationType(tempQueryParams['mode'] as VERIFICATION_TYPE_PROPS);

    // Check for error
    if (!tempToken) {
      setErrorType('invalid-token');
      setErrorMessage('There was an error when validating your link. Please sign-in again.');
      setLoading(false);
    }
  };

  const verifyEmailAddress = (token: string) => {
    if (!auth || !auth?.currentUser) {
      // If user is not logged in, redirect to sign-in first, passing the token along with the redirect
      toast?.current?.show({ severity: 'info', summary: 'No Auth Found', detail: 'Please sign-in again to continue.', life: TOAST_DURATION });
      setStorageItem(storageItems.pendingVerification, token); // Save the token for when the user logs in
      setStorageItem(storageItems.completedWelcomeScreens, true); // This is a hack to prevent the welcome screens from showing

      navigate('/chat');
      setLoading(false);
    } else {
      checkActionCode(auth, token)
        .then((info) => {
          const emailFromAuth = auth?.currentUser?.email || '';
          const emailRegisteredFromIncomingLink = info?.data?.email || '';

          if (emailFromAuth !== emailRegisteredFromIncomingLink) {
            // If the email from the incoming link doesn't match the auth user's email
            setErrorType('email-mismatch');
            setErrorMessage('Your account does not match the email verification link provided.');
            setLoading(false);
            toast?.current?.show({
              severity: 'warn',
              summary: 'Account Mismatch',
              detail: 'Your account does not match the email verification link provided.',
              life: TOAST_DURATION,
            });
          } else {
            applyActionCode(auth, token)
              .then(async () => {
                setVerified(true);
                setLoading(false);

                const tempAuth = getAuth();

                // eslint-disable-next-line
                // @ts-ignore
                await tempAuth.currentUser.reload().then(async () => {
                  // This is the only way to get the emailVerified property to update at this point, as 'onAuthStateChanged' doesn't seem to be updating with the 'emailVerified' value change
                  if (tempAuth.currentUser?.emailVerified) {
                    // If email is verified, update our user object
                    const updatedUser = {
                      ...getUser(),
                      emailVerified: tempAuth.currentUser?.emailVerified,
                      email: tempAuth.currentUser.email || '',
                      firebaseUid: tempAuth.currentUser.uid || '',
                    } as Local_User_Props;
                    setUser(updatedUser);
                    await _getAccessToken({ forceRefresh: true }); // Force token refresh, otherwise token will show emailVerified as false
                  }
                });

                setTimeout(() => {
                  if (tempAuth.currentUser) navigate('/onboarding');
                  else {
                    setStorageItem(storageItems.state, 'verified');
                    navigate('/');
                  }
                }, 1500);
              })
              .catch((e) => {
                toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'There was an error when validating your email.', life: TOAST_DURATION });

                setErrorType('general');
                setErrorMessage('There was an error when validating your link. Please sign-in again.');
                setVerified(false);
                setLoading(false);
                logError(e, 'applyActionCode');
              });
          }
        })
        .catch((e) => {
          toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'There was an error when validating your email.', life: TOAST_DURATION });

          setErrorType('general');
          setErrorMessage('There was an error when validating your link. Please sign-in again.');
          setVerified(false);
          setLoading(false);
          logError(e, 'checkActionCode');
        });
    }
  };

  return (
    <div className="verify-screen-wrapper">
      <div className="verify-content-wrapper">
        <div className="title xxlargeBold">{loading ? 'Verifying' : verified ? 'Verified' : errorType === 'email-mismatch' ? 'Email Mismatch' : 'Verification Error'}</div>

        {errorMessage ? (
          <div className="subtitle smallRegular m-b-xxl">{errorMessage}</div>
        ) : (
          <>
            {verificationType === 'verifyEmail' ? (
              <>
                <div className="subtitle smallRegular m-b-xxl">
                  {loading
                    ? 'Please give us just a moment to verify your email'
                    : verified
                    ? 'Thanks for waiting. Welcome to Castello Chat!'
                    : "We're sorry, but there was an error when verifying your email. Please try signing up again."}
                </div>

                {verified && <div className="subtitle smallBold m-b-xl">Logging you in</div>}
              </>
            ) : verificationType === 'resetPassword' ? (
              <>
                <div className="subtitle smallRegular m-b-xxl">
                  {loading
                    ? 'Please give us just a moment to verify your password reset link'
                    : verified
                    ? 'One second. We are redirecting you to reset your password.'
                    : "We're sorry, but there was an error with your reset password link. Please try requesting a new password reset link again."}
                </div>

                {verified && <div className="subtitle smallBold m-b-xl">Rerouting...</div>}
              </>
            ) : null}

            {(loading || verified) && <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" animationDuration="1.5s" />}
          </>
        )}

        {errorType === 'email-mismatch' && (
          <Button className="smallMedium m-b-md" onClick={() => navigate('/chat')}>
            Continue as {capitalizeFirstLetter(getUser()?.firstName || '')} {capitalizeFirstLetter(getUser()?.lastName || 'current user')}
          </Button>
        )}

        {((!loading && !verified) || errorMessage) && (
          <Button
            className="smallMedium m-b-md"
            severity="secondary"
            onClick={() => {
              fullSignOutMutation.mutateAsync().then((signedOut) => {
                if (signedOut) navigate('/');
              });
            }}
          >
            Logout
          </Button>
        )}
      </div>
    </div>
  );
};

export default Verify;
