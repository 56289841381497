import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { PrimeReactProvider } from 'primereact/api';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthProvider, ChatApiProvider, ChatScrollProvider, DashboardApiProvider, ToastProvider, OverlayProvider, ChatApiProviderV2 } from './providers';
import App from './App';
import './services/sentry';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <PrimeReactProvider>
        <AuthProvider>
          <ChatScrollProvider>
            <ChatApiProvider>
              <ChatApiProviderV2>
                <DashboardApiProvider>
                  <ToastProvider>
                    <OverlayProvider>
                      <App />
                    </OverlayProvider>
                  </ToastProvider>
                </DashboardApiProvider>
              </ChatApiProviderV2>
            </ChatApiProvider>
          </ChatScrollProvider>
        </AuthProvider>
      </PrimeReactProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} position="bottom-right" /> */}
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
