import './index.scss';

import { memo } from 'react';
import { useCommon, useDashboardApi, useSidebar, useTheme } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { defaultTheme } from '../../utils/constants';
import { useOverlay } from '../../providers';
import { AuthModal } from '../../modals';
import { getNativeInsets } from '../../utils';
import { useChatListStore } from '../../store';
import GoogleIcon from '../google-icon';

const Navbar = memo(() => {
  const navigate = useNavigate();
  const { setSidebar, getSidebar } = useSidebar();
  const { setActiveDashboard } = useDashboardApi();
  const { getTheme } = useTheme();
  const { getUserAuthStatus, getOnlineStatus } = useCommon();
  const { showDialog } = useOverlay();
  const { setActiveChatId } = useChatListStore();

  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);
  const { data: sidebarVisible } = useQuery('sidebar', getSidebar);
  const { data: isOnline = true } = useQuery('online', getOnlineStatus);
  const nativeInsets = getNativeInsets();

  const authStatus = getUserAuthStatus();

  // Check if user has auth or not
  const authCheck = async () => {
    return new Promise((resolve) => {
      if (authStatus === 'authenticated') {
        resolve(true);
      } else {
        showDialog({
          content: <AuthModal navigate={(e: string) => navigate(e)} type={'signup'} />,
          onHide: () => {},
          closeOnEscape: false,
        });
        resolve(false);
      }
    });
  };

  const createNewItem = () => {
    authCheck().then((res) => {
      if (res) {
        if (window.location.pathname.includes('/chat')) {
          setActiveChatId('');
          navigate('/chat');
        }

        if (window.location.pathname.includes('/dashboard')) {
          setActiveDashboard('');
          navigate('/dashboard');
        }
      }
    });
  };

  return (
    <div className="navbar-wrapper" style={{ paddingTop: nativeInsets?.top }}>
      {!isOnline && (
        <div className="navbar-wrapper-row banner smallRegular" style={{ color: activeTheme === 'dark-theme' ? 'var(--text)' : 'var(--background)' }}>
          You're offline. Please check your internet connection.
        </div>
      )}

      {/* Mobile Navbar */}
      <div className="mobile-wrapper">
        <div className="navbar-icon-wrapper" style={{ top: nativeInsets?.top || 'var(--spacing-lg)' }}>
          <GoogleIcon name={'menu'} outline classname="clickable" onClick={() => setSidebar(true)} />
        </div>
      </div>

      {/* Desktop Navbar */}
      <div className="desktop-wrapper">
        {!sidebarVisible && (
          <div className="navbar-icon-wrapper" style={{ top: nativeInsets?.top || 'var(--spacing-lg)' }}>
            <GoogleIcon name={'edit_square'} outline classname="clickable" onClick={createNewItem} />
          </div>
        )}
      </div>
    </div>
  );
});

export default Navbar;
