import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Lottie, { Options } from 'react-lottie';
import { Typewriter } from 'react-simple-typewriter';
import { scrollList } from '../../mockdata';
import { WelcomeStepOne, WelcomeStepTwo } from '../../assets/lotties';
import { setStorageItem, storageItems } from '../../utils/localStorage';
import './index.scss';

const welcomeStepOneAnimationOptions: Options = {
  loop: true,
  autoplay: true,
  animationData: WelcomeStepOne,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const welcomeStepTwoAnimationOptions: Options = {
  loop: true,
  autoplay: true,
  animationData: WelcomeStepTwo,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const WelcomeScreen = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const welcomeOneRef = useRef(null);
  const welcomeTwoRef = useRef(null);

  const steps = [1, 2];

  useEffect(() => {
    if (welcomeOneRef.current) {
      // @ts-ignore
      // eslint-disable-next-line
      const animationInstance = welcomeOneRef.current?.anim;
      // eslint-disable-next-line
      animationInstance.setSpeed(0.2);
    }

    if (welcomeTwoRef.current) {
      // @ts-ignore
      // eslint-disable-next-line
      const animationInstance = welcomeTwoRef.current?.anim;
      // eslint-disable-next-line
      animationInstance.setSpeed(1);
    }
  }, [currentStep]);

  const renderDot = (index: number) => {
    return <div className={`dot ${currentStep === index ? 'active-dot' : ''}`} key={index}></div>;
  };

  const chatWithCastello = () => {
    setStorageItem(storageItems.completedWelcomeScreens, true);
    navigate('/chat');
  };

  return (
    <div className="welcome-screen-wrapper">
      <div className="lottie-wrapper" style={{ opacity: currentStep === 0 ? 0.35 : 1 }}>
        {currentStep === 0 && <Lottie ref={welcomeOneRef} speed={0.2} options={welcomeStepOneAnimationOptions} height={'100%'} width={'100%'} isStopped={false} isPaused={false} />}
        {currentStep === 1 && <Lottie ref={welcomeTwoRef} speed={0.8} options={welcomeStepTwoAnimationOptions} height={'100%'} width={'100%'} isStopped={false} isPaused={false} />}
      </div>

      <div className="welcome-content-wrapper">
        {currentStep === 0 && (
          <div className="welcome-text-wrapper">
            <div className="title headingBold m-b-md">Welcome to Castello AI</div>
            <div className="title xlargeRegular m-b-md">Analysis personalized for you</div>
          </div>
        )}
        {currentStep === 1 && (
          <div className="welcome-text-wrapper">
            <div className="title xlargeRegular m-b-sm">Together we can find out</div>
            <div className="title xxlargeBold m-b-md">
              <Typewriter words={scrollList} typeSpeed={35} deleteSpeed={15} delaySpeed={1500} cursor loop />
            </div>
          </div>
        )}
        {currentStep === 2 && <div className="title xxlargeBold m-b-md">Welcome to Castello</div>}
      </div>

      <div className="button-wrapper">
        {currentStep < steps.length - 1 ? (
          <Button className="welcome-button" data-cy="welcome-next-button" onClick={() => setCurrentStep(currentStep < 2 ? currentStep + 1 : 0)}>
            Next
          </Button>
        ) : (
          <>
            <Button className="welcome-button" data-cy="welcome-finish-button" onClick={chatWithCastello}>
              Explore with Castello AI
            </Button>
          </>
        )}

        <div className="dot-wrapper">{steps.map((a, index) => renderDot(index))}</div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
