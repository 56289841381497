// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, OAuthProvider, getAuth, indexedDBLocalPersistence } from 'firebase/auth';
import { logError } from '../utils';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_NAME}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_NAME}.appspot.com`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_MEASUREMENT_ID}`,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
const auth = getAuth(app);

// NOTE: Don't add anything here which requires a browser - Cypress tests need to run this file in a pure Node.js environment.

// Set persistence to local
auth
  .setPersistence(indexedDBLocalPersistence)
  .then(() => {
    // Firebase is ready with the desired persistence option
  })
  .catch((e) => {
    logError(e, 'firebase.ts', 'Error setting persistence');
  });

export { auth, googleProvider, appleProvider, app };
