import { memo, useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useQuery } from 'react-query';
import { useCommon, useDashboardApi, useGraph, useTheme } from '../../hooks';
import { CHAT_SEARCH_DEBOUNCE_TIME, LAZY_SCROLL_FETCH_THRESHHOLD, dashboardGraphSizeRestrictions, dashboardTradingViewGraphSizeRestrictions, defaultTheme } from '../../utils/constants';
import { ResponseBlockGraph, ResponseBlockWidget } from '../../components';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { useOverlay } from '../../providers';
import { Checkbox } from 'primereact/checkbox';
import { ApiBody_Post_Layout_Object_Props, Local_Selected_Graph_Props, TradingViewWidgetTypes, getRandomNumber } from '../../utils';
import { positiveAdjectives } from '../../mockdata/positiveAdjectives';
import { useParams } from 'react-router-dom';
import './index.scss';

/**
 * To Do: For responsive grid, algorithm should determine a placement for the new item(s) based on each breakpoint size.
 */

export const GraphSearchModal = memo(() => {
  const { setActiveDashboard, getActiveDashboard, getDashboardHistory, addToDashboardMap, updateDashboardHistory, updateDashboardMutation, createDashboardMutation } = useDashboardApi();
  const { setSelectedGraphs, getFilteredGraphs, getGraphHistory, setFilteredGraphs, getSelectedGraphs, getGraphMutation, graphSearchMutation } = useGraph();
  const { hideDialog } = useOverlay();
  const { getTheme } = useTheme();
  const { id: navigationUrl } = useParams();
  const { getUserAuthStatus } = useCommon();

  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  const { data: dashboardHistory = {} } = useQuery('dashboardHistory', getDashboardHistory);
  const { data: activeDashboard = '' } = useQuery('activeDashboard', getActiveDashboard);
  const { data: graphHistory = {} } = useQuery('graphHistory', getGraphHistory);
  const { data: filteredGraphs = [] } = useQuery('filteredGraphs', getFilteredGraphs);
  const { data: selectedGraphs = {} } = useQuery('selectedGraphs', getSelectedGraphs);

  const [pageNumber, setPageNumber] = useState(0); // Used for infinite scroll
  const [finishedScroll, setFinishedScroll] = useState(false); // Used to prevent loading after all chats have been loaded
  const [searchQuery, setSearchQuery] = useState(''); // Used to search chats
  const [searchLoading, setSearchLoading] = useState(false); // Using this instead of 'chatSearchMutation.isLoading' due to debounce
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [newDashboardId, setNewDashboardId] = useState('');
  const [count, setCount] = useState(0); // Find out how to delete this later

  const authStatus = getUserAuthStatus();

  // To Do: Make this into a common function
  // Check if user has auth or not
  const authCheck = () => {
    if (authStatus === 'authenticated') return true;
    else return false;
  };

  const handleGraphSearchScroll = (e: React.UIEvent<HTMLElement>) => {
    if (e) {
      // @ts-ignore
      // eslint-disable-next-line
      const { scrollTop, scrollHeight, clientHeight } = e?.target;

      const threshholdReached = Math.abs(scrollTop / (scrollHeight - clientHeight)) >= LAZY_SCROLL_FETCH_THRESHHOLD;

      // If we've passsed the threshhold, fetch new data
      if (threshholdReached) setFetchingData(true);
    }
  };

  // Reset the count when the navigationUrl changes
  useEffect(() => {
    setCount(0);
  }, [navigationUrl]);

  // Horrible (but working) way of updating the dashboard after we create it, since new item is not immediately being detected in dashboardHistory
  useEffect(() => {
    if (newDashboardId && count === 0) {
      setCount(count + 1);

      const updatedLayout = onAddItem(newDashboardId); // Update the layout with new graph(s)
      updateDashboardMutation.mutateAsync({ dashboardId: newDashboardId, title: dashboardHistory[newDashboardId].dashboard.title, isFavorite: false, layout: updatedLayout }).then(() => {
        hideDialog();
        setSelectedGraphs({}); // Reset selected graphs
      });
    }
  }, [dashboardHistory]);

  useEffect(() => {
    if (searchQuery) {
      const timeoutId = setTimeout(() => {
        const handler = async () => {
          await graphSearchMutation.mutateAsync({ searchQuery, pageNumber: 0 });
          setSearchLoading(false);
        };

        handler().catch((e) => console.log(e));
      }, CHAT_SEARCH_DEBOUNCE_TIME);

      return () => clearTimeout(timeoutId);
    } else {
      setFilteredGraphs([]);
      setSearchLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    // Load graphs on mount
    getGraphMutation.mutateAsync({ pageNumber: 0 });
  }, []);

  const onLazyLoad = async () => {
    if (authCheck() && !finishedScroll) {
      const newPageNumber = pageNumber + 1;
      setPageNumber(newPageNumber);

      await getGraphMutation.mutateAsync({ pageNumber: newPageNumber }).then(({ graphs }) => {
        if (!graphs || graphs.length === 0) setFinishedScroll(true);
      });

      return;
    }
    return;
  };

  // Fetches data once
  useEffect(() => {
    if (fetchingData) {
      const handler = async () => {
        await onLazyLoad(); // Fetch data
        setFetchingData(false); // Reset fetch data flag
      };

      handler().catch((e) => console.log(e));
    }
  }, [fetchingData]);

  const addGraphsToDashboard = () => {
    if (!activeDashboard || !dashboardHistory[activeDashboard]) {
      // Dashboard doesn't exist, create it

      createDashboardMutation.mutateAsync({ title: `My ${positiveAdjectives[getRandomNumber(0, positiveAdjectives.length - 1)]} Dashboard` }).then((dashboard) => {
        addToDashboardMap(dashboard, []); // Add dashboard to dashboard map
        setActiveDashboard(dashboard._id);
        setNewDashboardId(dashboard._id);
      });
    } else {
      // To Do: Have backend update so we don't need to pass in the title and isFavorite
      // Dashboard exists, add graphs to it
      const title = dashboardHistory[activeDashboard]?.dashboard?.title;
      const updatedLayout = onAddItem(); // Update the layout with new graph(s)

      updateDashboardMutation.mutateAsync({ dashboardId: activeDashboard, title, isFavorite: false, layout: updatedLayout }).then(() => {
        setSelectedGraphs({}); // Reset selected graphs
        hideDialog();
      });
    }
  };

  function graphClicked(id: string) {
    const newSelectedGraphs: Local_Selected_Graph_Props = { ...selectedGraphs };

    if (!newSelectedGraphs[id]) newSelectedGraphs[id] = true;
    else delete newSelectedGraphs[id];

    setSelectedGraphs(newSelectedGraphs);
  }

  // When we add an item to the dashboard, we need to update the layout
  function onAddItem(incomingDashboardId: string = '') {
    const updatedDashboard = { ...dashboardHistory[incomingDashboardId || activeDashboard] }; // Make a copy of the dashboard
    const newGraphs = [...updatedDashboard.graphs, ...Object.keys(selectedGraphs)]; // Copy the graphs
    const updatedLayout = [...updatedDashboard.dashboard.layout]; // Copy the layout

    // Add the new graphs to the layout
    Object.keys(selectedGraphs).map((graphId) => {
      // Check if the graph is a tradingview widget or a normal graph
      const graphType = graphHistory[graphId].graph?.data?.type === 'tradingview' ? 'tradingview' : 'graph';
      let defaultWidth = dashboardGraphSizeRestrictions.defaultWidth;
      let defaultHeight = dashboardGraphSizeRestrictions.defaultHeight;

      if (graphType === 'tradingview') {
        // @ts-ignore
        // eslint-disable-next-line
        const widgetType: TradingViewWidgetTypes = graphHistory[graphId]?.graph?.data?.config?.widget;

        // Assign the min width and height based on the widget type
        defaultWidth = dashboardTradingViewGraphSizeRestrictions[widgetType]?.defaultWidth;
        defaultHeight = dashboardTradingViewGraphSizeRestrictions[widgetType]?.defaultHeight;
      }

      // Find a placement for the new item
      const newItem = findPlacementForNewItem(updatedLayout, graphId, defaultWidth, defaultHeight, 24);

      // Add the new item to the layout
      updatedLayout.push({ graphId, x: newItem.x, y: newItem.y, w: defaultWidth, h: defaultHeight });
    });

    // Update the dashboard layout
    updatedDashboard.dashboard.layout = updatedLayout;

    // Update the dashboard history here, instead of within 'updateDashboardMutation'. This is because we have the info we need already, though we probably could move this stuff around.
    updateDashboardHistory({ dashboardIdToUpdate: activeDashboard, dashboard: updatedDashboard.dashboard, graphs: newGraphs });
    return updatedLayout;
  }

  // Function to find a placement for a new graph inside the grid
  function findPlacementForNewItem(gridItems: ApiBody_Post_Layout_Object_Props[], graphId: string, newItemWidth: number, newItemHeight: number, totalColumns: number) {
    // Function to check if a given position intersects with existing items
    const positionIntersects = (x: number, y: number, w: number, h: number) => {
      return gridItems.some((item) => x < item.x + item.w && x + w > item.x && y < item.y + item.h && y + h > item.y);
    };

    // Start searching for a placement from the top left of the grid
    for (let y = 0; ; y++) {
      for (let x = 0; x <= totalColumns - newItemWidth; x++) {
        // Check if the position is available
        if (!positionIntersects(x, y, newItemWidth, newItemHeight)) {
          return {
            x: x,
            y: y,
            w: newItemWidth,
            h: newItemHeight,
            graphId,
          };
        }
      }
    }
  }

  const graphsToDisplay = filteredGraphs?.length > 0 ? filteredGraphs : Object.keys(graphHistory);
  const noSearchResultsFound = !searchLoading && searchQuery.length > 0 && filteredGraphs?.length <= 0;
  const selectedGraphLength = Object.keys(selectedGraphs)?.length;

  return (
    <div className="graph-search-modal-wrapper">
      <div className="graph-search-modal-header">
        <div className="graph-search-wrapper">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={searchQuery}
              placeholder="Search graphs..."
              onChange={(e) => {
                setSearchLoading(true);
                setSearchQuery(e.target.value);
              }}
              className="smallRegular"
            />
            {graphSearchMutation.isLoading && <i className="pi pi-spin pi-spinner" style={{ right: '.75rem', color: 'var(--text)' }} />}
          </span>
        </div>

        {selectedGraphLength > 0 ? (
          <Button onClick={addGraphsToDashboard}>Add {selectedGraphLength} Graphs</Button>
        ) : (
          <Button severity={'secondary'} disabled={true}>
            Add {selectedGraphLength} Graphs
          </Button>
        )}
      </div>
      <div className="graph-display-wrapper" onScroll={handleGraphSearchScroll}>
        {
          <>
            {getGraphMutation.isLoading && pageNumber === 0 ? (
              <div className="loading-wrapper smallRegular">
                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" animationDuration="1.5s" />
              </div>
            ) : (
              <>
                {noSearchResultsFound ? (
                  <div className="inner-content-wrapper smallRegular">No results found</div>
                ) : (
                  <div className="graph-search-display-list">
                    {graphsToDisplay.map((item, index) => (
                      <div
                        className={`graph-search-display-item no-select ${selectedGraphs[item] ? 'selected-graph' : ''}`}
                        onClick={(e) => {
                          e.preventDefault();
                          graphClicked(item);
                        }}
                      >
                        {graphHistory[item].graph.data.type === 'graph' ? (
                          <ResponseBlockGraph
                            key={graphHistory[item].formattedGraphData._id}
                            {...graphHistory[item].formattedGraphData}
                            firstResponse={false}
                            index={index}
                            className={'fixed-chart-height'}
                          />
                        ) : (
                          // eslint-disable-next-line
                          // @ts-ignore
                          <ResponseBlockWidget key={graphHistory[item].graph._id} theme={activeTheme} {...graphHistory[item].graph.data} firstResponse={false} index={index} />
                        )}
                        <Checkbox onChange={() => graphClicked(item)} checked={selectedGraphs[item]}></Checkbox>
                        <div className="graph-checkbox-overlay" />
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        }
      </div>
    </div>
  );
});
