import axios, { AxiosError } from 'axios';
import { FIREBASE_API_ERRORS, MOBILE_VIEW_WIDTH } from './constants';
import {
  Util_Message_Mutation_Props,
  Component_ResponseBlock_Props,
  ApiResponse_Followup_Props,
  ApiResponse_Message_Props,
  ApiResponse_Graph_Datasets_Props,
  GraphSymbols,
  GraphSeries,
  Local_Graph_Breakdown_Props,
  FormattedGraphDataProps,
  Local_User_Props,
  ApiResponse_Graph_Props,
  Component_ResponseBlockGraph_Props,
  Local_KMB,
  ApiResponse_Error_Data,
  Local_Graph_Table_Props,
  Local_CSV_Headers,
  Local_CSV_Data,
  Component_ResponseBlockGraph_Config_Props,
  Local_CSV_Export_Props,
  Local_RN_Inset_Props,
} from './types';
import { FirebaseError } from 'firebase/app';
import * as Sentry from '@sentry/react';
import { getStorageItem, setStorageItem, storageItems } from './localStorage';
import isUaWebview from 'is-ua-webview';
import { BackendApiError } from '../errors';

export * from './types';
export * from './graphColors';
export * from './initialData';

// Random number generator
export function getRandomNumber(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Random string generator
export function getRandomString(size: number): string {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < size; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset[randomIndex];
  }

  return randomString;
}

// Hex to RGBA converter
export function hexToRgba(hex: string, alpha: number = 1): string {
  // Remove the "#" symbol if present
  hex = hex.replace(/^#/, '');

  // Check if the input is a valid hex color code
  if (!/^[0-9A-Fa-f]{6}$/i.test(hex) && !/^[0-9A-Fa-f]{8}$/i.test(hex)) {
    return '#FFF'; // Return null for invalid input
  }

  // Parse the hex values for red, green, and blue
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Ensure the alpha value is between 0 and 1
  alpha = Math.max(0, Math.min(1, alpha));

  // Format the RGBA color string
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function rgbaToHex(rgba: string): string {
  // Parse the RGBA values
  const match = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*([\d.]+)\)$/);
  if (!match) {
    throw new Error('Invalid RGBA color format');
  }

  // Extract the color components
  const r = parseInt(match[1], 10);
  const g = parseInt(match[2], 10);
  const b = parseInt(match[3], 10);
  const a = parseFloat(match[4]);

  // Ensure the components are in the valid range (0-255 for RGB, 0-1 for alpha)
  if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255 || a < 0 || a > 1) {
    throw new Error('RGBA color components out of range');
  }

  // Convert the RGB values to hexadecimal
  const hexR = r.toString(16).padStart(2, '0');
  const hexG = g.toString(16).padStart(2, '0');
  const hexB = b.toString(16).padStart(2, '0');

  // Convert the alpha value to hexadecimal (rounded to two decimal places)
  const hexA = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0');

  // Combine the components into a hexadecimal color string
  return `#${hexR}${hexG}${hexB}${hexA}`;
}

export function darkenHexColor(hex: string, factor: number): string {
  // Remove the '#' if it's included in the hex value
  hex = hex.replace('#', '');

  // Parse the hex value into RGB components
  let r = parseInt(hex.slice(0, 2), 16);
  let g = parseInt(hex.slice(2, 4), 16);
  let b = parseInt(hex.slice(4, 6), 16);

  // Apply the darkness factor (a value between 0 and 1)
  r = Math.max(0, Math.floor(r * (1 - factor)));
  g = Math.max(0, Math.floor(g * (1 - factor)));
  b = Math.max(0, Math.floor(b * (1 - factor)));

  // Convert the RGB values back to a hex string
  const darkenedHex = `#${(r * 0x10000 + g * 0x100 + b).toString(16).padStart(6, '0')}`;

  return darkenedHex;
}

export function shortenCurrency(value: string | number | null): string {
  if (!value) return '0';

  if (typeof value === 'string') value = parseFloat(value);

  if (value >= 1e12) {
    return (value / 1e12).toFixed(1) + 'T'; // Trillion
  } else if (value >= 1e9) {
    return (value / 1e9).toFixed(1) + 'B'; // Billion
  } else if (value >= 1e6) {
    return (value / 1e6).toFixed(1) + 'M'; // Million
  } else if (value >= 1e3) {
    return (value / 1e3).toFixed(1) + 'K'; // Thousand
  } else {
    return value.toString(); // Less than 1000, no change
  }
}

// Check if a string is a valid email address
export function isValidEmail(email: string): boolean {
  // Regular expression pattern for validating email addresses
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Use the test method to check if the email matches the pattern
  return emailPattern.test(email);
}

// Checks if error is from our API
// eslint-disable-next-line
export const isAxiosApiError = (e: any): e is Required<AxiosError<ApiResponse_Error_Data>> => {
  // eslint-disable-next-line
  return axios.isAxiosError(e) && e?.response?.data?.error?.message && e?.response?.data?.error?.type && e?.response?.data?.error?.code;
};

// Checks if error is from Firebase
// eslint-disable-next-line
export const isFirebaseError = (error: any): error is FirebaseError => {
  // eslint-disable-next-line
  return error instanceof FirebaseError || error?.code?.startsWith?.('auth/');
};

// Return a beutified error message
export function BeautifyError(err: AxiosError<ApiResponse_Error_Data> | FirebaseError) {
  try {
    if (!err) return '';

    if (isAxiosApiError(err) && err?.response) {
      // Axios error messages
      return err.response.data.error.message;
    }

    if (isFirebaseError(err)) {
      // Firebase error messages
      return FIREBASE_API_ERRORS[err.code] ? FIREBASE_API_ERRORS[err.code] : 'There was an error. Please try again.';
    }

    if (err instanceof BackendApiError) {
      // Fetch error messages
      return err.message;
    }

    return 'There was an error. Please try again.';
  } catch (e) {
    logError(e, 'BeautifyError');
    return 'There was an error. Please try again.';
  }
}

// Parses a string and returns a query parameters as an object
export function parseQueryParameters(url: string): Record<string, string> {
  const queryString = url.split('?')[1];
  if (!queryString) {
    return {};
  }

  const params = new URLSearchParams(queryString);
  const queryParams: Record<string, string> = {};

  // @ts-ignore
  for (const [key, value] of params.entries()) {
    // eslint-disable-next-line
    queryParams[key] = value;
  }

  return queryParams;
}

// Formats a number with commas
export function formatNumber(number: number): string {
  if (!number) return '0';
  return new Intl.NumberFormat('en-US').format(number);
}

// Formats a number based on a maximum number, returning a string with a unit
export function formatNumberBasedOnMaxNumber(num: number, decimalPlaces: number = 0, maxValue: number = 0): string {
  // Handle undefined or null input
  if (num === undefined || num === null) {
    return 'N/A';
  }
  // Handle zero input
  if (num === 0) {
    return '0';
  }

  let formattedValue = '';

  if (maxValue >= 1e12) {
    formattedValue = (num / 1e12).toFixed(decimalPlaces) + 'T'; // Trillions
  } else if (maxValue >= 1e9) {
    formattedValue = (num / 1e9).toFixed(decimalPlaces) + 'B'; // Billions
  } else if (maxValue >= 1e6) {
    formattedValue = (num / 1e6).toFixed(decimalPlaces) + 'M'; // Millions
  } else if (maxValue >= 1e3) {
    formattedValue = (num / 1e3).toFixed(decimalPlaces) + 'K'; // Thousands
  } else {
    formattedValue = num.toString(); // No unit needed
  }

  // Remove trailing zeros and unnecessary decimal point for whole numbers
  formattedValue = formattedValue.replace(/\.00$/, '');

  return formattedValue;
}

// Formats a number using K, M, or B
export function kmbFormatter(num: number, kmb: Local_KMB): string {
  // Handle undefined or null input
  if (num === undefined || num === null) {
    return 'N/A';
  }
  // Handle zero input
  if (num === 0) {
    return '0';
  }

  // Store if the number is negative and then work with its absolute value
  const isNegative = num < 0;
  const absoluteNum = Math.abs(num);

  // Return the number itself if it's less than 1000
  if (absoluteNum < 1000) {
    return (isNegative ? '-' : '') + absoluteNum.toLocaleString(undefined, { maximumFractionDigits: 2 });
  }

  let divisor: number;

  switch (kmb) {
    case 'r':
      divisor = 1; // Ratio
      break;
    case 'k':
      divisor = 1e3; // Thousand
      break;
    case 'm':
      divisor = 1e6; // Million
      break;
    case 'b':
      divisor = 1e9; // Billion
      break;
    default:
      throw new Error('Invalid unit. kmb must be "k", "m", or "b".');
  }

  const formattedNumber = absoluteNum / divisor;

  // Use toLocaleString for formatting with commas
  return (isNegative ? '-' : '') + formattedNumber.toLocaleString(undefined, { maximumFractionDigits: 2 });
}

// Parses LLM responses
export const parseServerResponse = (serverResponse: ApiResponse_Message_Props): Util_Message_Mutation_Props => {
  const aiGeneratedData = serverResponse?.aiGeneratedData || {};
  const responses = aiGeneratedData?.responses || [];
  const followupQuestions: ApiResponse_Followup_Props = aiGeneratedData?.followupQuestions || [];

  // console.log('parseServerResponse 1: ', { serverResponse, aiGeneratedData, responses, followupQuestions });

  const messages: Component_ResponseBlock_Props[] = [];
  const citations: string[] = [];

  responses.forEach((response, index) => {
    const randomString = getRandomString(10);
    const position = aiGeneratedData.responses.length + index;
    const isFirstResponse = index === 0;
    const isLastResponse = index === responses.length - 1;

    if (response.type === 'text' || response.type === 'document' || response.type === 'news' || response.type === 'transcripts') {
      messages.push({
        usertype: 'server',
        value: response.response,
        type: response.type,
        _id: serverResponse._id.toString().concat(`-${randomString}-server`),
        index: 0,
        originalMessageId: serverResponse._id.toString(),
        modifiedTimestamp: convertDateToEpoch(serverResponse.createdAt, position),
        userFeedback: serverResponse.userFeedback,
        firstResponse: isFirstResponse,
        lastResponse: isLastResponse,
      });
      if (response?.citations) citations.push(...response?.citations);
    } else if (response?.type === 'tradingview') {
      messages.push({
        usertype: 'server',
        type: response.type,
        config: response.config,
        _id: serverResponse._id.toString().concat(`-${randomString}-server`),
        index: 0,
        originalMessageId: serverResponse._id.toString(),
        modifiedTimestamp: convertDateToEpoch(serverResponse.createdAt, position),
        userFeedback: serverResponse.userFeedback,
        firstResponse: isFirstResponse,
        lastResponse: isLastResponse,
      });
    } else if (response.type === 'graph') {
      const parsedGraph = parseGraphs(response);
      messages.push({
        ...parsedGraph,
        _id: serverResponse._id.toString().concat(`-${randomString}-server`),
        index: 0,
        originalMessageId: serverResponse._id.toString(),
        modifiedTimestamp: convertDateToEpoch(serverResponse.createdAt, position),
        userFeedback: serverResponse.userFeedback,
        firstResponse: isFirstResponse,
        lastResponse: isLastResponse,
      });
    } else {
      messages.push({
        usertype: 'server',
        value: `An unknown error occurred. We've been notified and will fix this as soon as possible. For now, please refresh the page and try again.`,
        type: 'text',
        _id: Date.now().toString(),
        index: 0,
        originalMessageId: serverResponse._id.toString(),
        modifiedTimestamp: convertDateToEpoch(serverResponse.createdAt, position),
        userFeedback: serverResponse.userFeedback,
        firstResponse: isFirstResponse,
        lastResponse: isLastResponse,
      });
    }
  });

  const finalCitations = {
    [serverResponse._id.toString()]: citations,
  };

  // console.log('parseServerResponse 2: ', { messages, followupQuestions, citations, finalCitations });
  return { messages, followupQuestions, citations: finalCitations };
};

export const parseGraphs = (graphData: ApiResponse_Graph_Props): Component_ResponseBlockGraph_Props => {
  const symbols: GraphSymbols = {};

  const processData = (): FormattedGraphDataProps => {
    const processedGroups: { [key: string]: Local_Graph_Breakdown_Props } = {};

    graphData.data_sets.forEach((dataset: ApiResponse_Graph_Datasets_Props) => {
      if (!processedGroups[dataset.group]) {
        processedGroups[dataset.group] = {
          dimensions: ['x_value'],
          x_axis_title: dataset.x_axis_title,
          mean: {},
          standard_deviation: {},
          median: {},
          y_values: [],
          percent_yearly_change: [],
          percent_quarterly_change: [],
        };
      }
      processDataSet(processedGroups[dataset.group], dataset);
    });

    // console.log('output: ', processedGroups);
    return processedGroups;
  };

  const processDataSet = (processedData: Local_Graph_Breakdown_Props, dataset: ApiResponse_Graph_Datasets_Props) => {
    processedData.dimensions.push(dataset.symbol);
    processedData.mean[dataset.symbol] = dataset.mean;
    processedData.standard_deviation[dataset.symbol] = dataset.standard_deviation;
    processedData.median[dataset.symbol] = dataset.median;
    symbols[dataset.symbol] = true;

    dataset.y_values.forEach((value, index) => {
      if (processedData.y_values.length <= index) {
        // @ts-ignore
        processedData.y_values.push({ x_value: dataset.x_values[index] });

        // @ts-ignore
        if (dataset.percent_yearly_change) processedData.percent_yearly_change.push({ x_value: dataset.x_values[index] });

        // @ts-ignore
        if (dataset.percent_quarterly_change) processedData.percent_quarterly_change.push({ x_value: dataset.x_values[index] });
      }

      if (dataset.percent_yearly_change) {
        // @ts-ignore
        // eslint-disable-next-line
        processedData.percent_yearly_change[index][dataset.symbol] = dataset.percent_yearly_change[index];
      }

      if (dataset.percent_quarterly_change) {
        // @ts-ignore
        // eslint-disable-next-line
        processedData.percent_quarterly_change[index][dataset.symbol] = dataset.percent_quarterly_change[index];
      }
      // @ts-ignore
      processedData.y_values[index][dataset.symbol] = value;
    });

    // Additional processing for percent_yearly_change and percent_quarterly_change can be added here
  };

  const formattedOutput: FormattedGraphDataProps = processData();
  const series: GraphSeries[] = [];

  Object.keys(symbols).forEach(() => {
    series.push({ type: 'bar' });
  });

  const output: Component_ResponseBlockGraph_Props = {
    type: graphData.graph_type,
    data: formattedOutput,
    config: {
      title: graphData.title,
      currency: graphData?.currency || '',
      frequency: graphData?.frequency || '',
      period: graphData?.period || 1,
      graph_type: graphData.graph_type,
      plot: graphData.plot,
      y_axis_title: graphData.y_axis_title,
      symbols,
      series,
    },
    xvars: Object.keys(formattedOutput),
    _id: '',
    usertype: 'server',
    index: 0,
    originalMessageId: '',
    modifiedTimestamp: 0, // Override this value
    userFeedback: {
      reaction: null,
      reasons: null,
      additionalFeedback: null,
    },
    firstResponse: false,
    lastResponse: false,
  };

  return output;
};

/* eslint-disable */
// @ts-ignore
export function mergeObjects(obj1, obj2) {
  const result = { ...obj1 };

  Object.keys(obj2).forEach((key) => {
    if (typeof obj2[key] === 'object' && obj2[key] !== null && key in obj1) {
      result[key] = mergeObjects(obj1[key], obj2[key]);
    } else if (!(key in obj1)) {
      result[key] = obj2[key];
    }
  });

  return result;
}
/* eslint-enable */

export function parseTextResponse(input: string): string {
  try {
    if (!input) return '';

    // Regular expression to match Markdown links and plain URLs
    const regex = /\[([^\]]+)\]\(([^)]+)\)|https?:\/\/[^\s]+/g;

    let sourceIndex = 1;

    const output = input.replace(regex, (match, text, url) => {
      // If it's a Markdown link
      if (text && url) {
        return `[${text}](${url}) [[${sourceIndex++}]](${url} '${url}')`;
      }
      // If it's a plain URL
      else {
        return `[[${sourceIndex++}]](${match} '${match}')`;
      }
    });

    return output;
  } catch (e) {
    logError(e, 'parseTextResponse');
    return '';
  }
}

// function transformLatexDelimiters(text: string) {
//   // Replace inline math delimiters \\( and \\) with $
//   text = text.replace(/\\\\\(/g, '$').replace(/\\\\\)/g, '$');
//   // Replace display math delimiters \\[ and \\] with $$
//   text = text.replace(/\\\\\[/g, '$$').replace(/\\\\\]/g, '$$');
//   return text;
// }

export function parseCitation(citation: string): string {
  try {
    if (!citation) return '';
    const parsedUrl = new URL(citation);
    return parsedUrl.hostname.replace(/^www\./, '');
  } catch (e) {
    logError(e, 'parseCitation');
    return '';
  }
}

export function getBillingLink({ isPremiumUser, user, frequency }: { isPremiumUser: boolean; user: Local_User_Props | null | undefined; frequency: 'monthly' | 'yearly' }): string {
  try {
    const url = new URL(
      isPremiumUser
        ? process.env.REACT_APP_STRIPE_MANAGE_BILLING_LINK!
        : frequency === 'monthly'
        ? process.env.REACT_APP_STRIPE_PURCHASE_MONTHLY_LINK!
        : frequency === 'yearly'
        ? process.env.REACT_APP_STRIPE_PURCHASE_YEARLY_LINK!
        : '',
    );
    url.searchParams.append('prefilled_email', user?.email as string);
    url.searchParams.append('client_reference_id', user?._id as string);
    return url.toString();
  } catch (e) {
    logError(e, 'getBillingLink');
    return '';
  }
}

// Converts a date string to epoch time
export function convertDateToEpoch(timestamp: string, offsetMillis?: number): number {
  if (!offsetMillis) offsetMillis = 0;

  const date = new Date(timestamp);
  return date.getTime() + offsetMillis;
}

// Converts a graph time range to a string
export function timeRangeToSting(years: number, lastIndex: boolean): string {
  if (!years) return '';

  if (lastIndex) return 'Max';
  return `${years} Years`;
}

export const isMobile = () => {
  return window.innerWidth < MOBILE_VIEW_WIDTH;
};

export const isNativeApp = () => {
  return getStorageItem(storageItems.isNativeApp);
};

const isSnapchatWebview = (userAgent: string): boolean => userAgent.toLowerCase().includes('snapchat');
export const isWebview = (userAgent: string): boolean =>
  // The `is-ua-webview` library fails to detect Snapchat webviews on iOS, so we need to check for it explicitly.
  // eslint-disable-next-line
  isUaWebview(userAgent) || isSnapchatWebview(userAgent);

export const getNativeInsets = (): Local_RN_Inset_Props => {
  return getStorageItem(storageItems.nativeInsets) as Local_RN_Inset_Props;
};

// Capitalize the first letter of a string
export const capitalizeFirstLetter = (str: string) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const logError = (error: unknown, functionName: string, customMessage?: string) => {
  try {
    // eslint-disable-next-line
    console.log(`${functionName}: `, customMessage || error);

    // Don't log API errors, they're already reported on the backend
    if (isAxiosApiError(error) || error instanceof BackendApiError) {
      return;
    }

    // Don't log common Firebase errors, we don't care
    if (isFirebaseError(error) && FIREBASE_API_ERRORS[error?.code]) {
      return;
    }

    if (!(error instanceof Error)) {
      console.log('Error not instance of Error: ', error);
    }

    Sentry.captureException(error, {
      extra: {
        function: functionName,
        message: customMessage,
      },
    });
  } catch (e) {
    console.log('logError error: ', e);
    Sentry.captureException(new Error('Error recording error', { cause: e }));
  }
};

// Limits the number of decimal places in a number
export const limitDecimalPlaces = (value: number, trailLength: number = 2) => {
  if (!value) return value;

  const multiplier = Math.pow(10, trailLength);
  return Math.round(value * multiplier) / multiplier;
};

export function generateCSVData(rawData: Local_Graph_Table_Props[], config: Component_ResponseBlockGraph_Config_Props): Local_CSV_Export_Props {
  if (!rawData || rawData.length === 0) {
    // Handle empty or null data appropriately
    return { data: [], headers: [], filename: 'castello-graph-data.csv' };
  }

  // Clean header keys and push to headers array
  const headerKeys = Object.keys(rawData[0]);
  const headers: Local_CSV_Headers = headerKeys.map((key) => {
    const upperCasedKey = key.charAt(0).toUpperCase() + key.slice(1);
    const label = upperCasedKey.replace('_', ' ');

    return { label, key };
  });

  // Sort headers
  headers.sort((a, b) => {
    // -1 = a comes first
    // 0 = no change
    // 1 = b comes first

    // Symbol should be first
    if (a.key === 'symbol') {
      return -1;
    }

    // Currency should be second
    if (a.key === 'currency' && b.key !== 'symbol') {
      return -1;
    }

    // Years should be last, sorted in ascending order
    const isANumber = /^[0-9]*/.test(a.key);
    const isBNumber = /^[0-9]*/.test(b.key);

    if (isANumber && !isBNumber) {
      return 1;
    }
    if (!isANumber && isBNumber) {
      return -1;
    }
    if (isANumber && isBNumber) {
      return parseInt(a.key) - parseInt(b.key);
    }

    return 0;
  });

  const data: Local_CSV_Data = rawData.map((item) => {
    const cleanedData = { ...item };
    if (config?.currency) {
      cleanedData.currency = config?.currency;
    }

    return cleanedData;
  });

  // If currency wasnt already a field in headers, add it manually
  if (headers[1]?.key !== 'currency' && config?.currency) {
    headers.splice(1, 0, { label: 'Currency', key: 'currency' });
  }

  const csvFilename = config?.title ? `${config.title.toLowerCase().replace(' ', '-')}.csv` : 'castello-graph-data.csv';

  return { data, headers, filename: csvFilename };
}

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * A utility function to retry a function with exponential backoff.
 * @param retryFunction Function to execute and retry
 * @param shouldRetry Function to determine whether an error response from retryFunction should be retried
 * @param maxRetries Maximum number of retries. If this is reached, the next error will be thrown.
 * @returns The result of retryFunction
 */
export const exponentialRetry = async <ReturnValue>(
  retryFunction: () => Promise<ReturnValue>,
  {
    shouldRetry = () => true,
    beforeRetry,
    maxRetries,
  }: {
    shouldRetry?: (err: unknown) => boolean;
    beforeRetry?: () => Promise<void>;
    maxRetries: number;
  },
) => {
  let retries = 0;
  let lastError: unknown;
  while (retries <= maxRetries) {
    try {
      if (beforeRetry && retries > 0) await beforeRetry();
      const result = await retryFunction();
      return result;
    } catch (err) {
      lastError = err;
      if (!shouldRetry(err)) {
        throw err;
      }
    }

    // exponential delay time
    // random number of ms is added to avoid retries coming in waves (0-500ms added)
    await delay(2 ** retries * 1000 + Math.round(Math.random() * 500));
    retries += 1;
  }

  throw lastError;
};

export const toGraphData = (data: ApiResponse_Graph_Props) => {
  return {
    ...parseGraphs(data),
    modifiedTimestamp: convertDateToEpoch(Date.now().toString(), 0),
  };
};

// Compares the createdAt date to the current date and returns a string
export const getTimeAgo = (createdAt: Date) => {
  // Default Catch
  if (!createdAt || isNaN(createdAt?.getTime())) {
    return '';
  }
  const oneDayMs = 1000 * 60 * 60 * 24;
  const diffDays = (new Date().getTime() - createdAt?.getTime()) / oneDayMs;

  if (diffDays < 1) return 'today';
  if (diffDays < 2) return 'yesterday';
  if (diffDays < 7) return 'previous 7 days';
  if (diffDays < 30) return 'previous 30 days';
  return createdAt?.toLocaleString('default', { month: 'long', year: 'numeric' });
};

// Simulated API error
export const apiTimeout = () => {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error('Simulated API error'));
    }, 3000);
  });
};

// Interval with timeout
// Call this function like: `let interval = setIntervalWithTimeout`, and clear the interval like usual.
export const setIntervalWithTimeout = (callback: () => void | Promise<void>, interval: number, timeout?: number) => {
  const startTime = Date.now();
  const timeoutPeriod = timeout || 60000; // Default timeout period of 1 minute

  const intervalId = setInterval(() => {
    const currentTime = Date.now();
    const elapsedTime = currentTime - startTime;

    const handleCallback = async () => {
      try {
        await callback();
      } catch (error) {
        console.error('Error during interval callback:', error);
      }

      if (elapsedTime >= timeoutPeriod) {
        clearInterval(intervalId);
      }
    };

    handleCallback();
  }, interval);

  return intervalId;
};

// Helper function to remove an item from the lastMessageMap
export const removeItemFromLastMessageMap = (id: string) => {
  // @ts-ignore
  const lastMessageMap = getStorageItem(storageItems.lastMessageMap);
  // @ts-ignore
  delete lastMessageMap[id];
  setStorageItem(storageItems.lastMessageMap, lastMessageMap);
};

// Helper function to add an item from the lastMessageMap
export const addItemToLastMessageMap = (id: string, prompt: string) => {
  let lastMessageMap = getStorageItem(storageItems.lastMessageMap);
  // @ts-ignore
  lastMessageMap = { ...lastMessageMap, [id]: prompt };
  setStorageItem(storageItems.lastMessageMap, lastMessageMap);
};
