import { create } from 'zustand';
// import { devtools, persist } from 'zustand/middleware';
import {
  ApiResponse_Chat_Document_Props,
  ApiResponse_Chats_Status_Inner_Props,
  ApiResponse_Chats_Status_Props,
  ApiResponse_Message_Props,
  ApiResponse_Responses_Props,
  initialChatStatus,
} from '../utils';

interface UpsertMessageState {
  chatId: string;
  message: ApiResponse_Message_Props;
  addToStart?: boolean;
  generatedId?: string;
}

interface UpdateStreamDataProps {
  chatId: string;
  data: ApiResponse_Responses_Props;
}

interface ChatErrorMessageProps {
  chatId: string;
  generatedId: string;
  prompt: string;
  error: string;
}

// Define your state shape
interface ChatListState {
  // TODO: Remove this, try to make use of 'params.id' only instead of having both
  // Keep track of the active chat
  activeChatId: string;
  setActiveChatId: (chatId: string) => void;

  // Sidebar list of chats
  chatList: { [key: string]: ApiResponse_Chat_Document_Props };
  upsertChatList: (chatId: string, item: Partial<ApiResponse_Chat_Document_Props>) => void;
  setChatList: (chatList: ApiResponse_Chat_Document_Props[]) => void;
  deleteItem: (chatIdToRemove: string) => void;

  // Which sidebar chats are expanded
  expandedChats: { [key: string]: boolean };
  updateExpandedChats: (chatId: string, value: boolean) => void;

  // Messages for each chat
  messageList: { [chatId: string]: ApiResponse_Message_Props[] };
  upsertMessage: ({ chatId, message, addToStart, generatedId }: UpsertMessageState) => void;
  removeMessage: (chatId: string, messageId: string) => void;
  getMessageListByChatId: (chatId: string) => ApiResponse_Message_Props[];

  // Stream Data Map
  streamMap: { [key: string]: ApiResponse_Responses_Props[] | [] };
  updateStreamMapItem: ({ chatId, data }: UpdateStreamDataProps) => void;
  addStreamMapItem: (chatId: string) => void;
  removeStreamMapItem: (chatId: string) => void;

  // Map of which chats have errors
  chatErrorMap: { [key: string]: ChatErrorMessageProps };
  addChatErrorMapItem: (item: ChatErrorMessageProps) => void;
  removeChatErrorMapItem: (chatId: string) => void;

  // TODO: Is this still used?
  chatStatus: { [key: string]: ApiResponse_Chats_Status_Inner_Props };
  setChatStatus: (chats: ApiResponse_Chats_Status_Props) => void;
  getCurrentChatStatus: (chatId: string) => ApiResponse_Chats_Status_Inner_Props;
}

// Chat List Store

export const useChatListStore = create<ChatListState>((set, get) => ({
  activeChatId: '',
  setActiveChatId: (chatId) => set({ activeChatId: chatId }),

  chatList: {},
  upsertChatList: (chatId, item) =>
    set((state) => {
      const existingItem = state.chatList[chatId];
      if (existingItem) {
        // Update the existing item with the partial item data
        return {
          chatList: { ...state.chatList, [chatId]: { ...existingItem, ...item } },
        };
      } else {
        // Add the new item
        return {
          chatList: { ...state.chatList, [chatId]: item as ApiResponse_Chat_Document_Props },
        };
      }
    }),
  setChatList: (chatList) =>
    set(() => {
      const newChatList: { [key: string]: ApiResponse_Chat_Document_Props } = {};

      chatList.forEach((chat) => {
        newChatList[chat._id] = chat;
      });

      return { chatList: newChatList };
    }),

  chatErrorMap: {},
  addChatErrorMapItem: (item) => set((state) => ({ chatErrorMap: { ...state.chatErrorMap, [item.chatId]: item } })),
  removeChatErrorMapItem: (chatId) =>
    set((state) => {
      const newChatErrorMap = { ...state.chatErrorMap };
      delete newChatErrorMap[chatId];
      return { chatErrorMap: newChatErrorMap };
    }),

  deleteItem: (chatId) =>
    set((state) => {
      const newChatList = { ...state.chatList };
      delete newChatList[chatId];
      return { chatList: newChatList };
    }),

  expandedChats: {},
  updateExpandedChats: (chatId, value) =>
    set((state) => ({
      expandedChats: { ...state.expandedChats, [chatId]: value },
    })),

  messageList: {},
  // Insert or update a message in a chat
  upsertMessage: ({ chatId, message, addToStart = false, generatedId }) =>
    set((state) => {
      const chatMessages = state.messageList[chatId] || [];
      const existingMessageIndex = chatMessages.findIndex((msg) => msg._id === message._id || msg._id === generatedId);

      if (existingMessageIndex >= 0) {
        // Update the existing message
        const updatedChatMessages = [...chatMessages];
        updatedChatMessages[existingMessageIndex] = { ...chatMessages[existingMessageIndex], ...message };
        return {
          messageList: {
            ...state.messageList,
            [chatId]: updatedChatMessages,
          },
        };
      } else {
        // Add the new message either to the start or the end of the array
        const updatedChatMessages = addToStart ? [message, ...chatMessages] : [...chatMessages, message];
        return {
          messageList: {
            ...state.messageList,
            [chatId]: updatedChatMessages.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
          },
        };
      }
    }),
  // Remove a message from a chat
  removeMessage: (chatId, messageId) =>
    set((state) => {
      const chatMessages = state.messageList[chatId] || [];
      const updatedChatMessages = chatMessages.filter((msg) => msg._id !== messageId);
      return {
        messageList: {
          ...state.messageList,
          [chatId]: updatedChatMessages,
        },
      };
    }),

  // Return the messages for a chat
  getMessageListByChatId: (chatId) => get().messageList[chatId] || [],

  streamMap: {},
  // Updates a stream map item
  updateStreamMapItem: ({ chatId, data }) =>
    set((state) => {
      const newStreamMap = { ...state.streamMap };

      const buildResponse = (responses: ApiResponse_Responses_Props[] = []) => {
        if (responses?.find((response) => response.group_id === data?.group_id) && data?.type === 'text') {
          return responses.map((response) => {
            if (response.group_id === data.group_id) {
              return {
                ...response,
                response: response.type === 'text' && data.type === 'text' ? response.response + data.response : '',
              };
            }
            return response;
          });
        }
        return [...responses, data];
      };

      newStreamMap[chatId] = buildResponse(newStreamMap[chatId]);

      return {
        streamMap: {
          ...state.streamMap,
          [chatId]: newStreamMap[chatId],
        },
      };
    }),
  // Add the initial stream map item, used to display the loader
  addStreamMapItem: (chatId) =>
    set((state) => {
      const newStreamMap = { ...state.streamMap };

      if (!newStreamMap[chatId]) {
        newStreamMap[chatId] = [];
      }
      return { streamMap: newStreamMap };
    }),
  // Removes a stream map item
  removeStreamMapItem: (chatId) =>
    set((state) => {
      const newStreamMap = { ...state.streamMap };
      delete newStreamMap[chatId];
      return { streamMap: newStreamMap };
    }),

  chatStatus: {},
  setChatStatus: (incomingChats) =>
    set(() => {
      return { chatStatus: incomingChats.chats };
    }),
  getCurrentChatStatus: (chatId) => get().chatStatus[chatId] || initialChatStatus,
}));
