import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme, useChatApi, useAuth, useSidebar, useCommon, useChatApiV2 } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { TOAST_DURATION, defaultTheme } from '../../utils/constants';
import {
  Component_Sidebar_Props,
  isMobile,
  getNativeInsets,
  Sidebar_View_Props,
  ChipBar_Key_Props,
  Local_Sidebar_Menu_Item_Props,
  ApiResponse_Chat_Document_Props,
  getTimeAgo,
  BeautifyError,
} from '../../utils';
import { useOverlay, useToast } from '../../providers';
import { ReleaseLogsModal, AuthModal, UserProfileModal } from '../../modals';
import { Avatar } from 'primereact/avatar';
import { getStorageItem, storageItems } from '../../utils/localStorage';
import RenderSVG from '../renderSVG';
import { getExampeChips } from '../../mockdata';
import { Menu } from 'primereact/menu';
import { useChatListStore } from '../../store';
import { InfiniteLoader, ChipBarData, SidebarHistoryListItem, SidebarButton, ChipBar, SidebarFilter, SidebarActionMenu } from '../../components';
import './index.scss';

const Sidebar = memo(({ type }: Component_Sidebar_Props) => {
  const navigate = useNavigate();
  const toast = useToast();

  const { showDialog } = useOverlay();
  const { getSidebar, setSidebar } = useSidebar();
  const { getTheme, toggleTheme } = useTheme();
  const { getUserAuthStatus, fullSignOutMutation } = useCommon();
  const { getFilteredChats } = useChatApi();
  const { getUser } = useAuth();
  const { useChatsQuery } = useChatApiV2();
  const { chatList: zChatListData, upsertChatList, activeChatId, setActiveChatId } = useChatListStore();

  // console.log('zChatListData: ', zChatListData);

  // Theme
  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  // Sidebar
  const { data: sidebarVisible } = useQuery('sidebar', getSidebar);

  // User
  const { data: user } = useQuery('user', getUser);

  // Chat
  const { data: filteredChats = [], isLoading: filteredChatsLoading } = useQuery('filteredChats', getFilteredChats);
  const { isFetching, hasNextPage, fetchNextPage } = useChatsQuery();

  const [sidebarViewState, setSidebarViewState] = useState<Sidebar_View_Props>('trade');
  const [searchQuery, setSearchQuery] = useState(''); // Used to search chats
  const [searchLoading, setSearchLoading] = useState(false); // Using this instead of 'chatSearchMutation.isLoading' due to debounce
  const [menuVisible, setMenuVisible] = useState(false);
  const [editingTitle, setEditingTitle] = useState<string | null>(null);

  const [prevWidth, setPrevWidth] = useState(window.innerWidth);

  const authStatus = getUserAuthStatus();
  const nativeInsets = getNativeInsets();

  const userMenuItems: Local_Sidebar_Menu_Item_Props[] = [
    {
      groupId: 'theme',
      label: `${activeTheme === 'light-theme' ? 'Dark' : 'Light'} Mode`,
      icon: activeTheme === 'light-theme' ? 'dark_mode' : 'light_mode',
      command: () => toggleTheme(),
    },
    // {
    //   groupId: 'common',
    //   label: 'Help / FAQ',
    //   icon: 'help',
    //   command: () => {
    //     setMenuVisible(false);
    //     window.open('https://www.castello.ai/faq', '_blank');
    //   },
    // },
    {
      groupId: 'common',
      label: 'Release Logs',
      icon: 'text_snippet',
      command: () => {
        setMenuVisible(false);
        showReleaseLogsModal();
      },
    },
    {
      groupId: 'common',
      label: 'User',
      icon: 'person',
      command: () => {
        setMenuVisible(false);
        modalMiddleware(showUserModal);
      },
    },
    {
      groupId: 'logout',
      label: 'Log out',
      icon: 'logout',
      command: () => {
        fullSignOutMutation.mutateAsync().then((signedOut) => {
          if (signedOut) navigate('/');
        });
      },
    },
  ];

  // Don't show billing if user is in webview
  // if (!isWebview(userAgent)) {
  //   items.push({
  //     label: 'Billing',
  //     icon: 'pi pi-fw pi-pencil',
  //     command: () => {
  //       if (!authCheck() || !isPremiumUser) {
  //         modalMiddleware(showUpgradeModal);
  //         return;
  //       } else {
  //         window.location.href = getBillingLink({ isPremiumUser, user, frequency: 'monthly' });
  //         return;
  //       }
  //     },
  //   });
  // }

  // To Do: Make this into a common function
  // Check if user has auth or not
  const authCheck = () => {
    if (authStatus === 'authenticated') return true;
    else return false;
  };

  // Listen for mount
  useEffect(() => {
    if (getStorageItem(storageItems.pendingVerification)) {
      showAuthModal('signin');
    }
  }, []);

  const createNewItem = () => {
    if (isMobile()) {
      setSidebar(false);
    }

    if (type === 'chat') {
      setActiveChatId('');
      navigate('/chat');
    }
  };

  const selectItem = (id: string) => {
    setActiveChatId(id);
    if (isMobile()) {
      setSidebar(false);
    }

    if (type === 'chat') navigate(`/chat/${id}`);
    if (type === 'dashboard') navigate(`/dashboard/${id}`);
  };

  const items = [
    {
      label: 'Rename',
      icon: 'pi pi-pencil',
      command: () => {
        // @ts-ignore
        // eslint-disable-next-line
        // const foundTitle: string = data.find((item) => item?._id === focusedMenuItemId)?.title || '';
        // setOriginalTitle(foundTitle);
        // setNewTitle(foundTitle);
        setEditingTitle(activeChatId);
      },
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      className: 'delete-option',
      command: confirmSelection,
    },
  ];

  function confirmSelection() {
    const type = 'chat';
    showConfirmDialog({
      message: `Are you sure you want to delete this ${type}?`,
      header: `Delete ${type}`,
      icon: 'pi pi-exclamation-triangle',
      className: 'lazy-dialog-wrapper',
      acceptClassName: 'p-button-danger',
      accept,
      dismissableMask: true,
    });
  }

  const { showConfirmDialog } = useOverlay();
  const { deleteChatMutation, updateChatMutation } = useChatApi();
  const menuLeft = useRef(null);

  const accept = () => {
    const type = 'chat';

    deleteChatMutation
      .mutateAsync({ chatId: activeChatId || '' })
      .then(() => {
        toast?.current?.show({ severity: 'success', summary: 'Success', detail: `${type} deleted`, life: TOAST_DURATION });
        setActiveChatId('');
        navigate('/chat');
      })
      .catch(() => {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: `Failed to delete ${type}`, life: TOAST_DURATION });
      });
  };

  // Scrolls to active chat
  // const scrollToItem = () => {
  // Commenting out for now, as it's annoying
  // const activeChatElem = document.getElementsByClassName('selected-item');
  // if (activeChatElem && activeChatElem[0]) activeChatElem[0].scrollIntoView({ behavior: 'smooth' });
  // };

  // Middleware to show correct modal
  const modalMiddleware = (callback: () => void) => {
    if (authCheck()) callback();
    else showAuthModal('signup');
  };

  const showAuthModal = (type: 'signin' | 'signup') => {
    showDialog({
      content: <AuthModal navigate={(e: string) => navigate(e)} type={type} />,
      onHide: () => {},
      closeOnEscape: false,
    });
  };

  const showUserModal = () => {
    showDialog({
      header: () => {
        return (
          <div className="user-profile-modal-header">
            <Avatar
              image={user?.image}
              label={authCheck() ? `${user?.firstName.charAt(0)}${user?.lastName.charAt(0)}` : ''}
              className="user-profile-modal-avatar"
              icon={authCheck() ? '' : 'pi pi-user'}
              size="large"
            />
            <div className="user-profile-modal-title">
              {user?.firstName} {user?.lastName}
            </div>
          </div>
        );
      },
      content: <UserProfileModal />,
      onHide: () => {},
      closeOnEscape: false,
    });
  };

  // const showUpgradeModal = () => {
  //   showDialog({
  //     header: 'Upgrade',
  //     content: <UpgradeModal />,
  //     onHide: () => {},
  //   });
  // };

  const showReleaseLogsModal = () => {
    showDialog({
      header: 'Release Logs',
      content: <ReleaseLogsModal />,
      onHide: () => {},
    });
  };

  // Resize listener
  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const isExpanding = currentWidth > prevWidth;

      // Update the prevWidth for the next resize event
      setPrevWidth(currentWidth);

      // 'currentWidth' should be equal to '$screen-md'
      if (isExpanding && currentWidth >= 768) {
        const sidebarElem = document.getElementById('sidebar');

        if (sidebarElem && sidebarVisible) {
          // Add high z-index to prevent the sidebar from being hidden behind the overlay
          sidebarElem.classList.add('high-z-index');
        }

        setTimeout(() => {
          if (sidebarElem) {
            // Delay the removal of high z-index to prevent the sidebar from being hidden chat window
            sidebarElem.classList.remove('high-z-index');
          }
          // This should be equal to 'chat-section-wrapper' transition speed
        }, 500);
      }
    };

    // Add the resize event listener on mount
    window.addEventListener('resize', handleResize);

    // Remove the event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, [prevWidth]);

  const noSearchResultsFound = !filteredChatsLoading && !searchLoading && searchQuery.length > 0 && type === 'chat' && filteredChats?.length <= 0;
  const keyExtractor = (item: ApiResponse_Chat_Document_Props) => item._id;

  const flattenChats = Object.values(zChatListData) || []; // Flattens chat list data into a single array
  const activeTickers = flattenChats.find((item) => item._id === activeChatId)?.tickers || []; // Gets the active chat tickers
  const activeSidebarData = getExampeChips(activeTickers)[sidebarViewState as ChipBar_Key_Props]; // Used to show sidebar data with active chat tickers

  const lastDateLabelRef = useRef(''); // Used to store the last date label
  const chatsToDisplay = filteredChats?.length > 0 ? filteredChats : flattenChats; // Shows filtered chats if there are any, otherwise shows the normal chat list

  const renderRow = useCallback(
    (item: ApiResponse_Chat_Document_Props, index: number) => {
      const itemTimeFrame = getTimeAgo(new Date(item.sentLastMessageAt));
      let showDateHeader = false;

      // Check if the current date label is different from the last one stored
      if (itemTimeFrame !== lastDateLabelRef.current) {
        showDateHeader = true;
        lastDateLabelRef.current = itemTimeFrame; // Update the ref with the new date label
      }

      return (
        <SidebarHistoryListItem
          item={item}
          index={index}
          selectedId={activeChatId || ''}
          onClick={(id: string) => selectItem(id)}
          // @ts-ignore
          // eslint-disable-next-line
          onMenuClick={(event) => menuLeft.current?.toggle(event)}
          editingTitle={editingTitle}
          showTimeFrame={showDateHeader}
          timeFrame={itemTimeFrame}
          onTitleEdited={(newTitle: string | null) => {
            setEditingTitle(null);

            if (!newTitle) return;
            else {
              // Optimisically update the chat renaming. Reverts if call fails.
              const oldTitle = item.title;
              upsertChatList(item._id, { title: newTitle }); // Immediate (optimisic) update
              updateChatMutation.mutateAsync({ chatId: activeChatId || '', title: newTitle, isFavorite: false }).catch((e) => {
                upsertChatList(item._id, { title: oldTitle }); // Revert back to old title on error
                // eslint-disable-next-line
                toast?.current?.show({ severity: 'error', summary: 'Error', detail: BeautifyError(e), life: TOAST_DURATION });
              });
            }
          }}
        />
      );
    },
    [chatsToDisplay, editingTitle, activeChatId],
  );

  return (
    <>
      <div
        className="sidebar-mask"
        onClick={() => setSidebar(false)}
        style={{ pointerEvents: sidebarVisible ? 'all' : 'none', backgroundColor: sidebarVisible ? 'rgb(0 0 0 / .5)' : 'rgb(0 0 0 / 0)' }}
      />
      <div
        id="sidebar"
        className={`sidebar-wrapper ${sidebarVisible ? 'slide-in' : 'slide-out'}`}
        style={{ paddingTop: nativeInsets?.top || 'var(--spacing-md)', paddingBottom: nativeInsets?.bottom || 'var(--spacing-md)' }}
      >
        <SidebarButton
          text="Castello"
          customLeftIcon={<RenderSVG name={activeTheme === 'dark-theme' ? 'top_crown_white' : 'top_crown_black'} />}
          rightIcon="edit_square"
          rightIconOutline
          className="m-b-xs"
          onClick={() => modalMiddleware(createNewItem)}
        />

        <SidebarButton text="History" leftIcon="history" active={sidebarViewState === 'history'} onClick={() => setSidebarViewState('history')} />

        <ChipBar selected={sidebarViewState} onSelect={setSidebarViewState} />
        {sidebarViewState !== 'history' && <div className="sidebar-active-tickers xsmallMedium">{activeTickers?.toString().replaceAll(',', ', ')}</div>}

        {sidebarViewState === 'history' && authCheck() && <SidebarFilter type={type} value={searchQuery} onChange={setSearchQuery} loading={setSearchLoading} />}

        <div className="sidebar-content-wrapper">
          {sidebarViewState === 'history' ? (
            <div key="history">
              {noSearchResultsFound ? (
                <div className="no-chats-found-message smallRegular">No results found</div>
              ) : authCheck() ? (
                <InfiniteLoader
                  loadNewDataFrom="bottom"
                  pageEndLabel="No more chats"
                  isFetching={isFetching}
                  // Sorting because we are optimisitically updating the chat list after a user asks a question. We get this data after a stream ends, but this lets us update the chat order right away.
                  data={chatsToDisplay.sort((a, b) => new Date(b.sentLastMessageAt).getTime() - new Date(a.sentLastMessageAt).getTime())}
                  hasNextPage={hasNextPage}
                  renderRow={renderRow}
                  keyExtractor={keyExtractor}
                  // @ts-ignore
                  fetchNextPage={fetchNextPage}
                  className="sidebar-padding"
                />
              ) : (
                <div className="no-chats-found-message smallRegular">Sign up to view chat history</div>
              )}
            </div>
          ) : (
            <div key={sidebarViewState} className="sidebar-content-inner">
              <ChipBarData data={activeSidebarData} />
            </div>
          )}
        </div>

        {/* Upgrade Button */}
        {/* {!isPremiumUser && (
          <Button className="sidebar-button smallMedium" onClick={() => modalMiddleware(showUpgradeModal)} data-cy="upgrade-button">
            {billingText}
          </Button>
        )} */}

        <SidebarActionMenu data={userMenuItems} visible={menuVisible} toggleMenu={() => setMenuVisible(!menuVisible)} />
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />

        {!authCheck() ? (
          <SidebarButton className="m-t-sm" id={'userActionButton'} active text="Sign Up" leftIcon="login" onClick={() => showAuthModal('signup')} dataCy={'sidebar-signup-c2a-button'} />
        ) : (
          <SidebarButton
            className="m-t-sm"
            id={'userActionButton'}
            active={menuVisible}
            text={`${user?.firstName} ${user?.lastName}`}
            leftIcon="widgets"
            onClick={() => setMenuVisible(!menuVisible)}
            dataCy={'sidebar-user-action-button'}
          />
        )}
      </div>
    </>
  );
});

export default Sidebar;
