export const markdownError = `
# Something Went Wrong 😓

We're sorry, but we couldn't display the content as expected. Our team has been notified, and we'll check into this issue right away.

## What Can You Do?

- **Refresh** the page. Sometimes, a simple refresh resolves the issue.
- **Contact Support** if you need immediate assistance. We're here to help!

\`📧 Email Us\`: [help@castello.ai](mailto:help@castello.ai)

We appreciate your understanding and patience. Thank you for using our service, and we apologize for any inconvenience this may have caused.
`;