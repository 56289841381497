import { useMutation, useQueryClient } from 'react-query';
import { _getGraphs, _searchGraphs } from '../api';
import {
  ApiBody_Get_Chats_Props,
  ApiResponse_Dashboard_Graph_Item_Props,
  ApiResponse_Get_Graphs_Props,
  ApiResponse_Graph_Props,
  Component_ResponseBlockGraph_Props,
  Local_Graph_History_Props,
  Local_Graph_History_Update_Props,
  Local_Selected_Graph_Props,
  Query_Chat_Search_Props,
  initialFormattedGraphData,
  initialGraphHistory,
  logError,
  parseGraphs,
} from '../utils';

// ------------------ User API Hook ------------------
export const useGraph = () => {
  const queryClient = useQueryClient();

  // Fetch our Dashboard history
  const getGraphMutation = useMutation({
    mutationFn: ({ pageNumber }: ApiBody_Get_Chats_Props) => _getGraphs({ pageNumber }),
    onSuccess: ({ graphs }: ApiResponse_Get_Graphs_Props) => {
      console.log('getGraphMutation success: ', graphs);
      graphs.map((graph: ApiResponse_Dashboard_Graph_Item_Props) => {
        addToGraphMap(graph);
      });
    },
    onError: (e) => {
      logError(e, 'getGraphMutation');
    },
  });

  // Dashboard search function
  const graphSearchMutation = useMutation({
    mutationFn: ({ searchQuery, pageNumber }: Query_Chat_Search_Props) => _searchGraphs({ searchQuery, pageNumber }),
    onSuccess: ({ graphs }: ApiResponse_Get_Graphs_Props) => {
      console.log('graphSearchMutation success: ', graphSearchMutation);
      const filteredGraphsIds: string[] = [];
      graphs.map((graph: ApiResponse_Dashboard_Graph_Item_Props) => {
        addToGraphMap(graph);
        filteredGraphsIds.push(graph._id);
      });
      setFilteredGraphs(filteredGraphsIds);
    },
    onError: (e) => {
      logError(e, 'graphSearchMutation');
    },
  });

  const createFormattedGraphData = (incomingGraph: ApiResponse_Dashboard_Graph_Item_Props): Component_ResponseBlockGraph_Props => {
    const parsedGraph = {
      ...parseGraphs(incomingGraph.data as ApiResponse_Graph_Props),
      _id: incomingGraph._id,
      originalMessageId: incomingGraph.message._id,
    };

    return parsedGraph;
  };

  // Adds a chat to the map
  const addToGraphMap = (graph: ApiResponse_Dashboard_Graph_Item_Props) => {
    // console.log('addToGraphMap: ', { [graph._id]: graph });

    if (graph.data.type === 'graph') {
      // Normal Graph
      const formattedGraphData = createFormattedGraphData(graph);
      console.log('formattedGraphData: ', formattedGraphData);
      updateGraphHistory({ graphIdToUpdate: graph._id, graph, formattedGraphData });
    } else if (graph.data.type === 'tradingview') {
      // TradingView Graph
      console.log('tradingviewData: ', graph.data);
      updateGraphHistory({ graphIdToUpdate: graph._id, graph });
    }
  };

  function updateGraphHistory({ graphIdToUpdate, graph, formattedGraphData = initialFormattedGraphData }: Local_Graph_History_Update_Props) {
    const graphHistory = getGraphHistory();

    // console.log('updateGraphHistory 1: ', { graphIdToUpdate, graph, formattedGraphData });

    // Update the chatHistory with the new arrays
    const updatedGraphHistory = {
      ...graphHistory,
      [graphIdToUpdate]: {
        ...graphHistory[graphIdToUpdate],
        graph: graph || graphHistory[graphIdToUpdate].graph,
        formattedGraphData: formattedGraphData || graphHistory[graphIdToUpdate].formattedGraphData,
      },
    };

    // console.log('updateGraphHistory 2: ', updatedGraphHistory);

    // Set the updated chat history
    setGraphHistory(updatedGraphHistory);
  }

  const getGraphHistory = (): Local_Graph_History_Props => {
    return queryClient.getQueryData('graphHistory') || initialGraphHistory;
  };

  // Sets the dashboard history
  const setGraphHistory = (graphHistory: Local_Graph_History_Props) => {
    // console.log('new graph history: ', graphHistory);
    queryClient.setQueryData('graphHistory', graphHistory);
  };

  // Returns filtered dashboard data
  const getFilteredGraphs = (): string[] => {
    return queryClient.getQueryData('filteredGraphs') || [];
  };

  // Set filtered dashboard data
  const setFilteredGraphs = (filteredGraphs: string[]) => {
    return queryClient.setQueryData('filteredGraphs', filteredGraphs);
  };

  // Returns filtered dashboard data
  const getSelectedGraphs = (): Local_Selected_Graph_Props => {
    return queryClient.getQueryData('selectedGraphs') || {};
  };

  // Set filtered dashboard data
  const setSelectedGraphs = (selectedGraphs: Local_Selected_Graph_Props) => {
    return queryClient.setQueryData('selectedGraphs', selectedGraphs);
  };

  // Reset the graph queries
  const resetGraph = () => {
    queryClient.setQueryData('filteredGraphs', []);
    queryClient.setQueryData('selectedGraphs', {});
  };

  return {
    getGraphMutation,
    graphSearchMutation,
    addToGraphMap,
    getGraphHistory,
    setGraphHistory,
    getFilteredGraphs,
    setFilteredGraphs,
    getSelectedGraphs,
    setSelectedGraphs,
    resetGraph,
  };
};
