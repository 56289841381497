import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { ConfirmDialog, confirmDialog, ConfirmDialogProps } from 'primereact/confirmdialog';
import { Dialog, DialogProps } from 'primereact/dialog';

interface ExtendedDialogProps extends DialogProps {
  content: React.ReactNode;
  closeOnEscape?: boolean;
  onHide: () => void;
}

// Creating a combined context
const OverlayContext = createContext({
  // eslint-disable-next-line
  showConfirmDialog: (options: ConfirmDialogProps) => {},
  // eslint-disable-next-line
  showDialog: (options: ExtendedDialogProps) => {},
  hideDialog: () => {},
});

export const useOverlay = () => useContext(OverlayContext);

export const OverlayProvider = ({ children }: { children: React.ReactNode }) => {
  const [dialogProps, setDialogProps] = useState<ExtendedDialogProps | null>(null);

  const showConfirmDialog = (options: ConfirmDialogProps) => {
    confirmDialog(options);
  };

  const showDialog = (options: ExtendedDialogProps) => {
    const onHide = options.onHide ? options.onHide : () => hideDialog();
    const closeOnEscape = typeof options.closeOnEscape !== 'undefined' ? options.closeOnEscape : true;
    setDialogProps({ ...options, visible: true, closeOnEscape, dismissableMask: true, onHide });
  };

  const hideDialog = useCallback(() => {
    if (dialogProps?.onHide) {
      dialogProps.onHide(); // Execute custom onHide function if provided
    }
    setDialogProps((prev) => (prev ? { ...prev, visible: false } : null));
  }, [dialogProps]);

  // Effect to clean up dialogProps after it has been hidden
  useEffect(() => {
    if (dialogProps && !dialogProps.visible) {
      // Delay the cleanup to ensure onHide has time to execute before cleanup
      const timer = setTimeout(() => {
        setDialogProps(null);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [dialogProps]);

  return (
    <OverlayContext.Provider value={{ showConfirmDialog, showDialog, hideDialog }}>
      <ConfirmDialog dismissableMask={true} />
      {dialogProps && (
        <Dialog {...dialogProps} onHide={hideDialog} draggable={false}>
          {dialogProps.content}
        </Dialog>
      )}
      {children}
    </OverlayContext.Provider>
  );
};
