export const positiveAdjectives = [
  'Amazing',
  'Astonishing',
  'Astounding',
  'Spectacular',
  'Stunning',
  'Remarkable',
  'Incredible',
  'Extraordinary',
  'Fantastic',
  'Tremendous',
  'Phenomenal',
  'Marvelous',
  'Fabulous',
  'Superb',
  'Splendid',
  'Magnificent',
  'Grand',
  'Impressive',
  'Formidable',
  'Outstanding',
  'Excellent',
  'Great',
  'Sublime',
  'Super',
  'Cool',
  'Brilliant',
  'Dazzling',
  'Majestic',
  'Epic',
  'Enchanting',
  'Charming',
  'Enthralling',
  'Ravishing',
  'Divine',
  'Exquisite',
  'Delightful',
  'Beautiful',
  'Gorgeous',
  'Radiant',
  'Glorious',
  'Regal',
  'Royal',
  'Noble',
  'Grandiose',
];
