import { useMutation, useQueryClient } from 'react-query';
import { useAuth, useChatApi, useDashboardApi, useGraph } from '.';
import { getAuth, signOut } from 'firebase/auth';
import { clearSentryUser } from '../services/sentry';
import { useToast } from '../providers';
import { TOAST_DURATION } from '../utils/constants';
import { Local_Authentication_Status_Props, logError } from '../utils';
import { getStorageItem, removeStorageItem, setStorageItem, storageItems } from '../utils/localStorage';

/**
 * This file should contain common hooks that are used across multiple components.
 *
 * Some hooks can't use others due to heiarachy, so they are imported here and used in the hooks below.
 * Example: Auth hooks can't use Chat hooks, but Chat hooks can use Auth hooks.
 *
 * Don't use any 'setQueryData' calls here, unless we create a provider that is always set as the lowest level provider in the app.
 */

// ------------------ Common API Hook ------------------
export const useCommon = () => {
  const queryClient = useQueryClient();

  const toast = useToast();
  const { resetGraph } = useGraph();
  const { resetChat } = useChatApi();
  const { resetDashboard } = useDashboardApi();
  const { getUser, setUser, resetUser } = useAuth();

  // Local storage items to preserve on sign-out
  const localStorageItemsToPreserve = [storageItems.theme.key, storageItems.nativeInsets.key, storageItems.isNativeApp.key, storageItems.guestId.key];

  // Handle full sign out process. Should remove all traces of a user's authentication and data
  const fullSignOut = (): Promise<boolean> => {
    return signOut(getAuth())
      .then(() => {
        clearSentryUser();
        setUser(null);

        // Clear local storage items (except for ones present in 'localStorageItemsToPreserve')
        // eslint-disable-next-line
        Object.entries(storageItems).forEach(([key, item]) => {
          // If the key of the current item is not in the list of keys to preserve, remove it
          if (!localStorageItemsToPreserve.includes(item.key)) {
            removeStorageItem(item); // item is of type StorageItem<T>, matching removeStorageItem's parameter type
          }
        });

        // To Do: Remove these 'reset' calls
        // Clear React Query data
        resetChat();
        resetUser();
        resetDashboard();
        resetGraph();
        queryClient.resetQueries(); // Clear RQ all queries

        return true;
      })
      .catch((e) => {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'There was an error when logging out.', life: TOAST_DURATION });
        logError(e, 'fullSignOut');
        return false;
      });
  };

  // React Query mutation for full sign out
  const fullSignOutMutation = useMutation({
    mutationFn: fullSignOut,
    // eslint-disable-next-line
    onSuccess: async (signedOut) => {
      return signedOut || false;
    },
    onError: (e) => {
      logError(e, 'fullSignOutMutation');
    },
  });

  // To Do: Move to Auth Hook?
  const getUserAuthStatus = (): Local_Authentication_Status_Props => {
    const user = getUser();
    const guestId = getStorageItem(storageItems.guestId);
    const authenticationStatus: Local_Authentication_Status_Props = user ? 'authenticated' : guestId ? 'guest' : 'none';
    return authenticationStatus;
  };

  const setOnlineStatus = (online: boolean) => {
    queryClient.setQueryData('online', online);
  };

  const getOnlineStatus = (): boolean => {
    const value = queryClient.getQueryData('online');
    return value !== undefined ? Boolean(value) : true;
  };

  const setFollowupQuestionsVisible = (visible: boolean) => {
    queryClient.setQueryData('followupQuestionsVisible', visible);
    setStorageItem(storageItems.followupQuestionsVisible, visible);
  };

  const getFollowupQuestionsVisible = (): boolean => {
    const localStorageVal = getStorageItem(storageItems.followupQuestionsVisible);
    return queryClient.getQueryData('followupQuestionsVisible') || (localStorageVal === 'true' ? true : false);
  };

  return { fullSignOutMutation, fullSignOut, getUserAuthStatus, setOnlineStatus, getOnlineStatus, setFollowupQuestionsVisible, getFollowupQuestionsVisible };
};
