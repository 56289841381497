import { memo, useEffect, useRef } from 'react';
import { ExampleQuestionItemProps, Local_Send_Chat_Error_Props } from '../../utils';
import { useChatApiV2 } from '../../hooks';
import { useParams } from 'react-router-dom';
import { useToast } from '../../providers';
import { TOAST_DURATION } from '../../utils/constants';
import GoogleIcon from '../google-icon';
import './index.scss';

export const QuestionPromptBlock = memo(({ title, subtitle, icon }: ExampleQuestionItemProps) => {
  const params = useParams<{ id: string }>();
  const toast = useToast();
  const { sendChat } = useChatApiV2();

  const myRef = useRef<HTMLDivElement>(null);

  // Removes the animation class after the animation is complete
  useEffect(() => {
    if (myRef.current) {
      myRef.current.addEventListener('animationend', () => {
        if (myRef.current) {
          myRef.current.classList.remove('question-block-mount-animation');
        }
      });
    }
  }, []);

  const handleClick = () => {
    sendChat({ prompt: `${title} ${subtitle}`, chatId: params.id }).catch((error: Local_Send_Chat_Error_Props) => {
      toast?.current?.show({ severity: 'warn', summary: error.title, detail: error.message, life: TOAST_DURATION });
    });
  };

  return (
    <div ref={myRef} onClick={handleClick} data-cy="example-question" className={`question-block-prompt-wrapper question-block-mount-animation no-select`}>
      <div className="question-block-icon" data-cy="example-question-icon">
        <GoogleIcon name={icon} />
      </div>

      <div className="question-block-content">
        <div className="question-block-title smallBold" data-cy="example-question-title">
          {title}
        </div>
        <div className="question-block-subtitle smallRegular" data-cy="example-question-subtitle">
          {subtitle}
        </div>
      </div>
    </div>
  );
});
