import { useQueryClient } from 'react-query';
import { AppThemes, initialTheme } from '../utils';
import { useContext } from 'react';
import { PrimeReactContext } from 'primereact/api';
import { getStorageItem, setStorageItem, storageItems } from '../utils/localStorage';

// ------------------ Theme Hook ------------------
export const useTheme = () => {
  const queryClient = useQueryClient();

  // eslint-disable-next-line
  const { changeTheme } = useContext(PrimeReactContext);

  // Toggles the app theme
  const toggleTheme = (): boolean => {
    const oldTheme: AppThemes = getTheme();
    const newTheme: AppThemes = oldTheme === 'dark-theme' ? 'light-theme' : 'dark-theme';
    return applyTheme({ oldTheme, newTheme });
  };

  // Set the app theme
  const setTheme = (newTheme: AppThemes) => {
    const oldTheme: AppThemes = newTheme === 'dark-theme' ? 'light-theme' : 'dark-theme';
    applyTheme({ oldTheme, newTheme });
    return true;
  };

  // Apply the app theme
  const applyTheme = ({ oldTheme, newTheme }: { oldTheme: AppThemes; newTheme: AppThemes }) => {
    checkForDuplicateIds();
    if (changeTheme) {
      changeTheme(oldTheme, newTheme, 'theme-link');
      setStorageItem(storageItems.theme, newTheme);
      queryClient.setQueryData('theme', newTheme);
      return true;
    }
    return false;
  };

  // Not sure why, but 'changeTheme' adds duplicate ids to the DOM for `theme-link`
  const checkForDuplicateIds = () => {
    const foundElements = document.querySelectorAll('#theme-link');
    if (foundElements && foundElements.length > 1) {
      foundElements.forEach((element, index) => {
        if (index > 0) element.remove();
      });
    }
  };

  // Returns app theme
  const getTheme = (): AppThemes => {
    let theme: AppThemes = getStorageItem(storageItems.theme) as AppThemes;

    // If no theme found, set one
    if (!theme) {
      theme = initialTheme;
      setTheme(theme);
    }

    return theme;
  };

  return { getTheme, setTheme, toggleTheme };
};
