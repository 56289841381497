// Screens
import Chat from '../screens/chat';
import ErrorScreen from '../screens/error-screen';
import EmailVerification from '../screens/email-verification';
import Verify from '../screens/verify';
import ResetPassword from '../screens/reset-password';
import PaymentFeedback from '../screens/payment-feedback';
import Dashboard from '../screens/dashboard-screen';
import OnboardingScreen from '../screens/onboarding';
import Welcome from '../screens/welcome';

// Imports
import { useLocation, Navigate, createBrowserRouter } from 'react-router-dom';
import { useAuthApi, useToast } from '../providers';
import { useSidebar, useTheme } from '../hooks';
import { TOAST_DURATION } from '../utils/constants';
import NotFoundScreen from '../screens/not-found-screen';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import LoadingScreen from '../screens/loading-screen';
import { useQuery } from 'react-query';
import { setStorageItem, storageItems } from '../utils/localStorage';

// Wrap protected routes with this
function Protected({ children }: { children: JSX.Element }) {
  const auth = useAuthApi();
  const location = useLocation();

  // const completedWelcomeScreens = getStorageItem(storageItems.completedWelcomeScreens);
  const completedWelcomeScreens = true; // Temporary bypass for welcome screens, requested by Zain

  // If user doesn't have auth, redirect to home
  if (!completedWelcomeScreens && !auth.user) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // Stop guests from getting into 'dashboard'
  if (!auth.user && location.pathname.includes('/dashboard')) {
    return <Navigate to="/chat" state={{ from: location }} replace />;
  }

  // User has auth, but hasn't verified their email
  if (auth.user && !auth.user.emailVerified) {
    return <Navigate to="/email-verification" state={{ from: location }} replace />;
  }

  // If user has auth, but hasn't accepted TOS
  if (auth.user && (!auth.user.betaUseAccepted || !auth.user.tosAccepted)) {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  // Temporarily redirect to chat if user is trying to access dashboard. Remove this once dashboards is active.
  if (auth.user && location.pathname.includes('/dashboard')) {
    return <Navigate to="/chat" state={{ from: location }} replace />;
  }

  return children;
}

// Wrap routes that need to wait for some loading to finish
function LoadingMiddleware({ children }: { children: JSX.Element }) {
  const { getSidebar } = useSidebar();
  const { getTheme } = useTheme();

  const { data: sidebarVisible } = useQuery('sidebar', getSidebar);
  const { data: activeTheme } = useQuery('theme', getTheme);

  const [requiredItemsLoaded, setRequiredItemsLoaded] = useState(false);

  useEffect(() => {
    if (sidebarVisible !== undefined && activeTheme) {
      setRequiredItemsLoaded(true);
    }
  }, [sidebarVisible, activeTheme]);

  if (requiredItemsLoaded) return children;
  else return <LoadingScreen />;
}

// Wrap protected routes with this
function Middleware({ children }: { children: JSX.Element }) {
  const auth = useAuthApi();
  const location = useLocation();
  const toast = useToast();

  // const completedWelcomeScreens = getStorageItem(storageItems.completedWelcomeScreens);
  const completedWelcomeScreens = true; // Temporary bypass for welcome screens, requested by Zain

  const welcomePages = location.pathname === '/';
  const TOSAccepted = auth.user && auth.user.betaUseAccepted && auth.user.tosAccepted;
  const completedUser = auth.user && auth.user.emailVerified && TOSAccepted;

  // If user has auth, don't make them do welcome screen if it's somehow not in local storage
  if (!completedWelcomeScreens && completedUser) {
    setStorageItem(storageItems.completedWelcomeScreens, true);
  }

  if (location.pathname.includes('/chat')) {
    const urlSplit = location.pathname.split('/');
    if (urlSplit[2] && urlSplit[2].length !== 24) {
      // To Do: This isn't rendering for some reason, even though it's being hit.
      toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'No chat found matching that URL', life: TOAST_DURATION });
      Sentry.captureException(`No chat found matching that URL: ${window?.location?.href}`);
      return <Navigate to="/chat" />;
    }
  }

  // User has completed welcome screens, but tries to access them again
  if (completedWelcomeScreens && location.pathname === '/') {
    return <Navigate to="/chat" state={{ from: location }} replace />;
  }

  // User doesn't has auth, but hasn't verified their email
  if (!auth.user && location.pathname === '/onboarding') {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // User has auth, but hasn't verified their email
  if (auth.user && !auth.user.emailVerified && location.pathname !== '/email-verification' && location.pathname !== '/verify') {
    return <Navigate to="/email-verification" state={{ from: location }} replace />;
  }

  // If user has verified email, but hasn't accepted TOS
  if (auth.user && auth.user.emailVerified && !TOSAccepted && location.pathname !== '/onboarding') {
    return <Navigate to="/onboarding" state={{ from: location }} replace />;
  }

  // If user already verified email, redirect to chat.
  if (auth.user?.emailVerified && location.pathname === '/email-verification') {
    return <Navigate to="/chat" state={{ from: location }} replace />;
  }

  // If the user has auth, but tries to access welcome pages
  if (completedUser && welcomePages) {
    return <Navigate to="/chat" state={{ from: location }} replace />;
  }

  // Auth user is trying to access onboarding
  if (completedUser && location.pathname === '/onboarding') {
    return <Navigate to="/chat" state={{ from: location }} replace />;
  }

  // Auth user is trying to access onboarding
  // if (!auth.user && location.pathname === '/onboarding') {
  //   return <Navigate to="/sign-in" state={{ from: location }} replace />;
  // }

  return children;
}

const routes = [
  {
    path: '/',
    element: (
      <Middleware>
        <Welcome />
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/onboarding',
    element: (
      <Middleware>
        <OnboardingScreen />
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/reset-password',
    element: (
      <Middleware>
        <ResetPassword />
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/email-verification',
    element: (
      <Middleware>
        <EmailVerification />
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/verify',
    element: (
      <Middleware>
        <Verify />
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/post-purchase',
    element: (
      <Middleware>
        <Protected>
          <PaymentFeedback />
        </Protected>
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/chat',
    element: (
      <Middleware>
        <Protected>
          <LoadingMiddleware>
            <Chat />
          </LoadingMiddleware>
        </Protected>
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/chat/:id',
    element: (
      <Middleware>
        <Protected>
          <LoadingMiddleware>
            <Chat />
          </LoadingMiddleware>
        </Protected>
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/dashboard',
    element: (
      <Middleware>
        <Protected>
          <LoadingMiddleware>
            <Dashboard />
          </LoadingMiddleware>
        </Protected>
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/dashboard/:id',
    element: (
      <Middleware>
        <Protected>
          <LoadingMiddleware>
            <Dashboard />
          </LoadingMiddleware>
        </Protected>
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/error',
    element: (
      <Middleware>
        <ErrorScreen />
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '/support',
    element: (
      <Middleware>
        <div>
          Email us: <a href="mailto:help@castello.ai">help@castello.ai</a>
        </div>
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
  {
    path: '*',
    element: (
      <Middleware>
        <NotFoundScreen />
      </Middleware>
    ),
    errorElement: <ErrorScreen />,
  },
];

// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/#usage-with-react-router-64-data-api
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(routes);

export default router;
