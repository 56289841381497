import { memo } from 'react';
import { useQuery } from 'react-query';
import { useAuth, useCommon } from '../../hooks';
import { useOverlay } from '../../providers';
import './index.scss';

export const UserProfileModal = memo(() => {
  const { getUser, deleteUserMutation } = useAuth();
  const { getUserAuthStatus, fullSignOutMutation } = useCommon();

  const authStatus = getUserAuthStatus();
  const { data: user } = useQuery('user', getUser);
  const { hideDialog, showConfirmDialog } = useOverlay();

  const getUserSubscriptionStatusText = () => {
    if (Boolean(user?.subscriptionStatus)) {
      if (user?.subscriptionStatus === 'active') return 'Active Subscription';
      if (user?.subscriptionStatus === 'incomplete') return 'Incomplete';
      else if (user?.subscriptionStatus === 'incomplete_expired') return 'Expired';
      else if (user?.subscriptionStatus === 'trialing') return 'Trialing';
      else if (user?.subscriptionStatus === 'past_due') return 'Active Subscription (Past Due)';
      else if (user?.subscriptionStatus === 'canceled') return 'No Subscription';
      else if (user?.subscriptionStatus === 'unpaid') return 'Active Subscription (Unpaid)';
      else if (user?.subscriptionStatus === 'paused') return 'Paused';
      else return user?.subscriptionStatus;
    } else return 'No Subscription';
  };

  function confirmSelection() {
    showConfirmDialog({
      message: `Are you sure you want to delete your account? This action cannot be undone.`,
      header: `Delete Account`,
      icon: 'pi pi-exclamation-triangle',
      className: 'lazy-dialog-wrapper',
      acceptClassName: 'p-button-danger',
      accept,
      dismissableMask: true,
      closeOnEscape: true,
    });
  }

  function accept() {
    deleteUserMutation.mutateAsync().then((data) => {
      if (data.success) {
        hideDialog();
        fullSignOutMutation.mutateAsync().then((signedOut) => {
          if (signedOut) window.location.reload();
        });
      }
    });
  }

  return (
    <div className="common-modal user-profile-modal-wrapper">
      <div className="item-wrapper">
        <div className="item-title smallBold">Name:</div>
        <div className="item-value smallRegular">{authStatus === 'authenticated' ? `${user?.firstName} ${user?.lastName}` : authStatus === 'guest' ? 'Guest User' : ''}</div>
      </div>

      <div className="item-wrapper">
        <div className="item-title smallBold">Email:</div>
        <div className="item-value smallRegular">{authStatus === 'authenticated' ? `${user?.email}` : authStatus === 'guest' ? 'N/A' : ''}</div>
      </div>

      <div className="item-wrapper">
        <div className="item-title smallBold">Subscription:</div>
        <div className="item-value smallRegular">{authStatus === 'authenticated' ? getUserSubscriptionStatusText() : 'No Subscription'}</div>
      </div>

      <div className="footer">
        <div className={`item-value smallRegular delete-account ${deleteUserMutation.isLoading ? '' : ' clickable'}`} onClick={() => confirmSelection()}>
          {deleteUserMutation.isLoading ? 'Deleting Account...' : 'Delete Account'}
        </div>
      </div>
    </div>
  );
});
