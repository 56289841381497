import { Chip_Props } from '../utils';
import { investIcon, learnIcon, tradeIcon } from '../utils/constants';

function formatSymbols(symbols: string[]): string {
  return symbols.toString().replaceAll(',', ', ') as string;
}

export function getExampeChips(symbols: string[]): Chip_Props {
  if (!symbols || symbols.length <= 0) symbols = ['AAPL'];

  const exampleQuestions: Chip_Props = {
    // Get backend support for this. This should be a dynamic list in in of itself.
    // trending: [
    //   {
    //     title: "Amazon's Green Card Sponsorship Pause",
    //     text: "Explore the potential consequences of Amazon's recent decision to suspend green card sponsorship for its international employees",
    //     icon: 'grain',
    //   },
    //   {
    //     title: "Xiaomi's EV Market Entry",
    //     text: "Analyze Xiaomi's new venture into the electric vehicle sector and its effects on industry competition",
    //     icon: 'grain',
    //   },
    //   {
    //     title: 'Meta Platforms AI Adoption',
    //     text: "Evaluate how Meta Platforms' integration of artificial intelligence influences its stock prices and long-term expansion plans",
    //     icon: 'grain',
    //   },
    //   {
    //     title: "IBM's HashiCorp Acquisition",
    //     text: "Examine the long-term benefits for IBM's AI and cloud divisions following its takeover of HashiCorp",
    //     icon: 'grain',
    //   },
    //   {
    //     title: "Tesla's Robotaxi Vision",
    //     text: "Consider the practicality and industry influence of Tesla's robotaxi concept amidst doubt from competitors",
    //     icon: 'grain',
    //   },
    // ],
    trade: [
      {
        title: 'Detailed Technical Analysis',
        text: `Analyze key technical indicators like moving averages, RSI, and MACD to identify potential trading opportunities for ${formatSymbols(symbols)}`,
        icon: tradeIcon,
      },
      {
        title: 'Trading Volume Analysis',
        text: `Compare current trading volumes for ${formatSymbols(symbols)} with historical data to identify trends and patterns`,
        icon: tradeIcon,
      },
      {
        title: 'Fundamental Analysis',
        text: `Conduct a comprehensive review of ${formatSymbols(symbols)}'s financial health, including revenue, earnings, and cash flow metrics`,
        icon: tradeIcon,
      },
      {
        title: 'Fibonacci Retracement Levels',
        text: `Utilize Fibonacci Retracement techniques to identify key support and resistance levels for ${formatSymbols(symbols)}`,
        icon: tradeIcon,
      },
      {
        title: 'Geopolitical Event Impact',
        text: `Investigate how recent geopolitical events might impact the pricing and market volatility of ${formatSymbols(symbols)}`,
        icon: tradeIcon,
      },
    ],
    invest: [
      {
        title: 'Long-Term Growth Potential',
        text: `Evaluate ${formatSymbols(symbols)}'s long-term growth potential by analyzing industry trends and competitive dynamics`,
        icon: investIcon,
      },
      {
        title: 'Innovation and R&D',
        text: `Examine ${formatSymbols(symbols)}'s focus on innovation and R&D to assess future growth prospects`,
        icon: investIcon,
      },
      {
        title: 'Dividend Analysis',
        text: `Analyze the dividend yield, payout consistency, and growth history of ${formatSymbols(symbols)}`,
        icon: investIcon,
      },
      {
        title: 'Price-To-Earnings Ratio Analysis',
        text: `Explore the implications of ${formatSymbols(symbols)}'s Price-to-Earnings (P/E) ratio for long-term investment strategies`,
        icon: investIcon,
      },
      {
        title: 'Diversification with Non-Correlated Assets',
        text: `Discuss the benefits of adding non-correlated assets to a diversified portfolio that includes ${formatSymbols(symbols)}`,
        icon: investIcon,
      },
    ],
    learn: [
      {
        title: 'Comprehensive Stock Overview',
        text: `Get a detailed overview of ${formatSymbols(symbols)}, including its business model, market position, and financial health`,
        icon: learnIcon,
      },
      {
        title: 'Impactful News Review',
        text: `Review major news and events that have recently influenced the market performance of ${formatSymbols(symbols)}`,
        icon: learnIcon,
      },
      {
        title: 'Risk Assessment Profile',
        text: `Compare ${formatSymbols(symbols)} with industry competitors to identify potential investment risks`,
        icon: learnIcon,
      },
      {
        title: 'Support and Resistance',
        text: `Learn the importance of identifying support and resistance levels for ${formatSymbols(symbols)} to enhance trading strategies`,
        icon: learnIcon,
      },
      {
        title: 'Principles of Value Investing',
        text: `Understand the key principles of value investing and how to apply them to evaluate the true market value of ${formatSymbols(symbols)}`,
        icon: learnIcon,
      },
    ],
  };

  return exampleQuestions;
}
