// TODO: Find an API that will give us this data instead
export const companyList = [
  'Apple',
  'Microsoft',
  'Amazon',
  'Alphabet',
  'Facebook',
  'Tesla',
  'Berkshire Hathaway',
  'Visa',
  'Johnson & Johnson',
  'Walmart',
  'JPMorgan Chase',
  'Procter & Gamble',
  'NVIDIA',
  'UnitedHealth Group',
  'Home Depot',
  'Mastercard',
  'Bank of America',
  'Pfizer',
  'Intel',
  'Verizon',
  'Coca-Cola',
  'AT&T',
  'PepsiCo',
  'Netflix',
  'Disney',
  'PayPal',
  'Exxon Mobil',
  'Cisco',
  'Nike',
  'Adobe',
  'Comcast',
  'Chevron',
  'AbbVie',
  'Salesforce',
  'Oracle',
  'Thermo Fisher Scientific',
  'Broadcom',
  'Costco',
  'Abbott',
  'Medtronic',
  'T-Mobile',
  'Union Pacific',
  'NextEra Energy',
  'Honeywell',
  'Qualcomm',
  'Texas Instruments',
  'American Tower',
  'Eli Lilly',
  'Philip Morris',
  'Citigroup',
  'Amgen',
  'Bristol-Myers Squibb',
  'Starbucks',
  '3M',
  'United Parcel Service',
  'Morgan Stanley',
  'Lockheed Martin',
  'Caterpillar',
  'Goldman Sachs',
  'Anthem',
  'Intuitive Surgical',
  'Charles Schwab',
  'Stryker',
  'Chubb',
  'ServiceNow',
  'Deere',
  'Prologis',
  'American Express',
  'CVS Health',
  'Illinois Tool Works',
  'General Electric',
  'Northrop Grumman',
  'Mondelez',
  'General Motors',
  'Boeing',
  'PNC Financial Services',
  'Gilead Sciences',
  'Cigna',
  'ConocoPhillips',
  'Danaher',
  'Schlumberger',
  'American International Group',
  'Micron Technology',
  'FedEx',
  'Booking Holdings',
  'Norfolk Southern',
  'BlackRock',
  'Automatic Data Processing',
  'CME Group',
  'Crown Castle',
  'Altria',
  'Eaton',
  'Colgate-Palmolive',
  "McDonald's",
  'Raytheon Technologies',
  'Dominion Energy',
  'Biogen',
  'Linde',
  'Zoetis',
  'Johnson Controls',
  'Prudential Financial',
  'HCA Healthcare',
  'Consolidated Edison',
  'Autodesk',
  'L3Harris Technologies',
  'KLA',
  'Lam Research',
  'Parker-Hannifin',
  "Lowe's",
  'Marriott International',
  'NXP Semiconductors',
  'Analog Devices',
  'AvalonBay Communities',
  'Edwards Lifesciences',
  'Regeneron Pharmaceuticals',
  'Alexion Pharmaceuticals',
  'LyondellBasell',
  'Duke Energy',
  'Nucor',
  'Waste Management',
  'Williams',
  'T. Rowe Price',
  'Public Service Enterprise Group',
  'Simon Property Group',
  'Kroger',
  'Southern Company',
  'Rockwell Automation',
  'American Water Works',
  'Baxter International',
  'Agilent Technologies',
  'Yum! Brands',
  'ViacomCBS',
  'Ball',
  'Xcel Energy',
  'Eversource Energy',
  'International Flavors & Fragrances',
  'Paycom Software',
  'Arthur J. Gallagher',
  'Clorox',
  'McKesson',
  'Expedia Group',
  'Constellation Brands',
  'Cardinal Health',
  'Motorola Solutions',
  'Western Digital',
  'Mettler-Toledo',
  'Cintas',
  'MarketAxess',
  'Vulcan Materials',
  'Archer Daniels Midland',
  'CMS Energy',
  'Dover',
  'IDEX',
  'Eastman Chemical',
  'Edison International',
  'Expeditors International',
  'First Solar',
  'Hershey',
  'Hartford Financial Services',
  'Humana',
  'Intercontinental Exchange',
  'Intuit',
  'Kansas City Southern',
  'Kimberly-Clark',
  'Kraft Heinz',
  'Lincoln National',
  'Loews',
  "Macy's",
  'Marsh & McLennan',
  'MetLife',
  'MGM Resorts',
  'Mohawk Industries',
  'Mosaic',
  'MSCI',
  'Noble Energy',
  'Northern Trust',
  'Norwegian Cruise Line',
  'Occidental Petroleum',
  'Oneok',
  'Pacific Gas and Electric',
  "People's United Financial",
  'Pfizer',
  'Principal Financial Group',
  'PulteGroup',
  'Raymond James Financial',
  'Republic Services',
  'Ross Stores',
  'S&P Global',
  'Sempra Energy',
  'Sonic Automotive',
  'S&P Global',
  'Stanley Black & Decker',
  'Tapestry',
  'Tractor Supply',
  'Tyson Foods',
  'UDR',
  'Ulta Beauty',
  'U.S. Bancorp',
  'Vertex Pharmaceuticals',
  'WEC Energy Group',
  'Whirlpool',
  'Williams-Sonoma',
  'Willis Towers Watson',
  'Zimmer Biomet',
  'Zions Bancorporation',
  'Zscaler',
  'D.R. Horton',
  'Darden Restaurants',
  'DaVita',
  'Deere',
  'Dollar General',
  'Dollar Tree',
  'Duke Realty',
  'DuPont',
  'Enbridge',
  'Enphase Energy',
  'Entegris',
  'EOG Resources',
  'EPAM Systems',
  'Equity Residential',
  'Equinix',
  'Essex Property Trust',
  'Evergy',
  'Everest Re Group',
  'Exact Sciences',
  'Exelon',
  'Expeditors International',
  'ExxonMobil',
  'FactSet',
  'Fastenal',
  'Federal Realty',
  'Fidelity National Information Services',
  'Fifth Third Bancorp',
  'First Republic Bank',
  'FirstEnergy',
  'Fiserv',
  'FleetCor Technologies',
  'FMC',
  'Ford',
  'Fox Corporation',
  'Franklin Resources',
  'Freeport-McMoRan',
  'Gartner',
  'Gen Digital',
  'General Dynamics',
  'General Mills',
  'Genuine Parts',
  'Globe Life',
  'Globus Medical',
  'Graham Holdings',
  'Hanesbrands',
  'HCA Healthcare',
  'Hewlett Packard Enterprise',
  'Hilton Worldwide',
  'Hologic',
  'Home Depot',
  'Honeywell',
  'Hormel Foods',
  'Host Hotels & Resorts',
  'HP',
  'Huntington Ingalls Industries',
  'Huntington Bancshares',
  'Hyatt Hotels',
  'IBM',
  'Icahn Enterprises',
  'ICU Medical',
  'IDEXX Laboratories',
  'Illinois Tool Works',
  'Incyte',
  'Ingredion',
  'Insulet',
  'Intel',
  'Interpublic Group',
  'Invesco',
  'Ionis Pharmaceuticals',
  'IQVIA',
  'Jack Henry & Associates',
  'Jacobs Engineering',
  'Jefferies Financial Group',
  'JetBlue Airways',
  'Johnson & Johnson',
  'Jones Lang LaSalle',
  'JPMorgan Chase',
  'Juniper Networks',
  'Kansas City Southern',
  'Kellogg',
  'KeyCorp',
  'Keysight Technologies',
  'Kimco Realty',
  'Kinder Morgan',
  'Kirkland Lake Gold',
  'Knight-Swift Transportation',
  "Kohl's",
  'Kroger',
  'Laboratory Corporation of America',
  'Lamb Weston Holdings',
  'Las Vegas Sands',
  'Leggett & Platt',
  'Leidos',
  'Lennar',
  'Lennox International',
  'Levi Strauss',
  'Liberty Global',
  'Lincoln National',
  'Linde',
  'Live Nation Entertainment',
  'LKQ',
  'Lockheed Martin',
  'Loews',
  "Lowe's",
  'Lumen Technologies',
  'LyondellBasell',
  'M&T Bank',
  'Marathon Petroleum',
  'Marriott International',
  'Martin Marietta Materials',
  'Masco',
  'Mastercard',
  'Maxim Integrated Products',
  'McCormick & Company',
  'McKesson',
  'Medtronic',
  'Merck',
  'Meta Platforms',
  'MetLife',
  'MGM Resorts',
  'Microchip Technology',
  'Micron Technology',
  'Microsoft',
  'Mid-America Apartment Communities',
  'Mohawk Industries',
  'Molson Coors Beverage',
  'Mondelez',
  'MongoDB',
  "Moody's",
  'Morgan Stanley',
  'Motorola Solutions',
  'MSC Industrial Direct',
  'Nasdaq',
  'National Oilwell Varco',
  'NetApp',
  'Netflix',
  'New York Times',
  'Newmont',
  'News Corp',
  'NextEra Energy',
  'Nike',
  'Noble Energy',
  'Norfolk Southern',
  'Northern Trust',
  'Northrop Grumman',
  'NortonLifeLock',
  'Nucor',
  'NVIDIA',
  'Occidental Petroleum',
  "O'Reilly Automotive",
  'Old Dominion Freight Line',
  'Omega Healthcare Investors',
  'Omnicom Group',
  'ONEOK',
  'Oracle',
  'Organon',
  'Otis Worldwide',
  'Owens Corning',
  'PACCAR',
  'Packaging Corporation of America',
  'Palo Alto Networks',
  'Paramount Global',
  'Park Hotels & Resorts',
  'Parker-Hannifin',
  'Paychex',
  'PayPal',
  'Pentair',
  'PepsiCo',
  'PerkinElmer',
  'Pfizer',
  'Philip Morris',
  'Pinnacle West Capital',
  'Pioneer Natural Resources',
  'PNC Financial Services',
  'PPG Industries',
  'PPL',
  'Principal Financial Group',
  'Procter & Gamble',
  'Progressive',
  'Prologis',
  'Prudential Financial',
  'Public Service Enterprise Group',
  'Public Storage',
  'PulteGroup',
  'PVH',
  'Qorvo',
  'QUALCOMM',
  'Quanta Services',
  'Quest Diagnostics',
  'Ralph Lauren',
  'Raymond James Financial',
  'Raytheon Technologies',
  'Realty Income',
  'Regency Centers',
  'Regeneron Pharmaceuticals',
  'Regions Financial',
  'Republic Services',
  'ResMed',
  'Robert Half International',
  'Rockwell Automation',
  'Rollins',
  'Roper Technologies',
  'Ross Stores',
  'Royal Caribbean',
  'S&P Global',
  'Salesforce',
  'SBA Communications',
  'Schlumberger',
  'Sempra Energy',
  'ServiceNow',
  'Sherwin-Williams',
  'Simon Property Group',
  'Skyworks Solutions',
  'Snap-on',
  'Southern Company',
  'Southwest Airlines',
  'Spirit AeroSystems',
  'Splunk',
  'Square',
  'Stanley Black & Decker',
  'Starbucks',
  'State Street',
  'Steris',
  'Stryker',
  'Sun Communities',
  'Synchrony Financial',
  'Synopsys',
  'Sysco',
  'T-Mobile',
  'Take-Two Interactive',
  'Tapestry',
  'TD Ameritrade',
  'Teledyne Technologies',
  'Teleflex',
  'Tegna',
  'Teradyne',
  'Tesla',
  'Texas Instruments',
  'Thermo Fisher Scientific',
  'Tiffany & Co.',
  'Tractor Supply',
  'Travelers',
  'Trimble',
  'Truist Financial',
  'Tupperware Brands',
  'Twitter',
  'Tyson Foods',
  'U.S. Bancorp',
  'UDR',
  'Ulta Beauty',
  'Under Armour',
  'Union Pacific',
  'United Airlines',
  'United Parcel Service',
  'United Rentals',
  'UnitedHealth',
  'Universal Health Services',
  'Unum Group',
  'Valero Energy',
  'Varian Medical Systems',
  'Ventas',
  'Verisk Analytics',
  'Verizon',
  'Vertex Pharmaceuticals',
  'VF Corporation',
  'ViacomCBS',
  'VICI Properties',
  'Viela Bio',
  'Vimeo',
  'Visa',
  'VMware',
  'Vornado Realty Trust',
  'Vulcan Materials',
  'W.R. Berkley',
  'W.W. Grainger',
  'Walgreens Boots Alliance',
  'Walmart',
  'Walt Disney',
  'Waste Management',
  'Waters',
  'WEC Energy Group',
  'Wells Fargo',
  'Welltower',
  "Wendy's",
  'Western Digital',
  'WestRock',
  'Weyerhaeuser',
  'Whirlpool',
  'Williams',
  'Willis Towers Watson',
  'Wynn Resorts',
  'Xcel Energy',
  'Xilinx',
  'XL Fleet',
  'Xylem',
  'Yum! Brands',
  'Zebra Technologies',
  'Zimmer Biomet',
  'Zions Bancorporation',
  'Zoom Video',
  'Zscaler',
];
