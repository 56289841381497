import { useEffect, useRef, useState } from 'react';
import Lottie, { Options } from 'react-lottie';
import { Confetti } from '../../assets/lotties';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { PAYMENT_REDIRECT_TIMEOUT } from '../../utils/constants';
import './index.scss';

const defaultLottieOptions: Options = {
  loop: false,
  autoplay: true,
  animationData: Confetti,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const PaymentFeedback = () => {
  const [redirectTimer, setRedirectTimer] = useState(6);
  const intervalId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setRedirectTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      if (intervalId.current) clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (intervalId.current) {
      if (redirectTimer === 0) redirectToChat();
      if (redirectTimer < PAYMENT_REDIRECT_TIMEOUT) clearInterval(intervalId.current);
    }
  }, [redirectTimer]);

  const redirectToChat = () => {
    window.location.href = `${process.env.REACT_APP_URL}/chat`;
  };

  return (
    <div className="payment-feedback-screen-wrapper">
      <div className="lottie-wrapper">
        <Lottie options={defaultLottieOptions} height={'100%'} width={'100%'} isStopped={false} isPaused={false} />
      </div>

      <div className="payment-feedback-content-wrapper">
        <div className="title xxlargeBold">Payment Success!</div>
        <div className="subtitle smallRegular m-b-xxl">Thank you for your interest in castello.ai!</div>
        <div className="subtitle smallRegular m-b-xxl">
          {redirectTimer - 1 > 0 ? (
            `Back to Castello in ${redirectTimer - 1}`
          ) : (
            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" animationDuration="1.5s" />
          )}
        </div>

        {redirectTimer < PAYMENT_REDIRECT_TIMEOUT && (
          <>
            <div className="subtitle smallRegular m-b-sm">Didn't get redirected?</div>

            <Button className="smallMedium m-b-md" onClick={redirectToChat}>
              Back to Chat
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default PaymentFeedback;
