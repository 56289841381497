import { memo, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme, useChatApi, useCommon } from '../../../hooks';
import html2canvas from 'html2canvas';
import {
  ApiResponse_Message_Reaction_Props,
  Local_CSV_Export_Props,
  logError,
  parseCitation,
  generateCSVData,
  Local_UserFeedback_Props,
  ApiResponse_Responses_Props,
  Local_Graph_Table_Props,
  Component_ResponseBlockGraph_Config_Props,
} from '../../../utils';
import { useOverlay, useToast } from '../../../providers';
import { TOAST_DURATION, defaultTheme } from '../../../utils/constants';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { CSVLink } from 'react-csv';
import { AuthModal, DownvoteFeedbackModal } from '../../../modals';
import GoogleIcon from '../../google-icon';
import { useNavigate } from 'react-router-dom';
import { useChatListStore } from '../../../store';
import './index.scss';
import { en } from '../../../utils/language';

interface FeedbackBlockProps {
  dataRef: any; // eslint-disable-line
  messageId: string;
  response: ApiResponse_Responses_Props;
  userFeedback: Local_UserFeedback_Props;
  tableData?: Local_Graph_Table_Props[];
  config?: Component_ResponseBlockGraph_Config_Props;
  isPinned: boolean;
}

export const FeedbackBlock = memo(({ dataRef, messageId, response, userFeedback, tableData, config, isPinned }: FeedbackBlockProps) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { getTheme } = useTheme();
  const { getUserAuthStatus } = useCommon();

  const { updateMessageMutation, toggleMessagePinMutation } = useChatApi();
  const { showDialog } = useOverlay();
  const { activeChatId } = useChatListStore();
  const authStatus = getUserAuthStatus();

  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  const [csvData, setCSVData] = useState<Local_CSV_Export_Props>();

  const citationMenu = useRef<Menu>(null);
  const relevantCitations = response?.type === 'text' ? response.citations : [];

  const modalMiddleware = (callback: () => void) => {
    if (authCheck()) callback();
    else showAuthModal('signup');
  };

  const authCheck = () => {
    if (authStatus === 'authenticated') return true;
    else return false;
  };

  const showAuthModal = (type: 'signin' | 'signup') => {
    showDialog({
      content: <AuthModal navigate={(e: string) => navigate(e)} type={type} />,
      onHide: () => {},
      closeOnEscape: false,
    });
  };

  useEffect(() => {
    if (tableData && config) {
      setCSVData(generateCSVData(tableData, config));
    }
  }, [tableData]);

  useEffect(() => {
    const scrollListener = document.getElementsByClassName('infinite-loader-wrapper');
    // eslint-disable-next-line
    const handleScroll = (event: any) => {
      // console.log('hit scroll', { menu: citationMenu.current, top: scrollListener[0].getBoundingClientRect().top, y: scrollListener[0].getBoundingClientRect(). });
      // @ts-ignore
      // eslint-disable-next-line
      citationMenu.current?.hide(event);
    };

    if (scrollListener.length > 0) {
      scrollListener[0].addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showDownvoteFeedbackModal = () => {
    showDialog({
      header: 'Downvote Feedback',
      content: (
        <DownvoteFeedbackModal
          onSubmit={({ additionalFeedback, reaction, reasons }) => submitMessageReactionFeedback({ incomingReaction: reaction, additionalFeedback, reasons, newReaction: true })}
        />
      ),
      onHide: () => {},
      closeOnEscape: false,
    });
  };

  const handleMessagePinning = async () => {
    const originalPinStatus = isPinned;

    const result = await toggleMessagePinMutation.mutateAsync({ chatId: activeChatId, messageId, isPinned });

    if (result) {
      toast?.current?.show({ severity: 'success', summary: 'Success', detail: `${originalPinStatus ? en.messages.messageUnpinned : en.messages.messagePinned}`, life: TOAST_DURATION });
    } else {
      toast?.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: `${originalPinStatus ? en.messages.messageUnpinFailure : en.messages.messagePinFailure}`,
        life: TOAST_DURATION,
      });
    }
  };

  const items: MenuItem[] = [
    {
      // @ts-ignore
      // eslint-disable-next-line
      items:
        relevantCitations &&
        relevantCitations.length > 0 &&
        relevantCitations.map((key, index) => ({
          label: <div className="smallRegular">{`[${index + 1}] ${parseCitation(key)}`}</div>,
          command: () => {
            // @ts-ignore
            window.open(key, '_blank').focus();
          },
        })),
    },
  ];

  async function handleCopyImage() {
    try {
      switch (response?.type) {
        case 'text':
        case 'document':
          await copyTextToClipboard(response.response);
          return;

        case 'tradingview':
          await copyWidgetToClipboard();
          return;
        case 'graph':
          createBase64Graph();
          return;

        default:
          return null;
      }
    } catch (e) {
      logError(e, 'handleCopyImage', 'Error copying image to clipboard');
    }
  }

  async function copyTextToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
      toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Text copied to clipboard', life: TOAST_DURATION });
    } catch (e) {
      toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to copy to clipboard', life: TOAST_DURATION });

      logError(e, 'copyTextToClipboard', 'Error copying text to clipboard');
    }
  }

  function createBase64Graph() {
    // Step 1: Capture the Graph Image
    // @ts-ignore
    // eslint-disable-next-line
    const base64Image = dataRef.current.getEchartsInstance().getDataURL({
      pixelRatio: 2,
      backgroundColor: 'var(--background)',
    });

    // Step 2: Create Canvas and Convert to Blob
    const image = new Image();
    // eslint-disable-next-line
    image.src = base64Image;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext('2d');
      // @ts-ignore
      ctx.drawImage(image, 0, 0);

      // Add watermark
      // @ts-ignore
      ctx.font = '20px Arial';
      // @ts-ignore
      ctx.fillStyle = 'rgba(255, 255, 255, 0.5)'; // Semi-transparent white
      // @ts-ignore
      ctx.fillText('Castello.ai', canvas.width - 200, canvas.height - 30);

      // Convert canvas to Blob
      // eslint-disable-next-line
      canvas.toBlob(async (blob) => {
        // Step 3: Copy the blob to the clipboard
        await copyBlobToClipboard(blob);
      });
    };
  }

  // @ts-ignore
  async function copyBlobToClipboard(blob) {
    try {
      // eslint-disable-next-line
      const item = new ClipboardItem({ 'image/png': blob });
      await navigator.clipboard.write([item]);
      toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Image copied to clipboard', life: TOAST_DURATION });
    } catch (e) {
      toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to copy image to clipboard', life: TOAST_DURATION });

      logError(e, 'copyBlobToClipboard', 'Error copying graph image to clipboard');
    }
  }

  async function copyWidgetToClipboard() {
    // @ts-ignore
    // eslint-disable-next-line
    const ref = dataRef?.current?.firstChild?.id || '';

    // eslint-disable-next-line
    const widgetElement = document.getElementById(ref);

    try {
      if (widgetElement) {
        // @ts-ignore
        const canvas = await html2canvas(widgetElement);
        // Convert canvas to Blob
        // eslint-disable-next-line
        canvas.toBlob(async (blob) => {
          // Step 3: Copy the blob to the clipboard
          await copyBlobToClipboard(blob);
        });
      } else {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to copy graph to clipboard', life: TOAST_DURATION });
      }
    } catch (e) {
      toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to copy graph to clipboard', life: TOAST_DURATION });

      logError(e, 'copyBlobToClipboard', 'Failed to copy widget');
    }
  }

  function handleDownvoteFeedback(incomingReaction: ApiResponse_Message_Reaction_Props) {
    let newReaction = false;

    if (userFeedback?.reaction === 'good' && incomingReaction === 'good') incomingReaction = null;
    if (userFeedback?.reaction === 'bad' && incomingReaction === 'bad') incomingReaction = null;

    if (incomingReaction) newReaction = true;

    if (newReaction && incomingReaction === 'bad') {
      showDownvoteFeedbackModal();
    } else {
      submitMessageReactionFeedback({ incomingReaction, newReaction, reasons: userFeedback?.reasons, additionalFeedback: userFeedback?.additionalFeedback });
    }
  }

  function submitMessageReactionFeedback({
    newReaction,
    incomingReaction,
    reasons = null,
    additionalFeedback = null,
  }: {
    newReaction: boolean;
    incomingReaction: ApiResponse_Message_Reaction_Props;
    reasons: Local_UserFeedback_Props['reasons'];
    additionalFeedback: Local_UserFeedback_Props['additionalFeedback'];
  }) {
    if (reasons === null && additionalFeedback && additionalFeedback?.length > 0) {
      // If there is additional feedback but no reasons, set 'reasons' to empty array (Francesco wants this behavior)
      reasons = [];
    }

    updateMessageMutation
      .mutateAsync({ chatId: activeChatId, messageId, reaction: incomingReaction, reasons, additionalFeedback })
      .then(() => {
        if (newReaction) {
          toast?.current?.show({ severity: 'success', summary: 'Success', detail: 'Thank you for your feedback', life: TOAST_DURATION });
        }
      })
      .catch(() => {
        toast?.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to send feedback', life: TOAST_DURATION });
      });
  }

  return (
    <div className="icon-row-wrapper" style={{ display: 'flex' }}>
      <div className="left">
        <>
          {response?.type === 'graph' && tableData && csvData && (
            <CSVLink filename={csvData.filename} data={csvData?.data} headers={csvData?.headers} className="icon-row-item">
              <i className="pi pi-download clickable mediumRegular" />
            </CSVLink>
          )}

          <GoogleIcon
            name={isPinned ? 'keep_off' : 'keep'}
            classname={`icon-row-item clickable`}
            // @ts-ignore
            // eslint-disable-next-line
            onClick={() => modalMiddleware(handleMessagePinning)}
            size={20}
            data-cy="response-feedback-good"
          />
          {response?.type !== 'tradingview' && (
            <GoogleIcon
              name={'content_copy'}
              classname="icon-row-item clickable"
              // @ts-ignore
              // eslint-disable-next-line
              onClick={handleCopyImage}
              size={20}
            />
          )}
          <GoogleIcon
            name={'thumb_down'}
            classname={`icon-row-item clickable ${userFeedback?.reaction === 'bad' && 'downvoted'}`}
            filled={userFeedback?.reaction === 'bad'}
            // @ts-ignore
            // eslint-disable-next-line
            onClick={() => handleDownvoteFeedback('bad')}
            size={20}
            data-cy="response-feedback-bad"
          />
          <GoogleIcon
            name={'thumb_up'}
            classname={`icon-row-item clickable ${userFeedback?.reaction === 'good' && 'upvoted'}`}
            filled={userFeedback?.reaction === 'good'}
            // @ts-ignore
            // eslint-disable-next-line
            onClick={() => handleDownvoteFeedback('good')}
            size={20}
            data-cy="response-feedback-good"
          />
        </>
      </div>
      <div className="right">
        <Menu model={items} popup ref={citationMenu} id="citation-popup-menu" />
        {relevantCitations && relevantCitations.length > 0 && (
          <div
            className="citation clickable xsmallMedium no-select"
            onClick={(event) => citationMenu?.current?.toggle(event)}
            style={{ color: activeTheme === 'dark-theme' ? 'var(--background-dark)' : 'var(--text-dark)' }}
          >
            Sources
          </div>
        )}
      </div>
    </div>
  );
});
