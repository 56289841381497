import { RefObject, createContext, useContext, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { getNativeInsets, isMobile } from '../utils';

type ToastContextType = RefObject<Toast> | null;

const ToastContext = createContext<ToastContextType>(null);

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const toastRef = useRef(null);

  // This effect is used to update the toast position on mobile devices. It will work the second run of our app, as the first run will not get updated insets at the moment.
  useEffect(() => {
    const updateToastPosition = () => {
      const mobileToastViews = document.getElementsByClassName('mobile-toast-view');
      if (mobileToastViews.length > 0) {
        const firstToast = mobileToastViews[0];

        // Get existing style attribute
        const existingStyle = firstToast.getAttribute('style') || '';

        // Append the new style, making sure to include a semicolon if needed
        const newStyle = `${existingStyle}${existingStyle.endsWith(';') ? '' : ';'}top: ${getNativeInsets().top}px !important;`;

        // Set the modified style attribute
        firstToast.setAttribute('style', newStyle);

        // Clear interval after updating
        clearInterval(myInterval);
      }
    };

    const myInterval = setInterval(updateToastPosition, 100);

    // Cleanup interval on component unmount
    return () => clearInterval(myInterval);
  }, []);

  return (
    <ToastContext.Provider value={toastRef}>
      <Toast ref={toastRef} className={isMobile() ? 'mobile-toast-view' : ''} />
      {children}
    </ToastContext.Provider>
  );
};
