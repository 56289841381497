import { useCallback, useEffect, useRef, useState } from 'react';
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApiResponse_Message_Props, Local_Send_Chat_Error_Props, isMobile } from '../../utils';
import { ChatInput, ChatLayout, InfiniteLoader, StatusBlock, ErrorBlock, TitleBlock, ChatRow, ChatResponse, ChatFooter, QuestionPromptBlock } from '../../components';
import { useChat, useChatApiV2, useCommon } from '../../hooks';
import { useQuery } from 'react-query';
import { useChatListStore } from '../../store';
import { en } from '../../utils/language';
import { getStorageItem, setStorageItem, storageItems } from '../../utils/localStorage';
import { useChatScroll, useToast } from '../../providers';
import { TOAST_DURATION } from '../../utils/constants';
import { exampleQuestions } from '../../mockdata';
import './index.scss';

const Chat = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location<{ prompt?: string }>;
  const params = useParams<{ id: string }>();
  const toast = useToast();

  const { useChatByIdQuery, useStreamChatMutation, sendChat, getStreamStatus } = useChatApiV2();
  const { chatStatusMutation } = useChat();
  const { streamMap, activeChatId, setActiveChatId, setChatStatus, getCurrentChatStatus, chatErrorMap, getMessageListByChatId, removeChatErrorMapItem } = useChatListStore();
  const { getUserAuthStatus } = useCommon();
  const { isScrolling } = useChatScroll();

  const { data: streamStatus = '' } = useQuery('streamStatus', getStreamStatus);

  const { isLoading, isFetching, hasNextPage, fetchNextPage, refetch } = useChatByIdQuery(params.id as string);

  const [chatMessage, setChatMessage] = useState<string>('');
  const [bottomTextMessage, setBottomTextMessage] = useState<string>('');
  const prevChatsRef = useRef({});

  const activeChatStatus = getCurrentChatStatus(params.id as string);
  const messages = getMessageListByChatId(params.id as string);
  const authStatus = getUserAuthStatus();
  const lastMessageMap = getStorageItem(storageItems.lastMessageMap);

  const keyExtractor = (item: ApiResponse_Message_Props) => item._id;

  const onChangeChatMessage = (value: string) => setChatMessage(value);

  const authCheck = () => {
    if (authStatus === 'authenticated') return true;
    else return false;
  };

  /**
   * @todo: For guest user, url should reset to /chat if they refresh the page. Right now, it gets stuck on the last chat id.
   */

  const showLoader = !(params.id || messages.length > 0) ? false : isLoading; // Should we display the chat loader
  const isActivelyStreamingCurrentChat = Boolean(streamMap[params?.id as string]);
  const currentChatReloadedDuringStream = !isActivelyStreamingCurrentChat && activeChatStatus?.status === 'processing';
  const showExampleQuestions = messages.length <= 0 && !isActivelyStreamingCurrentChat && !currentChatReloadedDuringStream && !showLoader && !chatErrorMap[params.id as string]; // Should we display the example questions

  // On-mount listener
  useEffect(() => {
    const chatId = params?.id || activeChatId || '';
    const MINIMUM_CALL_FREQUENCY = 1000; // 1 second
    const timeoutPeriod = 60000 * 3; // How long until we break out of the loop (3 minutes)

    const handler = async () => {
      let res = await chatStatusMutation.mutateAsync(); // Use a local var, such as 'res', as 'activeChatStatus' is not updated immediately
      prevChatsRef.current = res.chats;

      const startTime = Date.now();

      // Check if the current chat is processing (on page load)
      if (res.chats[params.id as string]?.status === 'processing') {
        // User loaded app and the current chat is processing in the background
        setBottomTextMessage(en.messages.processingBackgroundStreamRequest);
        setChatStatus(res);
      }

      if (Object.keys(res.chats).length > 0) {
        // Wait for the chat to finish processing
        while (Object.keys(res.chats).length > 0) {
          const elapsedTime = Date.now() - startTime;

          // Break out of the loop if the timeout period has been reached
          if (elapsedTime >= timeoutPeriod) {
            break;
          }

          const callStart = Date.now(); // Record the start time of the call

          res = await chatStatusMutation.mutateAsync(); // Wait for the mutation to finish

          // Check if the any chats statuses have been removed
          if (Object.keys(res.chats).length !== Object.keys(prevChatsRef.current).length) {
            const missingKeys = [];

            // Find the keys that were removed
            for (const key in prevChatsRef.current) {
              if (!res.chats.hasOwnProperty(key)) {
                missingKeys.push(key);
              }
            }

            // Remove the missing keys from the lastMessageMap
            // @ts-ignore
            // eslint-disable-next-line
            const newLastMessageMap = { ...lastMessageMap };

            missingKeys.forEach((key) => {
              // eslint-disable-next-line
              delete newLastMessageMap[key]; // Remove the key from the lastMessageMap
            });

            await refetch(); // Refetch messages to get the latest message that finished
            setChatStatus(res); // Update the chat status
            setStorageItem(storageItems.lastMessageMap, newLastMessageMap); // Update the lastMessageMap
            prevChatsRef.current = res.chats; // Update the previous chat to the current one
          }

          const callDuration = Date.now() - callStart; // Calculate how long the call took

          // Ensure a minimum time was passed before making the next call
          if (callDuration < MINIMUM_CALL_FREQUENCY) {
            await new Promise((resolve) => setTimeout(resolve, MINIMUM_CALL_FREQUENCY - callDuration));
          }
        }

        // Scroll to bottom of chat when user asks a new question
        const elem = document.getElementsByClassName('infinite-loader-wrapper chat-message-padding');
        if (elem) {
          const scrollHeight = elem[0].scrollHeight;
          elem[0].scrollTo({ top: scrollHeight, behavior: 'smooth' });
        }
      }

      // Reset the lastMessageMap if there are no active streams (general cleanup)
      if (Object.keys(streamMap).length === 0) {
        setStorageItem(storageItems.lastMessageMap, {}); // Reset the map if there are no active streams
      }
    };

    // If the user is authenticated, check for queued messages
    if (authCheck()) {
      handler();
    }

    // Set active chat id on mount
    setActiveChatId(chatId);

    // Navigate to the needed page
    if (chatId) navigate(`/chat/${chatId}`);
    else navigate(`/chat`);
  }, []);

  // Listen for changes in activeChatId
  useEffect(() => {
    navigate(`/chat/${activeChatId}`);
  }, [activeChatId]);

  // Listen for changes in our stream and activeChatStatus
  useEffect(() => {
    // If chat status is not processing and user has no active message stream
    if (!useStreamChatMutation.isLoading && !activeChatStatus?.status) {
      setBottomTextMessage(''); // Reset the bottom text message
    }
  }, [useStreamChatMutation.isLoading, activeChatStatus]);

  // Listen for changes in the location state
  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (location?.state?.prompt) {
      sendChat({ prompt: location.state.prompt, chatId: params.id }).catch((error: Local_Send_Chat_Error_Props) => {
        toast?.current?.show({ severity: 'warn', summary: error.title, detail: error.message, life: TOAST_DURATION });
      });
      window.history.replaceState({}, '');
    }
  }, [isLoading, location?.state?.prompt]);

  // Listen for changes in our active chat
  useEffect(() => {
    refetch(); // Refetch messages to get the latest message that finished

    if (currentChatReloadedDuringStream) {
      // User swapped to a chat that is processing in background
      setBottomTextMessage(en.messages.processingBackgroundStreamRequest);
    } else {
      // User swapped to a chat that isn't processing in background
      setBottomTextMessage('');
    }
  }, [params.id, activeChatId]);

  const handleClick = (prompt: string) => {
    const handler = () => {
      sendChat({ prompt, chatId: params.id }).catch((error: Local_Send_Chat_Error_Props) => {
        toast?.current?.show({ severity: 'warn', summary: error.title, detail: error.message, life: TOAST_DURATION });
      });
    };

    handler();
  };

  const renderRow = useCallback(
    (item: ApiResponse_Message_Props, index: number) => {
      const displayFollowupQuestions = index === messages.length - 1 && !isActivelyStreamingCurrentChat && !currentChatReloadedDuringStream && !chatErrorMap[params.id as string]?.prompt;
      return <ChatRow index={index} message={item} displayFollowupQuestions={displayFollowupQuestions} onClickFollowUpQuestion={handleClick} isScrolling={isScrolling as boolean} />;
    },
    [messages, isScrolling, params.id],
  );

  return (
    <ChatLayout type="chat" loading={showLoader}>
      <div key={params.id} className="chat-response-wrapper" style={{ justifyContent: messages ? 'flex-start' : 'center' }}>
        {showExampleQuestions ? (
          <div className="example-question-wrapper">
            <div className="example-question-header">
              <div className="xxlargeBold m-b-xs">Castello</div>
              <div className="largeRegular">What can I do for you?</div>
            </div>

            <ChatInput
              value={chatMessage}
              onChange={onChangeChatMessage}
              isDisabled={isActivelyStreamingCurrentChat || currentChatReloadedDuringStream}
              onSubmit={() => {
                setChatMessage('');
                sendChat({ prompt: chatMessage, chatId: params.id });
              }}
            />

            <div className="example-questions-wrapper">
              {exampleQuestions.map((question) => {
                return <QuestionPromptBlock key={`${question.title}-${question.subtitle}`} title={question.title} subtitle={question.subtitle} icon={question.icon} />;
              })}
            </div>

            <div className="footer">
              {!isMobile() && (
                // If user is not on mobile, show a beta disclaimer
                <div className="disclaimer-message smallRegular">Castello AI is still in beta. Please be aware that answers you receive may not be accurate.</div>
              )}
              <ChatFooter bottomMessage={bottomTextMessage} />
            </div>
          </div>
        ) : (
          <>
            {messages && (
              <InfiniteLoader
                pageEndLabel={'No more messages'}
                loadNewDataFrom="top"
                isFetching={isFetching}
                data={messages}
                hasNextPage={hasNextPage}
                renderRow={renderRow}
                keyExtractor={keyExtractor}
                fetchNextPage={fetchNextPage}
                renderFooter={() => (
                  <>
                    {/* @ts-ignore */}
                    {currentChatReloadedDuringStream && <TitleBlock title={lastMessageMap[params.id] as string} />}

                    {isActivelyStreamingCurrentChat && streamMap[params.id as string].length > 0 && (
                      <ChatResponse responses={streamMap[params.id as string]} isScrolling={isScrolling as boolean} activeStream />
                    )}
                    {(isActivelyStreamingCurrentChat || currentChatReloadedDuringStream) && <StatusBlock status={streamStatus} />}

                    {chatErrorMap[params.id as string]?.prompt && (
                      <ErrorBlock
                        messagesLength={messages.length}
                        promptThatErrored={chatErrorMap[params.id as string].prompt}
                        errorMessage={chatErrorMap[params.id as string].error}
                        onRefresh={() => {
                          removeChatErrorMapItem(params.id as string);
                          sendChat({ prompt: chatErrorMap[params.id as string]?.prompt || '', chatId: chatErrorMap[params.id as string]?.chatId || (params.id as string) });
                        }}
                      />
                    )}
                  </>
                )}
                className="chat-message-padding"
              />
            )}

            <ChatInput
              value={chatMessage}
              onChange={onChangeChatMessage}
              isDisabled={isActivelyStreamingCurrentChat || currentChatReloadedDuringStream}
              onSubmit={() => {
                setChatMessage('');
                sendChat({ prompt: chatMessage, chatId: params.id });
              }}
            />
          </>
        )}
      </div>
    </ChatLayout>
  );
};

// Chat.whyDidYouRender = true;

export default Chat;
