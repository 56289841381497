import Lottie, { Options } from 'react-lottie';

import { Chatting } from '../../../assets/lotties';

import { ResponseBlockIcon } from '../../response-block-icon';

const defaultLottieOptions: Options = {
  loop: true,
  autoplay: true,
  animationData: Chatting,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

interface StatusBlockProps {
  status?: string;
}

export const StatusBlock = ({ status = '' }: StatusBlockProps) => {
  return (
    <div className="reply-wrapper">
      <ResponseBlockIcon usertype="server" visible={true} />
      <div className="lottie-wrapper" data-cy="response-loading-animation">
        <Lottie options={defaultLottieOptions} isStopped={false} isPaused={false} height={'100%'} width={100} />
      </div>

      <div className="stream-status-text">{status}</div>
    </div>
  );
};
