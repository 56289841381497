interface BackendApiErrorOptions {
  message: string;
  code: string | number;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cause?: any;
  sentryErrorId?: string;
}

export class BackendApiError extends Error {
  public code: string | number;
  public type: string;
  public sentryErrorId?: string;

  constructor({ message, code, type, cause, sentryErrorId }: BackendApiErrorOptions) {
    super(message); // Pass the message to the base Error class constructor
    this.name = this.constructor.name; // Set the error name as the class name
    this.code = code;
    this.type = type;
    this.cause = cause;
    this.sentryErrorId = sentryErrorId;

    // Maintaining proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
