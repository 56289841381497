import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { createContext, useContext, useEffect, useState } from 'react';
import LoadingScreen from '../screens/loading-screen';
import { useAuth } from '../hooks';
import { Firebase_Extended_User_Props, Local_User_Props, logError } from '../utils';
import { useQuery } from 'react-query';
import { _getAccessToken, _getUserIDFromDatabase } from '../api';
import { setSentryUser } from '../services/sentry';

interface AuthContextType {
  user: Local_User_Props | null;
}

const AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  // eslint-disable-next-line
  const { setUser, getUser } = useAuth();
  // eslint-disable-next-line
  const { data: user } = useQuery('user', getUser);
  let modifiedUser: Local_User_Props | null = null;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (currentUser) => {
      const handler = async () => {
        if (currentUser) {
          const userFromDatabase = currentUser.emailVerified ? await _getUserIDFromDatabase() : null;
          const accessToken = await _getAccessToken({});
          currentUser = currentUser as Firebase_Extended_User_Props;

          // console.log('currentUser', currentUser);

          modifiedUser = {
            accessToken,
            emailVerified: currentUser?.emailVerified || false,
            email: currentUser?.email || '',
            firebaseUid: currentUser?.uid || '',
            firstName: userFromDatabase?.firstName || '',
            lastName: userFromDatabase?.lastName || '',
            _id: userFromDatabase?._id || '',
            subscriptionStatus: userFromDatabase?.subscriptionStatus || null,
            isPremiumUser: userFromDatabase?.isPremiumUser || false,
            image: currentUser?.photoURL || '',
            betaUseAccepted: userFromDatabase?.betaUseAccepted || false,
            tosAccepted: userFromDatabase?.tosAccepted || false,
          };
          setUser(modifiedUser);
          setSentryUser(modifiedUser);
        } else {
          setUser(null);
        }
        setLoading(false);
      };

      handler().catch((e) => {
        setLoading(false);
        logError(e, 'AuthProvider.onAuthStateChanged');
      });
    });

    // Remove listener
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  // If loading takes longer than 5 seconds, set loading to false
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (loading) {
        setLoading(false);
      } else {
        clearTimeout(timeoutId);
      }
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [loading]);

  const values = {
    user: user as Local_User_Props | null,
  };

  // Auth may fail if we return 'children' before 'loading' is completed, since auth may be null at that point
  return <AuthContext.Provider value={values}>{loading ? <LoadingScreen /> : children}</AuthContext.Provider>;
}

function useAuthApi() {
  return useContext(AuthContext);
}

export { useAuthApi, AuthProvider, AuthContext };
