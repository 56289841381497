import './index.scss';
import { ProgressSpinner } from 'primereact/progressspinner';

const Loader = () => {
  return (
    <div className="loading-wrapper">
      <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" animationDuration="1.5s" />
    </div>
  );
};

export default Loader;
