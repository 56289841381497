import { Local_DashboardGraphSizeRestrictions_Props, Local_RN_Inset_Props, Local_Subscription_Perk_Props, Local_Subscription_Tiers_Props, Util_StringDictionary_Props } from './types';

export const MINIMUM_PASSWORD_LENGTH = 8;
export const TOAST_DURATION = 3000;
export const PAYMENT_REDIRECT_TIMEOUT = -5; // seconds before timeout failure
export const LAZY_SCROLL_FETCH_THRESHHOLD = 0.9; // number of messages to fetch per page
export const CHAT_SEARCH_DEBOUNCE_TIME = 300; // Time to wait before searching chats
export const TABLE_DATA_DEFAULT_ROW_COUNT = 2; // Number of rows in table data by default
export const MOBILE_VIEW_WIDTH = 768; // Max width for mobile view
export const TABLET_VIEW_WIDTH = 1024; // Max width for tablet view
export const MAXIMUM_QUERY_CHARACTER_LIMIT = 10000; // Max characters for a chat query
export const MINIMUM_FEEDBACK_LENGTH = 3; // Minimum length for feedback
export const SLOGAN = 'Expert Analysis At Your Fingertips';

export const constants: Util_StringDictionary_Props = {
  fiscal_year: 'Annual',
  fiscal_quarter: 'Quarterly',
  MRQ: 'fiscal_quarter',
  MRY: 'fiscal_year',
  today: 'Today',
  yesterday: 'Yesterday',
  previous7Days: 'Previous 7 Days',
  previous30Days: 'Previous 30 Days',
};

/**
 * Expected firebase errors
 */
export const FIREBASE_API_ERRORS: Util_StringDictionary_Props = {
  'auth/too-many-requests': 'Too many requests. Please try again later.',
  'auth/email-already-in-use': 'Email already in use',
  'auth/invalid-email': 'Invalid email',
  'auth/weak-password': `Weak password. Please use at least ${MINIMUM_PASSWORD_LENGTH} characters.`,
  'auth/invalid-login-credentials': 'Incorrect username or password',
};

export const freeTierPerks: Local_Subscription_Perk_Props = {
  price: {
    monthly: '0',
    yearly: '0',
  },
  title: 'Free',
  description: 'Powerful tools, for free',
  perks: [
    { title: 'Unlimited chats', description: 'Ask away! Free users can enjoy unlimited chat conversations' },
    { title: 'Unlimited dashboards', description: 'See it, organize it! Build unlimited financial views for free' },
  ],
};

export const proTierPerks: Local_Subscription_Perk_Props = {
  price: {
    monthly: '9.99',
    yearly: '99.99',
  },
  title: 'Pro',
  description: 'Free tier, plus:',
  perks: [
    { title: 'Priority Queue', description: "During high traffic periods, you're messages will be answered first" },
    { title: 'Future perks', description: 'Swag, early access to features, and more' },
  ],
  previousIncludes: freeTierPerks,
};

export const tierSubscriptions: Local_Subscription_Tiers_Props = {
  free: freeTierPerks,
  pro: proTierPerks,
};

export const defaultTheme = 'light-theme';
export const dashboardBreakpoints = { lg: 24, md: 20, sm: 16, xs: 12 };
export const dashboardColumns = { lg: 24, md: 20, sm: 16, xs: 12 };

export const dashboardTradingViewGraphSizeRestrictions: Local_DashboardGraphSizeRestrictions_Props = {
  symbol_info_widget: {
    minW: 5,
    minH: 5,
    defaultWidth: 15,
    defaultHeight: 10,
  },
  symbol_overview_widget: {
    minW: 5,
    minH: 8,
    defaultWidth: 10,
    defaultHeight: 20,
  },
  advanced_realtime_chart_widget: {
    minW: 5,
    minH: 10,
    defaultWidth: 10,
    defaultHeight: 20,
  },
  stock_heatmap_widget: {
    minW: 5,
    minH: 15,
    defaultWidth: 10,
    defaultHeight: 20,
  },
  economic_calendar_widget: {
    minW: 5,
    minH: 5,
    defaultWidth: 10,
    defaultHeight: 20,
  },
};

export const dashboardGraphSizeRestrictions = {
  minW: 5,
  minH: 15,
  defaultWidth: 10,
  defaultHeight: 20,
};

export const defaultInsets: Local_RN_Inset_Props = { top: 0, right: 0, bottom: 0, left: 0 };

export const downvoteResponseReasons: Record<string, string> = {
  incorrectResponse: 'Incorrect Response',
  irrelevantResponse: 'Irrelevant Response',
  incompleteResponse: 'Incomplete Response',
  slowResponse: 'Slow Response',
  badFormatting: 'Bad Formatting',
};

export const tradeIcon = 'sync_alt';
export const investIcon = 'savings';
export const learnIcon = 'school';
export const newsIcon = 'news';
