import { Component_SiderbarController_Props } from '../../utils';

const SidebarController = ({ isSidebarVisible, onClick }: Component_SiderbarController_Props) => {
  return (
    <div className="sidebar-controller" onClick={onClick} data-cy="sidebar-controller">
      <div className={`sidebar-controller-top ${isSidebarVisible ? '' : 'controller-top-collapsed'}`} />
      <div className={`sidebar-controller-bottom ${isSidebarVisible ? '' : 'controller-bottom-collapsed'}`} />
    </div>
  );
};

export default SidebarController;
