import { useQueryClient } from 'react-query';
import { isMobile } from '../utils';
import { getStorageItem, setStorageItem, storageItems } from '../utils/localStorage';

// ------------------ Sidebar Hook ------------------
export const useSidebar = () => {
  const queryClient = useQueryClient();

  const setSidebar = (visible: boolean) => {
    if (visible) setStorageItem(storageItems.sidebar, true);
    else setStorageItem(storageItems.sidebar, false);
    queryClient.setQueryData('sidebar', visible);
  };

  const getSidebar = (): boolean => {
    let sidebarVisible = queryClient.getQueryData('sidebar');
    const localStorageValue = getStorageItem(storageItems.sidebar);

    if (!isMobile()) {
      // Desktop
      if (sidebarVisible === undefined && localStorageValue) {
        sidebarVisible = localStorageValue;
      }

      if (sidebarVisible === undefined) {
        sidebarVisible = true; // This is the default value for web
      }
    } else {
      // Mobile
      if (sidebarVisible && localStorageValue) {
        // This ensures we keep sidebar open if the user is swapping between tabs
        sidebarVisible = localStorageValue;
      } else {
        // This ensures we close sidebar on mobile when we refresh the page
        sidebarVisible = false; // This is the default value for mobile
      }
    }

    return Boolean(sidebarVisible);
  };

  return { setSidebar, getSidebar };
};
