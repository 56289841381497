import { memo } from 'react';
import RenderSVG from '../renderSVG';
import { useQuery } from 'react-query';
import { useTheme } from '../../hooks';
import { defaultTheme } from '../../utils/constants';
import { UserType } from '../../utils';
import './index.scss';

export const ResponseBlockIcon = memo(({ usertype, visible = true }: { usertype: UserType; visible: boolean }) => {
  const { getTheme } = useTheme();
  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  return (
    <div className={`response-avatar response-avatar-${usertype}`} style={{ display: visible ? 'flex' : 'none' }}>
      {usertype === 'server' ? <RenderSVG name={activeTheme === 'dark-theme' ? 'top_crown_white' : 'top_crown_black'} classname={'svg-style'} /> : <i className="pi pi-user largeRegular" />}
    </div>
  );
});
