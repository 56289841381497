import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import { useChatApi } from '../../hooks';
import { SidebarTypes } from '../../utils';
import { CHAT_SEARCH_DEBOUNCE_TIME } from '../../utils/constants';
import './index.scss';

interface SidebarFilterProps {
  type: SidebarTypes;
  value: string;
  loading: (loading: boolean) => void;
  onChange: (searchQuery: string) => void;
}

export const SidebarFilter = ({ type, value, loading, onChange }: SidebarFilterProps) => {
  const { setFilteredChats, chatSearchMutation } = useChatApi();

  useEffect(() => {
    if (value) {
      const timeoutId = setTimeout(() => {
        const handler = async () => {
          if (type === 'chat') await chatSearchMutation.mutateAsync({ searchQuery: value, pageNumber: 0 });
          // if (type === 'dashboard') await dashboardSearchMutation.mutateAsync({ searchQuery, pageNumber: 0 });
          loading(false);
        };

        handler().catch((e) => console.log(e));
      }, CHAT_SEARCH_DEBOUNCE_TIME);

      return () => clearTimeout(timeoutId);
    } else {
      if (type === 'chat') setFilteredChats([]);
      // if (type === 'dashboard') setFilteredDashboards([]);
      loading(false);
    }
  }, [value]);

  return (
    <div className="sidebar-filter-wrapper">
      <div className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={value}
          placeholder={`Filter ${type}s...`}
          onChange={(e) => {
            loading(true);
            onChange(e.target.value);
          }}
          className="smallRegular"
        />
        {chatSearchMutation.isLoading && <i className="pi pi-spin pi-spinner" style={{ right: '.75rem', color: 'var(--text)' }} />}
      </div>
    </div>
  );
};
