import { memo } from 'react';
import { TitleBlock, FollowupQuestionBlock, ChatResponse } from '../';
import { ApiResponse_Message_Props, getNativeInsets } from '../../utils';
import { isEqual } from 'lodash';
import './index.scss';

interface ChatRowProps {
  message: ApiResponse_Message_Props;
  isScrolling: boolean;
  displayFollowupQuestions: boolean;
  onClickFollowUpQuestion: (question: string) => void;
  index: number;
}

const arePropsEqual = (prevProps: ChatRowProps, nextProps: ChatRowProps) => {
  return isEqual(prevProps.message, nextProps.message) && isEqual(prevProps.displayFollowupQuestions, nextProps.displayFollowupQuestions) && isEqual(prevProps.isScrolling, nextProps.isScrolling);
};

export const ChatRow = memo(({ message, displayFollowupQuestions, isScrolling, index, onClickFollowUpQuestion }: ChatRowProps) => {
  const nativeInsets = getNativeInsets();

  return (
    <>
      {index === 0 && <div className="first-message-spacer" style={{ marginTop: nativeInsets?.top ? nativeInsets?.top / 2 : '' }} />}
      <div id={`question-header-${message._id}`} className="chat-row-column-reverse">
        <TitleBlock title={message.prompt} />
        {message?.aiGeneratedData?.responses?.length > 0 && <ChatResponse completeMessage={message} isScrolling={isScrolling} />}
      </div>

      {displayFollowupQuestions && !!message?.aiGeneratedData?.followupQuestions && message?.aiGeneratedData?.followupQuestions?.length > 0 && (
        <FollowupQuestionBlock questions={message?.aiGeneratedData?.followupQuestions} onClickFollowUpQuestion={onClickFollowUpQuestion} />
      )}
    </>
  );
}, arePropsEqual);
