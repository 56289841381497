import { useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from '../../../hooks';
import { markdownError } from '../../../mockdata/markdownError';
import remarkGfm from 'remark-gfm';
import remarkToc from 'remark-toc';
import rehypeRaw from 'rehype-raw';
import rehypeSlug from 'rehype-slug';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import { ApiResponse_Responses_Props, AppThemes, Local_UserFeedback_Props, logError } from '../../../utils';
import { defaultTheme } from '../../../utils/constants';
import { FeedbackBlock } from '../';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css'; // Needed for equation rendering
import './index.scss';

const remarkPlugins = [remarkGfm, remarkToc, remarkMath];
const rehypePlugins = [rehypeSlug, rehypeHighlight, rehypeRaw, rehypeKatex];

interface TextResponseBlockProps {
  message: string;
  messageId: string;
  userFeedback: Local_UserFeedback_Props;
  response: ApiResponse_Responses_Props;
  isComplete?: boolean;
  shouldShowFeedback?: boolean;
  isPinned: boolean;
}

export const TextResponseBlock = ({ message, messageId, response, userFeedback, isComplete, shouldShowFeedback, isPinned }: TextResponseBlockProps) => {
  const { getTheme } = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  // Force links to open in a new tab
  useEffect(() => {
    const container = containerRef.current;

    const handleLinkClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement; // Cast the event target to HTMLElement

      // Check if the clicked element is a link
      if (target.tagName === 'A' && target instanceof HTMLAnchorElement) {
        event.preventDefault(); // Prevent the default action
        window.open(target.href, '_blank'); // Open the link in a new tab
      }
    };

    // Add the event listener
    if (container) {
      container.addEventListener('click', handleLinkClick);
    }

    // Cleanup the event listener
    return () => {
      if (container) {
        container.removeEventListener('click', handleLinkClick);
      }
    };
  }, []);

  // Change the theme of the syntax highlighting when app theme changes
  const loadHighlightStyle = (theme: AppThemes) => {
    const existingStyle = document.getElementById('highlight-style');
    if (existingStyle) {
      document.head.removeChild(existingStyle);
    }
    const styleSheet = document.createElement('link');
    styleSheet.id = 'highlight-style';
    styleSheet.rel = 'stylesheet';
    styleSheet.href =
      theme === 'dark-theme' ? 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.3.1/styles/srcery.min.css' : 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.3.1/styles/nord.min.css';

    document.head.appendChild(styleSheet);
  };

  useEffect(() => {
    loadHighlightStyle(activeTheme);
  }, [activeTheme]);

  const renderMarkdown = () => {
    try {
      const newData = message
        .replace(/\$/g, '\\$') // Replaces $ with \$
        .replace(/\\\[/g, '$$') // Replaces \[ with $$
        .replace(/\\\]/g, '$$') // Replaces \] with $$
        .replace(/\\\(/g, '$') //  Replaces \( with $
        .replace(/\\\)/g, '$'); //  Replaces \) with $

      // Try to return formatted markdown content
      return <ReactMarkdown children={newData} remarkPlugins={remarkPlugins} rehypePlugins={rehypePlugins} />;
    } catch (e) {
      logError(e, 'renderMarkdown - attempt 1');

      try {
        // If formatted markdown fails, try to return the original content as plaintext
        return <div>{message}</div>;
      } catch (e) {
        logError(e, 'renderMarkdown - attempt 2');

        // If that failed for some reason, return a nicely formatted markdown error message
        return <ReactMarkdown children={markdownError} remarkPlugins={remarkPlugins} rehypePlugins={rehypePlugins} />;
      }
    }
  };

  return (
    <>
      <div ref={containerRef} className="response-block-wrapper" data-cy="server-response-block-text">
        <div className="response-block-common-style server-response response-block-text-wrapper">
          <div className="response-block-inner-text-wrapper">
            <div className="response-block-server-response markdown-table">{renderMarkdown()}</div>
          </div>
        </div>
      </div>

      {shouldShowFeedback && isComplete && <FeedbackBlock dataRef={message} messageId={messageId} response={response} userFeedback={userFeedback} isPinned={isPinned} />}
    </>
  );
};
