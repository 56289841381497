import { TitleBlock } from '../';
import { getNativeInsets } from '../../../utils';
import './index.scss';

interface ErrorBlockProps {
  messagesLength: number; // number of messages in the chat
  promptThatErrored: string;
  errorMessage: string;
  onRefresh: () => void;
}

export const ErrorBlock = ({ errorMessage, promptThatErrored, messagesLength, onRefresh }: ErrorBlockProps) => {
  const nativeInsets = getNativeInsets();

  return (
    <div
      className={`error-wrapper ${messagesLength < 1 ? 'error-wrapper-with-margin' : ''}`}
      style={{ marginTop: nativeInsets?.top ? `calc(${nativeInsets?.top / 2}px + var(--navbar-height) + var(--spacing-lg))` : '' }}
    >
      <TitleBlock title={promptThatErrored} />
      <div className="error-row">
        <i className="pi pi-refresh mediumBold" onClick={onRefresh} />
        <div className="error-message">{errorMessage}</div>
      </div>
    </div>
  );
};
