import * as Sentry from '@sentry/react';
import { Button } from 'primereact/button';
import { useNavigate, useRouteError } from 'react-router-dom';
import Lottie, { Options } from 'react-lottie';
import { ErrorGreen, ErrorRed } from '../../assets/lotties';
import { useQuery } from 'react-query';
import { useTheme } from '../../hooks';
import { defaultTheme } from '../../utils/constants';
import './index.scss';

const ErrorScreen = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const { getTheme } = useTheme();

  const { data: activeTheme = defaultTheme } = useQuery('theme', getTheme);

  const errorAnimationOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: activeTheme === 'dark-theme' ? ErrorGreen : ErrorRed,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  if (!(error instanceof Error)) {
    // @ts-ignore
    // eslint-disable-next-line
    let tempErr = error?.error instanceof Error ? error.error : new Error(error);
    Sentry.captureException(tempErr);
  } else {
    Sentry.captureException(error);
  }

  console.log('ErrorScreen', error);

  // @ts-ignore
  // eslint-disable-next-line
  let errorMessage = error?.message || 'Sorry, an unexpected error has occurred.';

  return (
    <div id="error-screen">
      <div className="error-response-wrapper">
        <div className="headingMedium m-b-sm">Something Went Wrong</div>
        <div className="smallRegular">{errorMessage}</div>
        <div className="lottie-wrapper m-b-md">
          <Lottie options={errorAnimationOptions} height={'100%'} width={'100%'} isStopped={false} isPaused={false} />
        </div>
        <Button className="button" onClick={() => navigate('/')}>
          Back to Castello
        </Button>
      </div>
    </div>
  );
};

export default ErrorScreen;
